import React, { useState } from "react";

import UamStore from "./uam-store.js";
import RoleSearchDto from "./role-search-dto.js";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap";

let store = new UamStore();

function RoleSearch(props) {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');

  function handleRoleCodeChange(event) {
    setCode(event.target.value);
  }

  function handleRoleNameChange(event) {
    setName(event.target.value);
  }

  function handleSearch(event, callback) {
    let searchReq = new RoleSearchDto({
      "code": code,
      "name": name
    });
    props.store.searchRole(searchReq).then(props.callback);
  }

  return (
    <>
      <Row className="col-md-12">
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>Role Code</label>
            <Input placeholder="Code" type="text" onChange={handleRoleCodeChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>Role Name</label>
            <Input placeholder="Name" type="text" onChange={handleRoleNameChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary">
              Search
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default RoleSearch;
