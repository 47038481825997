import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CategoryStore from "../category/category-store.js";
import MediaFileSearchDto from "./mediafile-search-dto.js";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

let store = new CategoryStore();

function MediaFileSearch(props) {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);
  const [genres, setGenres] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [genreId, setGenreId] = useState('');
  const [genreName, setGenreName] = useState('');
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [source, setSource] = useState('');
  const [ids, setIds] = useState('');

  useEffect(() => {
    let urlIds = searchParams.get('ids');
    setIds(urlIds);
    store.list().then((data) => {
      setCategories(data);
    });
  }, []);

  function getGenreList() {
    return genres.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function getStatusList() {
    return [
      {
        value: 'ACTIVE',
        label: 'ĐANG HIỆN'
      },
      {
        value: 'INACTIVE',
        label: 'ĐANG CHỜ HIỆN'
      },
      {
        value: 'DISABLED',
        label: 'ĐANG ẨN'
      },
      {
        value: 'IN_PROCESSING',
        label: 'ĐANG XỬ LÝ'
      }
    ];
  }

  function getSourceList() {
    return [
      {
        value: 'phapquang',
        label: 'PHÁP QUANG'
      },
      {
        value: 'chanhphap',
        label: 'CHÁNH PHÁP'
      }
    ];
  }

  function getCategoryList() {
    return categories.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function handleCategoryChange(category) {
    let selectedCat = categories.filter(item => item.code === category.value)[0];
    setCategoryId(selectedCat.id);
    setCategoryName(selectedCat.name);
    setGenres(selectedCat.genres);
  }

  function handleGenreChange(genre) {
    setGenreId(genres.filter(item => item.code === genre.value)[0].id);
    setGenreName(genre.name);
  }

  function handleStatusChange(select) {
    setStatus(select.value);
  }

  function handleSourceChange(select) {
    setSource(select.value);
  }

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleIdsChange(event) {
    setIds(event.target.value);
  }

  function handleSearch(event) {
    props.showProgressingFn();
    let searchReq = new MediaFileSearchDto({
      "ids": ids,
      "title": title,
      "status": status,
      "source": source,
      "categoryId": categoryId,
      "genre1Id": genreId,
      "pageNo": props.page || 0
    });
    props.store.search(searchReq).then((data) => props.callback(data, searchReq));
  }

  return (
    <>
      <Row>
        <Col className="pr-1" md="2">
          <FormGroup>
            <label>{t('category.category')}</label>
            <Select options={getCategoryList()} onChange={handleCategoryChange}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="2">
          <FormGroup>
            <label>{t('category.genre')}</label>
            <Select options={getGenreList()} onChange={handleGenreChange}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="2">
          <FormGroup>
            <label>Trạng thái</label>
            <Select options={getStatusList()} onChange={handleStatusChange}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="1">
          <FormGroup>
            <label>ID</label>
            <Input placeholder="ID" type="text" onChange={handleIdsChange}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="1">
          <FormGroup>
            <label>{t('common.title')}</label>
            <Input placeholder={t('common.title')} type="text" onChange={handleTitleChange}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="2">
          <FormGroup>
            <label>Ứng dụng</label>
            <Select options={getSourceList()} onChange={handleSourceChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default MediaFileSearch;
