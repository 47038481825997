class HttpUtils {
  getHeaders = function() {
    return {
       "accept": 'application/json',
       "Access-Control-Allow-Origin": "*",
       "Authorization": 'Bearer ' + localStorage.getItem('access_token')
     };
  }
}

export default HttpUtils;
