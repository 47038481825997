import React, { useState } from "react";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { useTranslation } from 'react-i18next';

import BookStore from "../book-store.js";

import {
  Button
} from "reactstrap";

function NotifyButton(props) {
  const { t } = useTranslation();
  const [notifyModal, setNotifyModal] = useState(false);

  function handleNotifyApp(event) {
    setNotifyModal(false);
    new BookStore().notifyApp(props.id);
  }

  return <span style={{cursor: "pointer"}}>
    <PureModal header="Please confirm"
      footer={
       <div>
         <button onClick={() => setNotifyModal(false)}>Cancel</button>
         <button onClick={handleNotifyApp}>Yes</button>
       </div>
      }
      isOpen={notifyModal}
      onClose={()=>setNotifyModal(false)}
    >
      <p>Are you sure you want to notify AppPQ?</p>
    </PureModal>
    <span onClick={() => setNotifyModal(true)} >
      <Button className="btn-round btn-icon" color="success" outline size="sm">
        <i className="fa fa-bell" />
      </Button>{t('books.notify_app')}
    </span>
  </span>;
}

export default NotifyButton;
