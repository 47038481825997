import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Nav, NavItem } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { useTranslation } from 'react-i18next';

import logo from "logo.svg";
var ps;

function Sidebar(props) {
  const { t } = useTranslation();
  const [openOpts, setOpenOpts] = useState(new Map());
  const location = useLocation();
  const sidebar = React.useRef();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  function toggleMenu(key) {
    for (let [key, value] of openOpts) {
      console.log(key + " = " + value);
      if(openOpts.get(key)) {
        openOpts.set(key, false);
      }
    }

    let current = openOpts.get(key);
    openOpts.set(key, !current);
    setOpenOpts(openOpts);
  }

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo">
        <a href="https://portal.congtyphapquang.vn" className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="react-logo" />
          </div>
        </a>
        <a href="https://portal.congtyphapquang.vn" className="simple-text logo-normal">
          ADMIN SPACE
        </a>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          {props.routes.map((prop, key) => {
            return (
              <li className={activeRoute(prop.path)} key={key}>
                <NavItem onClick={() => toggleMenu(key)}>
                  <NavLink to={prop.layout + prop.path} className="nav-NavLink">
                      <i className={prop.icon} />
                      <p>{t(prop.name)}
                        {prop.routes && <span
                          className="pull-right nc-icon nc-minimal-down"
                          style={{
                            "padding-top": "15px",
                            transform: openOpts.get(key)
                              ? 'rotate(0deg)'
                              : 'rotate(-90deg)',
                            transitionDuration: '0.5s',
                            transitionProperty: 'transform',
                          }}
                        />}
                      </p>
                  </NavLink>
                </NavItem>
                {prop.routes &&
                  <Collapse isOpen={openOpts.get(key)}>
                    {prop.routes.map((prop2, key2) => {
                      return (
                        <NavItem>
                          <NavLink to={prop.layout + prop.path + "/" + prop2.path}>
                            <i className={prop2.icon} />
                            <p style={{color: "green"}}>{t(prop2.name)}</p>
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </Collapse>
                }
              </li>
            );
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
