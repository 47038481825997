import axios from "axios";
import HttpUtils from "../../utils/http-utils.js";

let sloganBgList;
let quoteBgList;

class AlbumStore {
  sloganBgImages = async function() {
    if(!sloganBgList) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/albums/sloganBgImages",
          { headers: new HttpUtils().getHeaders()});
      sloganBgList = response.data;
    }
    return sloganBgList;
  }

  quoteBgImages = async function() {
    if(!quoteBgList) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/albums/quoteBgImages",
          { headers: new HttpUtils().getHeaders()});
      quoteBgList = response.data;
    }
    return quoteBgList;
  }
}

export default AlbumStore;