class OrderSearchDto {
  fromDate;
  toDate;
  orderNo;
  pageNo;
  pageSize;
  sortBy;
  sortAsc;

  constructor(data) {
    this.fromDate = data?.fromDate;
    this.toDate = data?.toDate;
    this.orderNo = data?.orderNo;
    this.pageNo = data?.pageNo;
    this.pageSize = data?.pageSize;
    this.sortBy = data?.sortBy;
    this.sortAsc = data?.sortAsc;
  }
}

export default OrderSearchDto;
