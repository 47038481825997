import React, { useEffect, useState } from "react"; 
import AccountStore from "./account-store.js";
import AccountSearchDto from "./account-search-dto.js";
import AccountItem from "./account-item.js";
import AccountSearch from "./accounts-search.js";
import AccountPaging from "./accounts-paging.js";
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new AccountStore();

function AccountList() {
  const { t } = useTranslation();

  const [accountSearchPage, setAccountSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let searchReq = new AccountSearchDto();
    store.search(searchReq).then((data) => {
      setAccountSearchPage(data);
      setLoaded(true);
    });
  }, []);
  
  return (
    <>
      <div className="content">
        <AccountSearch store={store} callback={(data) => {setAccountSearchPage(data);}}/>
        <span style={{display: loaded ? "none" : "block"}}>
          <Row>
            <Col>
              <Progress animated color="info" striped value={100}/>
            </Col>
          </Row>
        </span>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{t('admin.accounts.list')}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>{t('admin.accounts.id')}</th>
                      <th>{t('admin.accounts.username')}</th>
                      <th>{t('admin.accounts.email')}</th>
                      <th>Ứng dụng</th>
                      <th>{t('admin.accounts.role')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountSearchPage?.content?.map(item => (
                      <AccountItem id={item.id} key={item.id} data={item} store={store}/>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AccountPaging searchPage={accountSearchPage}/>
      </div>
    </>
  );
}

export default AccountList;
