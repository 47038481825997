import React, { useEffect, useState } from "react"; 
import AuditLogStore from "./auditlog-store.js";
import AuditLogSearchDto from "./auditlog-search-dto.js";
import AuditLogItem from "./auditlog-item.js";
import AuditLogSearch from "./auditlog-search.js";
import AuditLogPaging from "./auditlogs-paging.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new AuditLogStore();

function AuditLogList() {
  const [AuditLogSearchPage, setAuditLogSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let searchReq = new AuditLogSearchDto();
    store.search(searchReq).then((data) => {
      setAuditLogSearchPage(data);
      setLoaded(true);
    });
  }, []);
  
  return (
    <>
      <div className="content">
        <AuditLogSearch store={store} callback={(data) => {setAuditLogSearchPage(data);}}/>
        <span style={{display: loaded ? "none" : "block"}}>
          <Row>
            <Col>
              <Progress animated color="info" striped value={100}/>
            </Col>
          </Row>
        </span>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">AUDIT LOGS</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Id</th>
                      <th>User</th>
                      <th>Action</th>
                      <th>DateTime</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {AuditLogSearchPage?.content?.map(item => (
                      <AuditLogItem id={item.id} key={item.id} data={item} store={store}/>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AuditLogPaging searchPage={AuditLogSearchPage}/>
      </div>
    </>
  );
}

export default AuditLogList;
