import React, { useState, useEffect } from "react";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

function MediaSuggestion(props) {
  const [multiSelections, setMultiSelections] = useState([]);
  const [attachableMedias, setAttachableMedias] = useState(props.attachableMedias);

  function handleSelection(data) {
    setMultiSelections(data);
    let ids = data.map((x, i) => x.id).join(',');
    console.log(ids);
    props.callback(ids);
  }

  useEffect(() => {
    if(props.mediaIds) {
      setMultiSelections(props.attachableMedias.filter((x) => props.mediaIds.includes(x.id)));
    }
  }, []);

  return <Typeahead
         id="basic-typeahead-multiple"
         labelKey="title"
         multiple
         onChange={handleSelection}
         options={attachableMedias}
         placeholder="Choose some media..."
         selected={multiSelections}
       />;
}

export default MediaSuggestion;
