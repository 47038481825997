import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import OrderSearchDto from "./order-search-dto.js";
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Button,
  FormGroup,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function BookSearch(props) {
  const { t } = useTranslation();

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [status, setStatus] = useState('');
  const [statusName, setStatusName] = useState('');

  const statusList = [
    {"code": "PAID", "name": t('orders.status.paid')},
    {"code": "TRANSFERRED", "name": t('orders.status.transferred')},
    {"code": "FINISHED", "name": t('orders.status.finished')},
    {"code": "CANCELED", "name": t('orders.status.cancelled')},
  ];

  function handleStatusChange(event) {
    let selected = event.target.value;
    setStatus(selected);
    setStatusName(statusList.filter(item => item.code === selected)[0].name);
  }

  function handleSearch(event, callback) {
    let searchReq = new OrderSearchDto({
      "fromDate": fromDate,
      "toDate": toDate,
      "status": status
    });
    props.store.search(searchReq).then(props.callback);
  }

  function handleExport(event, callback) {
    let searchReq = new OrderSearchDto({
      "fromDate": fromDate,
      "toDate": toDate,
      "status": status
    });
    props.store.export(searchReq).then(props.callback);
  }

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  return (
    <>
      <Row>
        <Col className="pl-3" md="2">
          <FormGroup>
            <label>{t('common.from_date')}</label>
            <DatePicker selected={fromDate} className="form-control"
                    onChange={(date) => setFromDate(date)}
                    dateFormat="dd-MM-yyyy"
                    timeClassName={handleColor}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="2">
          <FormGroup>
            <label>{t('common.to_date')}</label>
            <DatePicker selected={toDate} className="form-control"
                      onChange={(date) => setToDate(date)}
                      dateFormat="dd-MM-yyyy"
                      timeClassName={handleColor}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="2">
          <FormGroup>
            <UncontrolledDropdown group className="uncontrolled-dropdown">
                <DropdownToggle caret>
                    {status && statusName } {!status && t('common.status') }
                </DropdownToggle>
                <DropdownMenu>
                    {statusList.map((x, i) => <DropdownItem onClick={handleStatusChange}
                        key={x.code} value={x.code}>{x.name}</DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary" type="submit">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleExport} className="btn-round form-control" color="primary" type="submit">
              Export Excel
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default BookSearch;
