import React from "react";
import 'react-pure-modal/dist/react-pure-modal.min.css';

function RoleItem(props) {
  return (
    <>
      <tr>
        <td>{props.data.roleId}</td>
        <td>{props.data.code}</td>
        <td>{props.data.name}</td>
        <td>View details</td>
      </tr>
    </>
  );
}

export default RoleItem;