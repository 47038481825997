import React, { useState } from "react";

import 'react-pure-modal/dist/react-pure-modal.min.css';

import EditButton from "component/edit-button.js";
import DeleteButton from "component/delete-button.js";
import LibraryItemInfo from "./component/libraryitem-info.js";
import DisableButton from "component/disable-button.js";
import EnableButton from "component/enable-button.js";

import LibraryItemStore from "./libraryitem-store.js";
import LibraryItemDto from "./libraryitem-dto.js";
import LibraryItemEdit from "./libraryitem-edit.js";
import { useTranslation } from 'react-i18next';

import {
  Row, Badge
} from "reactstrap";

function LibraryItemView(props) {
  const [writable, setWritable] = useState(false);
  const [sell, setSell] = useState(true);
  const [message, setMessage] = useState("processing... it takes approximate 5 minutes or faster");

  const [data] = useState(props.data);

  function saveDirtyValues(dto: LibraryItemDto) {
    new LibraryItemStore().update(
      dto,
      (res) => {
        if(res.data) {
          setMessage(res.data);
        } else {
          setMessage("Cannot update new values ....");
        }
      }
    );
  }

  async function remove(mediaId: int) {
    props.showProgressingFn();
    await new LibraryItemStore().remove(
      mediaId,
      (res) => {
      }
    );
    props.refresh();
  }

  return (
    <>
      <LibraryItemViewRead hide={writable} data={data}
        onEdit={() => setWritable(true)}
        onDelete={(id) => {remove(id);}}/>
      <tr className={!writable ? "hide" : undefined}>
        <td colSpan="6">
          <LibraryItemEdit show={writable} data={data}
            refresh={props.refresh}
            notiRef={props.notiRef}
            attachableMedias={props.attachableMedias}
            attachableBooks={props.attachableBooks}
            onUpdate={(dto) => {saveDirtyValues(dto)}}
            onReadonly={() => setWritable(false)}/>
        </td>
      </tr>
    </>
  );
}

function LibraryItemViewRead(props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(props.data.status);

  return (
    <tr className={props.hide ? "hide" : undefined}>
      <td>
        {props.data.id}
      </td>
      <td width="12%">
        {props.data.typeTxt}
      </td>
      <td width="10%">
        <img alt="" src={props.data.bgImageSrc}/>
      </td>
      <td width="35%">
        {props.data.title && <>{props.data.title.replace(/<\/?[^>]+(>|$)/g, "")} <br/><br/></> }
        {props.data.titleEn && <>{props.data.titleEn.replace(/<\/?[^>]+(>|$)/g, "")} <br/><br/></> }
        {props.data.titleCn && <>{props.data.titleCn.replace(/<\/?[^>]+(>|$)/g, "")} <br/><br/></> }
        {props.data.content && <>{props.data.content.substring(0, 150).replace(/<\/?[^>]+(>|$)/g, "")} {"..."} <br/><br/></> }
        {props.data.contentEn && <>{props.data.contentEn.substring(0, 150).replace(/<\/?[^>]+(>|$)/g, "")} <br/><br/></> }
        {props.data.contentCn && <>{props.data.contentCn.substring(0, 150).replace(/<\/?[^>]+(>|$)/g, "")} <br/><br/></> }
        {props.data.updatedAt}
      </td>
      <td>
        {props.data.bookName}<br/>
        {props.data.mediaIds && <>MEDIA ID: {props.data.mediaIds}</>}
      </td>
      <td>
        <Row>
          <EditButton id={props.data.id}
            className={props.data.queueStatusStr ? "hide" : undefined}
            callback={(id) => props.onEdit()}/><br/>
          <DeleteButton id={props.data.id} callback={(id) => {props.onDelete(id);}}/>
          <DisableButton className={(status !== 'ACTIVE' && status !== 'INACTIVE') ? "hide" : undefined}
            id={props.data.id} name={props.data.title} callback={(id) => {setStatus('DISABLED'); props.onDisable(id);}}/>
          <EnableButton className={status === 'DISABLED' ? undefined : "hide"}
            id={props.data.id} name={props.data.title}
            callback={(id, time) => {setStatus('INACTIVE'); props.onEnable(id, time);}}/>
        </Row>
      </td>
    </tr>
  );
}

export default LibraryItemView;