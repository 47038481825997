import {
  Button,
} from "reactstrap";

function EditButton(props) {
  return <span style={{cursor: "pointer"}} className={props.className}>
    <Button className="btn-round" color="success"
        onClick={() => {props.callback(props.id);}}
        outline size="sm">
      <i className="fa fa-edit" /> Thay Đổi
    </Button>
  </span>;
}

export default EditButton;
