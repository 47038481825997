import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import MediaFileStore from "../mediafile-store.js";
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  FormGroup,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function SetupNotifyButton(props) {
  const { t } = useTranslation();
  const [notifyModal, setNotifyModal] = useState(false);
  const [notifyTime, setNotifyTime] = useState();

  function handleSetupNotifyApp(event) {
    setNotifyModal(false);
    if(notifyTime) {
      new MediaFileStore().setupNotifyApp(props.id, notifyTime);
    }
  }

  return <span style={{cursor: "pointer"}}>
    <Modal isOpen={notifyModal} fade={false} toggle={()=>setNotifyModal(false)}>
      <ModalHeader toggle={()=>setNotifyModal(false)}>Thiết lập thời gian thông báo</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="12">
            <FormGroup>
              <label>Chọn thời gian</label><br/>
              <DatePicker showTimeSelect selected={notifyTime}
                          onChange={(date) => setNotifyTime(date)}
                          dateFormat="dd-MM-yyyy hh:mm aa"
                          className="form-control"/>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSetupNotifyApp}>
          OK
        </Button>{' '}
        <Button color="secondary" onClick={()=>setNotifyModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setNotifyModal(true)} >
      <Button className="btn-round btn-icon" color="warning" outline size="sm">
        <i className="fa fa-clock" />
      </Button>{props.btnTitle}
    </span>
  </span>;
}

export default SetupNotifyButton;
