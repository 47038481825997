import axios from "axios";
import HttpUtils from "../../utils/http-utils.js";

class OrderStore {
  search = async function(searchParams: OrderSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/orders/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(orderId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/orders/" + orderId,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  update = async function(order: OrderDto) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/orders", order, { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log(res);
          console.log(res.data);
        });
  }

  export = async function(searchParams: OrderSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/orders/export", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }
}

export default OrderStore;