import React, { useState } from "react";

import MediaFileStore from "../mediafile-store.js";

import {
  Button,
  Spinner, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function CreateHlsButton(props) {
  const [createHlsModal, setCreateHlsModal] = useState(false);

  function handleCreateHls(event) {
    setCreateHlsModal(false);
    new MediaFileStore().createHls(props.id);
  }

  return <span style={{cursor: "pointer"}}>
    <Modal isOpen={createHlsModal} fade={false} toggle={()=>setCreateHlsModal(false)} centered>
      <ModalHeader toggle={() => setCreateHlsModal(false)}>Tạo lại file HLS</ModalHeader>
      <ModalBody>
        Đây là chức năng dành cho ADMIN để tạo lại file HLS. Bấm <b>Đồng Ý</b> nếu bạn biết rõ công việc này.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleCreateHls}>
          Đồng ý
        </Button>{' '}
        <Button color="secondary" onClick={()=>setCreateHlsModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setCreateHlsModal(true)}>
      <Button className="btn-round btn-icon" color="danger" outline size="sm">
        <i className="fa fa-bug" />
      </Button>Sửa lỗi phát
    </span>
  </span>;
}

export default CreateHlsButton;
