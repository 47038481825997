import axios from "axios";
import HttpUtils from "../../utils/http-utils.js";

class DashboardStore {
  summary = async function() {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/dashboard/summary",
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  summaryCp = async function() {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/dashboard/summaryCp",
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  monthlyPkg = async function() {
    let response = await axios
      .get(process.env.REACT_APP_API_URL + "/dashboard/monthlyPkg", { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  monthlySeries = async function() {
    let response = await axios
      .get(process.env.REACT_APP_API_URL + "/dashboard/monthlySeries", { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  packageStatisticsChartData = function(data) {
    return {
      labels: ["30", "60", "90", "180", "360"],
      datasets: [
        {
          label: "Emails",
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#fcc468", "#4acccd", "#e3e3e3", "#ef8157", "blue"],
          borderWidth: 0,
          data: data,
        },
      ],
    };
  }

  viewPerMonthStatisticsChartData = function(tFn, data) {
    return {
      labels: [
        tFn("charts.m1"),
        tFn("charts.m2"),
        tFn("charts.m3"),
        tFn("charts.m4"),
        tFn("charts.m5"),
        tFn("charts.m6"),
        tFn("charts.m7"),
        tFn("charts.m8"),
        tFn("charts.m9"),
        tFn("charts.m10"),
        tFn("charts.m11"),
        tFn("charts.m12")
      ],
      datasets: [
        {
          label: 'PQ - THÀNH CÔNG',
          data: data.totalViews,
          fill: true,
          borderColor: "blue",
          backgroundColor: "transparent",
          pointBorderColor: "blue",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        },
        {
          label: 'PQ - BỊ LỖI',
          data: data.totalViewFails,
          fill: true,
          borderColor: "red",
          backgroundColor: "transparent",
          pointBorderColor: "red",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        },
        {
          label: 'CP - THÀNH CÔNG',
          data: data.totalViewsCp,
          fill: true,
          borderColor: "green",
          backgroundColor: "transparent",
          pointBorderColor: "green",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4
        },
        {
          label: 'CP - BỊ LỖI',
          data: data.totalViewFailsCp,
          fill: true,
          borderColor: "yellow",
          backgroundColor: "transparent",
          pointBorderColor: "yellow",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4
        }
      ],
    };
  }

  costPerMonthStatisticsChartData = function(tFn, data) {
    return {
      labels: [
        tFn("charts.m1"),
        tFn("charts.m2"),
        tFn("charts.m3"),
        tFn("charts.m4"),
        tFn("charts.m5"),
        tFn("charts.m6"),
        tFn("charts.m7"),
        tFn("charts.m8"),
        tFn("charts.m9"),
        tFn("charts.m10"),
        tFn("charts.m11"),
        tFn("charts.m12")
      ],
      datasets: [
        {
          label: 'BĂNG THÔNG (GB)',
          data: data.totalBandwidths,
          fill: true,
          borderColor: "#51CACF",
          backgroundColor: "transparent",
          pointBorderColor: "#51CACF",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        },
        {
          label: 'CHI PHÍ (USD) * 10',
          data: data.totalCost,
          fill: true,
          borderColor: "#ef8157",
          backgroundColor: "transparent",
          pointBorderColor: "#ef8157",
          pointRadius: 4,
          pointHoverRadius: 4,
          pointBorderWidth: 8,
          tension: 0.4,
        }
      ],
    };
  }
}

export default DashboardStore;