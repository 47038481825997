import axios from "axios";
import HttpUtils from "../../utils/http-utils.js";

class UserStore {
  search = async function(searchParams: UserSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/users/search", searchParams,
          { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(userId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/users/" + userId,
          { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  sendMsg = async function(userId: int, message) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/users/send-message",
      message, { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  update = async function(user: UserDto) {
    axios
        .post(process.env.REACT_APP_API_URL + "/users", user,
          { headers: new HttpUtils().getHeaders()}
        ).then((res) => {
          console.log(res);
          console.log(res.data);
        });
  }
}

export default UserStore;