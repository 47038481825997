import React, { useState, useEffect } from "react";
import axios from "axios";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import PureModal from 'react-pure-modal';
import UndoButton from "component/undo-button.js";
import SaveButton from "component/save-button.js";

import CategoryStore from "../category/category-store.js";
import HttpUtils from "utils/http-utils.js";
import NotiUtils from "utils/noti-utils.js";
import { useTranslation } from 'react-i18next';
import OriginalMediaSuggestion from "./component/original-media-suggestion.js";
import MediaFileStore from "./mediafile-store.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Progress,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function MediafileEdit(props) {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [genres1, setGenres1] = useState([]);
  const [genres2, setGenres2] = useState([]);
  const [categoryCode, setCategoryCode] = useState(props.data.categoryCode);
  const [categoryName, setCategoryName] = useState(props.data.category);
  const [genre1Code, setGenre1Code] = useState(props.data.genre1Code);
  const [genre2Code, setGenre2Code] = useState(props.data.genre2Code);
  const [genre1Name, setGenre1Name] = useState(props.data.genre1);
  const [genre2Name, setGenre2Name] = useState(props.data.genre2);
  const [title, setTitle] = useState(props.data.title);
  const [bgColorFrom, setBgColorFrom] = useState(props.data.bgColorFrom);
  const [bgColorTo, setBgColorTo] = useState(props.data.bgColorTo);

  const [originalMediaIds, setOriginalMediaIds] = useState(props.data.originalMediaIds);

  const [selectedCategoryOption, setSelectedCategoryOption] = useState({
    value: props.data.categoryCode,
    label: props.data.category
  });
  const [selectedGenre1Option, setSelectedGenre1Option] = useState({
    value: props.data.genre1Code,
    label: props.data.genre1
  });
  const [selectedGenre2Option, setSelectedGenre2Option] = useState({
    value: props.data.genre2Code,
    label: props.data.genre2
  });

  const [previewImg, setPreviewImg] = useState(props.data.imageUrl);
  const [imageFile, setImageFile] = useState('');
  const [mediaFile, setMediaFile] = useState('');
  const [imageProgress, setImageProgress] = useState('');
  const [mediaProgress, setMediaProgress] = useState('');
  const [recordAt, setRecordAt] = useState(null);
  const [recordPlace, setRecordPlace] = useState(props.data.recordPlace);
  const [publishNo, setPublishNo] = useState(props.data.publishNo);

  const [result, setResult] = useState('');
  const [notifyModal, setNotifyModal] = useState(false);

  useEffect(() => {
    new CategoryStore().list().then((data) => {
      setCategories(data);

      let selected = categoryCode;
      let selectedCat = data.filter(item => item.code === selected)[0];
      setCategoryCode(selected);
      setCategoryName(selectedCat.name);
      setGenres1(selectedCat.genres);
    });
  }, []);

  function onMediaSelected(event) {
    setMediaFile(event.target.files[0]);
  }

  function handlePublishNo(event) {
    let selected = event.target.value;
    setPublishNo(selected);
  }

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  function handleRecordAt(event) {
    let selected = event.target.value;
    setRecordAt(selected);
  }

  function handleRecordPlace(event) {
    let selected = event.target.value;
    setRecordPlace(selected);
  }

  function onImageSelected(event) {
    setImageFile(event.target.files[0]);
    setPreviewImg(URL.createObjectURL(event.target.files[0]));
  }

  function handleCategoryChange(event) {
    let selected = event.value;
    let selectedCat = categories.filter(item => item.code === selected)[0];
    setCategoryCode(selected);
    setCategoryName(selectedCat.name);
    setGenres1(selectedCat.genres);

    setGenre1Code('');
    setGenre1Name('');
    setGenre2Code('');
    setGenre2Name('');
    setGenres2([]);

    setSelectedCategoryOption({
      value: selected,
      label: selectedCat.name
    });
    setSelectedGenre1Option('');
    setSelectedGenre2Option('');
  }

  function handleGenre1Change(event) {
    let selected = event.value;
    let selectedGenre1 = genres1.filter(item => item.code === selected)[0];
    setGenre1Code(selected);
    setGenre1Name(selectedGenre1.name);
    setGenre2Code('');
    setGenre2Name('');

    if(selectedGenre1.genres) {
      setGenres2(selectedGenre1.genres);
    } else {
      setGenres2([]);
    }
    setSelectedGenre1Option({
      value: selected,
      label: selectedGenre1.name
    });
    setSelectedGenre2Option('');
  }

  function handleGenre2Change(event) {
    let selected = event.value;
    let selectedGenre1 = genres2.filter(item => item.code === selected)[0];
    setGenre2Code(selected);
    setGenre2Name(selectedGenre1.name);

    setSelectedGenre2Option({
      value: selected,
      label: selectedGenre1.name
    });
  }

  function handleTitle(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function validate() {
    let movedGenre1 = props.data.genre1Code !== genre1Code;
    let movedGenre2 = props.data.genre2Code !== genre2Code;
    let changedOriginal = props.data.originalMediaIds !== originalMediaIds;
    let moved = movedGenre1 || movedGenre2;
    let changedTitle = props.data.title !== title;
    let updatedImage = imageFile !== '';
    let updatedMedia = mediaFile !== '';
    let changedAssets = updatedImage || updatedMedia;
    if(changedAssets && moved) {
      setResult("We don't support to move and change assets at same time");
      return;
    }
    if(moved || changedTitle || updatedImage || updatedMedia || changedOriginal) {
      setNotifyModal(true);
    } else {
      new NotiUtils().notify(props.notiRef, 'info', "Nothing changed to update");
    }
  }

  async function onUpdate(e) {
    setNotifyModal(false);
    setResult("Please wait for processing...");
    let movedGenre1 = props.data.genre1Code !== genre1Code;
    let movedGenre2 = props.data.genre2Code !== genre2Code;
    let moved = movedGenre1 || movedGenre2;
    let changedTitle = props.data.title !== title.trim();
    let changedBgColorFrom = props.data.bgColorFrom !== bgColorFrom;
    let changedBgColorTo = props.data.bgColorFrom !== bgColorTo;
    let updatedImage = imageFile !== '';
    let updatedMedia = mediaFile !== '';
    if(updatedImage) {
      await updateImageFile(e);
    }
    if(updatedMedia) {
      await updateMediaFile(e);
    }
    props.onUpdate({
        'id': props.data.id,
        'title': changedTitle ? title.trim() : undefined,
        'bgColorFrom': changedBgColorFrom ? bgColorFrom : undefined,
        'bgColorTo': changedBgColorTo ? bgColorTo : undefined,
        'categoryCode': moved ? categoryCode : undefined,
        'genre1Code': moved ? genre1Code : undefined,
        'genre2Code': moved ? genre2Code : undefined,
        'updateImage': updatedImage,
        "originalMediaIds": originalMediaIds,
        "publishNo": publishNo,
        "recordAt": recordAt,
        "recordPlace": recordPlace
      });
    updateSrc(props.data);
    if(updatedMedia) {
      setResult("Vui lòng chờ 30 phút để xử lý file mới trên server trước khi phát trên AppPQ");
    } else {
      setResult("Updated data successfully!");
    }
  }

  function updateSrc(src) {
    src.title = title;
    src.categoryCode = categoryCode;
    src.category = categoryName;
    src.genre1Code = genre1Code;
    src.genre1 = genre1Name;
    src.genre2Code = genre2Code;
    src.genre2 = genre2Name;
    src.originalMediaIds = originalMediaIds;
  }

  async function updateImageFile(e)  {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
        "imageFile",
        imageFile,
        imageFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/updateImageFile/" + props.data.id, formData, {
        onUploadProgress: data => setImageProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    );
    return res.data === 'true';
  }

  async function updateMediaFile(e) {
    e.preventDefault()
    const formData = new FormData();
    formData.append(
        "mediaFile",
        mediaFile,
        mediaFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/updateMediaFile/" + props.data.id, formData, {
        onUploadProgress: data => setMediaProgress(Math.round((100 * data.loaded) / data.total)),
        headers: new HttpUtils().getHeaders()
      }
    )
    return res.data === 'true';
  }

  function getCategoryList() {
    return categories.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function handleBgColorFrom(event) {
    let selected = event.target.value;
    setBgColorFrom(selected);
  }

  function handleBgColorTo(event) {
    let selected = event.target.value;
    setBgColorTo(selected);
  }

  function getGenre1List() {
    return genres1.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function getGenre2List() {
    return genres2.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function handleOriginalChange(ids) {
    setOriginalMediaIds(ids);
  }

  return (
    <div className={!props.show ? "hide" : undefined}>
      <Row>
        <Col md="4">
          <Card className="card-user">
            <CardBody>
              <div className="image2">
                {previewImg && <img alt="..." src={previewImg} />}
              </div>
            </CardBody>
            <CardFooter>
              <div className="author2">
                {title}
              </div>
              {categoryName && <p className="text-center">
                <b>{categoryName} > </b>
                <b> {genre1Name} </b> <b> > {genre2Name}</b>
              </p>}
              <center><b>ỨNG DỤNG <span style={{color: "red"}}> {props.data.appSource.toUpperCase()} </span></b></center>
            </CardFooter>
          </Card>
        </Col>
        <Col md="8">
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">UPDATE NEW DATA - <span style={{color: "red"}}> {props.data.appSource.toUpperCase()} </span></CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row><Col md="12"><div className={"blockquote blockquote-warning"}> Trường thông tin có dấu <span style={{color: "red"}}> * </span> bắt buộc phải nhập</div></Col></Row>
                <Row>
                  <Col className="pr-3" md="12">
                    <FormGroup>
                      <label>Title</label>
                      <Input
                        placeholder="Input title here"
                        type="text" onChange={handleTitle} defaultValue={title}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>{t('category.category')} <span style={{color: "red"}}> * </span></label>
                      <Select options={getCategoryList()} value={selectedCategoryOption} onChange={handleCategoryChange}/>
                    </FormGroup>
                  </Col>
                  <Col className="px-1" md="4">
                    <FormGroup>
                      <label>{t('category.genre_1')} <span style={{color: "red"}}> * </span></label>
                      <Select options={getGenre1List()} value={selectedGenre1Option} onChange={handleGenre1Change}/>
                    </FormGroup>
                  </Col>
                  <Col className="px-3" md="4">
                    <FormGroup>
                      <label>{t('category.genre_2')}</label>
                      <Select options={getGenre2List()} value={selectedGenre2Option} onChange={handleGenre2Change}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Browse JPG image</label>
                      <Input
                        placeholder="Please select image to upload"
                        type="file" accept="image/jpg"
                        onChange={onImageSelected}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Browse Mp3 or Mp4 file</label>
                      <Input
                        placeholder="Please select file to upload"
                        type="file" accept=".mp3,audio/*,.mp4"
                        onChange={onMediaSelected}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Upload image progress</label>
                      <Progress
                        value={imageProgress}
                      > {imageProgress < 100 ? "uploading" : "done"} </Progress>
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Upload media progress</label>
                      <Progress
                        value={mediaProgress}
                      > {mediaProgress < 100 ? "uploading" : "done"} </Progress>
                    </FormGroup>
                  </Col>
                </Row>
                {categoryCode === 'SHORTVIDEO' && <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Bài giảng gốc liên kết <span style={{color: "red"}}> * </span></label>
                      <div>
                        <OriginalMediaSuggestion callback={(data) => {handleOriginalChange(data);}}
                          shortLinkableMedias={props.shortLinkableMedias}
                          originalMediaIds={props.data.originalMediaIds}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>}
                {categoryCode !== 'SHORTVIDEO' &&
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('files.record_at')}</label>
                        <div>
                          <DatePicker selected={recordAt}
                            onChange={(date) => setRecordAt(date)}
                            dateFormat="dd-MM-yyyy"
                            className="form-control"
                            timeClassName={handleColor}/>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('files.record_place')}</label>
                        <Input
                          placeholder={t('files.record_place')}
                            type="text" onChange={handleRecordPlace} defaultValue={recordPlace}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-4" md="4">
                      <FormGroup>
                        <label>{t('files.publish_no')}</label>
                        <Input
                          placeholder={t('files.publish_no')}
                            type="text" onChange={handlePublishNo} defaultValue={publishNo}
                        />
                      </FormGroup>
                    </Col>
                  </Row>}
                <hr/>
                {categoryCode !== 'SHORTVIDEO' &&
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <label>Mã màu nền bắt đầu</label>
                      <Input
                            placeholder="Mã màu nền bắt đầu"
                            type="text" onChange={handleBgColorFrom} defaultValue={bgColorFrom}
                          />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <label>Mã màu nền kết thúc</label>
                      <Input
                          placeholder="Mã màu nền kết thúc"
                          type="text" onChange={handleBgColorTo} defaultValue={bgColorTo}
                        />
                    </FormGroup>
                  </Col>
                </Row>}
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label className="title text-warning">{result}</label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    <SaveButton callback={validate}/> &nbsp;
                    <UndoButton callback={(id) => {props.onReadonly(); props.refresh();}}/>
                    <PureModal width="400px" header="Please confirm"
                        footer={
                         <div>
                           <button onClick={() => setNotifyModal(false)}>Cancel</button>
                           <button onClick={onUpdate}>Yes</button>
                         </div>
                        }
                        isOpen={notifyModal}
                        onClose={()=>setNotifyModal(false)}
                      >
                        <p>Are you sure you want to save new data?</p>
                        {props.data.title !== title ? <><p>Current title: <b>{props.data.title}</b><br/>New title: <b>{title}</b></p><hr/></>: undefined}
                        {props.data.categoryCode !== categoryCode ? <><p>Current category code: <b>{props.data.categoryCode}</b><br/>New category code: <b>{categoryCode}</b></p><hr/></>: undefined}
                        {props.data.genre1Code !== genre1Code ? <><p>Current genre1 code: <b>{props.data.genre1Code}</b><br/>New genre1 code: <b>{genre1Code}</b><hr/></p></>: undefined}
                        {props.data.genre2Code !== genre2Code ? <><p>Current genre2 code: <b>{props.data.genre2Code}</b><br/>New genre2 code: <b>{genre2Code}</b><hr/></p></>: undefined}
                        {props.data.originalMediaIds !== originalMediaIds ? <><p>Current Original: <b>{props.data.originalMediaIds}</b><br/>New original media: <b>{originalMediaIds}</b><hr/></p></>: undefined}
                        {imageFile !== '' ? <><p><b>Upload new image</b></p><br/></>: undefined}
                        {mediaFile !== '' ? <><p><b>Upload new media</b></p><br/></>: undefined}
                      </PureModal>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default MediafileEdit;
