const statisticsChartOptions = {
  plugins: {
    legend: { display: true },
    tooltip: { enabled: true },
  },
  maintainAspectRatio: true,
  pieceLabel: {
    render: "percentage",
    fontColor: ["white"],
    precision: 2,
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    x: {
      barPercentage: 1.6,
      grid: {
        drawBorder: false,
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  }
};

const perMonthStatisticsChartOptions = {
  plugins: {
    legend: { display: true },
    tooltip: { enabled: true },
  },
};

module.exports = {
  statisticsChartOptions,
  perMonthStatisticsChartOptions,
};
