import React, { useState } from "react";
import DatePicker from "react-datepicker";

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup,
  Form,
  Input,
  Label
} from "reactstrap";

function EnableButton(props) {
  const [modal, setModal] = useState(false);
  const [publishTime, setPublishTime] = useState(new Date());

  return <span style={{cursor: "pointer"}} className={props.className}>
    <Modal isOpen={modal} fade={false} toggle={()=>setModal(false)} centered>
      <ModalHeader toggle={() => setModal(false)}>HIỆN BÀI</ModalHeader>
      <ModalBody>
        HIỆN BÀI <b>{props.name}</b> - Vui lòng xác nhận một lần nữa?

        <br/>
        <br/>
        <FormGroup>
          <label>Thời gian hiện lên ứng dụng</label><br/>
          <DatePicker showTimeSelect selected={publishTime}
            onChange={(date) => setPublishTime(date)}
            dateFormat="dd-MM-yyyy hh:mm aa"
            className="form-control"/>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {setModal(false); props.callback(props.id, publishTime);}}>
          Xác nhận
        </Button>{' '}
        <Button color="secondary" onClick={()=>setModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setModal(true)}>
      <Button className="btn-round" outline size="sm" color="primary">
        <i className="fa fa-eye" /> Hiện bài
      </Button>
    </span>
  </span>;
}

export default EnableButton;
