/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";

function Footer(props) {
  return (
    <footer className={"footer" + (props.default ? " footer-default" : "")}>
      <Container fluid={props.fluid ? true : false}>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="https://thientonphatquang.com" target="_blank">
                  THIỀN TÔN PHẬT QUANG
                </a>
              </li>
              <li>
                <a href="https://congtyphapquang.com" target="_blank">
                  CÔNG TY PHÁP QUANG
                </a>
              </li>
              <li>
                <a href="https://apppq.congtyphapquang.vn" target="_blank">
                  APP PHÁP QUANG
                </a>
              </li>
              <li>
                <a href="https://qr.congtyphapquang.vn/home" target="_blank">
                  QR
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <div className="copyright">
              &copy; {1900 + new Date().getYear()}, made with{" "}
              <i className="fa fa-heart heart" /> by SOMEONE
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
