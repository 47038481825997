import axios from "axios";
import AuditLogSearchDto from "./auditlog-search-dto.js";
import HttpUtils from "utils/http-utils.js";

class AuditLogStore {
  search = async function(searchParams: AuditLogSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/auditlogs/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }
}

export default AuditLogStore;