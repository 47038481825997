import axios from "axios";
import AccountSearchDto from "./account-search-dto.js";
import HttpUtils from "utils/http-utils.js";

class AccountStore {
  search = async function(searchParams: AccountSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/accounts/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(AccountId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/accounts/" + AccountId,
              { headers: new HttpUtils().getHeaders() });
    return response.data;
  }

  update = async function(Account: AccountDto) {
    await axios.Account(process.env.REACT_APP_API_URL + "/accounts", Account,
          {headers: new HttpUtils().getHeaders() })
        .then((res) => {
          console.log(res);
          console.log(res.data);
        });
  }
}

export default AccountStore;