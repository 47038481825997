import React, { useEffect, useState } from "react";
import CategoryStore from "./category-store.js";
import CategorySearchDto from "./category-search-dto.js";
import CategoryItem from "./category-item.js";
import CategorySearch from "./categories-search.js";
import CategoryPaging from "./categories-paging.js";
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new CategoryStore();

function CategoryList() {
  const { t } = useTranslation();

  const [categoriesSearchPage, setCategoriesSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let searchReq = new CategorySearchDto();
    store.search(searchReq).then((data) => {
      setCategoriesSearchPage(data);
      setLoaded(true);
    });
  }, []);

  return (
    <>
      <CategorySearch store={store} callback={(data) => {setCategoriesSearchPage(data);}}/>
      <span style={{display: loaded ? "none" : "block"}}>
        <Row>
          <Col>
            <Progress animated color="info" striped value={100}/>
          </Col>
        </Row>
      </span>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{t('category.category_list')}</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive hover>
                <thead className="text-primary">
                  <tr>
                    <th>{t('category.category_id')}</th>
                    <th>{t('category.category_name')}</th>
                    <th>{t('category.genre_id')}</th>
                    <th>{t('category.genre_1')}</th>
                    <th>{t('category.genre_2')}</th>
                  </tr>
                </thead>
                <tbody>
                  {categoriesSearchPage?.content?.map(item => (
                      <CategoryItem id={item.genreId} data={item} store={store}/>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default CategoryList;
