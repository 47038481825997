class BookSearchDto {
  ids;
  title;
  categoryId;
  inStock;
  pdfOption;
  bookGroup;
  genre1Id;
  genre2Id;
  pageNo;
  pageSize;
  sortBy;
  sortAsc;

  constructor(data) {
    this.ids = data?.ids;
    this.title = data?.title;
    this.pdfOption = data?.pdfOption;
    this.bookGroup = data?.bookGroup;
    this.inStock = data?.inStock;
    this.categoryId = data?.categoryId;
    this.genre1Id = data?.genre1Id;
    this.genre2Id = data?.genre2Id;
    this.pageNo = data?.pageNo;
    this.pageSize = data?.pageSize;
    this.sortBy = data?.sortBy;
    this.sortAsc = data?.sortAsc;
  }
}

export default BookSearchDto;
