import React, { useEffect, useState } from "react"; 
import UamStore from "./uam-store.js";
import RoleSearchDto from "./role-search-dto.js";
import RoleItem from "./role-item.js";
import RoleSearch from "./role-search.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new UamStore();

function RoleList() {
  const [roleSearchPage, setRoleSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let searchReq = new RoleSearchDto();
    store.searchRole(searchReq).then((data) => {
      setRoleSearchPage(data);
      setLoaded(true);
    });
  }, []);
  
  return (
    <>
      <RoleSearch store={store} callback={(data) => {setRoleSearchPage(data);}}/>
      <span style={{display: loaded ? "none" : "block"}}>
        <Row>
          <Col>
            <Progress animated color="info" striped value={100}/>
          </Col>
        </Row>
      </span>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">ROLES LIST</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Permissions</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {roleSearchPage?.content?.map(item => (
                    <RoleItem id={item.id} key={item.id} data={item} store={store}/>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RoleList;
