import Dashboard from "page/dashboard/dashboard.js";
import Books from "page/book/books.js";
import BookQuotes from "page/bookquote/bookquotes.js";
import BookSlogans from "page/bookslogan/bookslogans.js";
import LibraryItems from "page/library/libraryitems.js";
import Orders from "page/order/orders.js";
import MediaFiles from "page/file/mediafiles.js";
import Categories from "page/category/categories.js";
import Users from "page/user/users.js";

import BookNew from "page/book/book-new.js";
import BookQuoteNew from "page/bookquote/bookquote-new.js";
import BookSloganNew from "page/bookslogan/bookslogan-new.js";
import LibraryItemNew from "page/library/libraryitem-new.js";
import MediafileNew from "page/file/mediafile-new.js";

import Administrator from "page/administrator/administrator.js";
import Uam from "page/administrator/uam/uam.js";
import Packages from "page/administrator/package/packages.js";
import Configs from "page/administrator/config/configs.js";
import Accounts from "page/administrator/account/accounts.js";
import AuditLogs from "page/administrator/auditlog/auditlogs.js";

var adminRoutes = [
  {
    path: "/dashboard",
    name: "menu.dashboard",
    icon: "nc-icon nc-bank",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/administrator",
    name: "menu.administration",
    icon: "nc-icon nc-settings",
    component: <Administrator />,
    layout: "/admin",
    routes: [
      {
        path: "accounts",
        name: "menu.accounts",
        icon: "nc-icon nc-badge",
        component: <Accounts />
      },
      {
        path: "uam",
        name: "menu.permissions",
        icon: "nc-icon nc-lock-circle-open",
        component: <Uam />
      },
      {
        path: "configurations",
        name: "menu.configuration",
        icon: "nc-icon nc-align-center",
        component: <Configs />
      },
      {
        path: "category",
        name: "menu.category.genre",
        icon: "nc-icon nc-bullet-list-67",
        component: <Categories />
      }
    ]
  },
  {
    path: "/mediafiles",
    name: "menu.media",
    icon: "nc-icon nc-button-play",
    component: <MediaFiles />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.media",
        icon: "nc-icon nc-cloud-upload-94",
        component: <MediafileNew />
      }
    ]
  },
  {
    path: "/users",
    name: "menu.apppq.users",
    icon: "nc-icon nc-single-02",
    component: <Users />,
    layout: "/admin",
  },
  {
    path: "/books",
    name: "menu.books",
    icon: "nc-icon nc-bold",
    component: <Books />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.book",
        icon: "nc-icon nc-cloud-upload-94",
        component: <BookNew />
      }
    ]
  },
  {
    path: "/library",
    name: "menu.library",
    icon: "nc-icon nc-paper",
    component: <LibraryItems />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.bookquote",
        icon: "nc-icon nc-simple-add",
        component: <LibraryItemNew />
      }
    ]
  },
  {
    path: "/orders",
    name: "menu.orders",
    icon: "nc-icon nc-cart-simple",
    component: <Orders />,
    layout: "/admin",
  }
];

export const bookRoutes = [
  {
    path: "/books",
    name: "menu.books",
    icon: "nc-icon nc-bold",
    component: <Books />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.book",
        icon: "nc-icon nc-cloud-upload-94",
        component: <BookNew />
      }
    ]
  },
  {
    path: "/library",
    name: "menu.library",
    icon: "nc-icon nc-chat-33",
    component: <LibraryItems />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.bookquote",
        icon: "nc-icon nc-simple-add",
        component: <LibraryItemNew />
      }
    ]
  },
  {
    path: "/orders",
    name: "menu.orders",
    icon: "nc-icon nc-cart-simple",
    component: <Books />,
    layout: "/admin",
  }
];

export const mediaRoutes = [
  {
    path: "/mediafiles",
    name: "menu.media",
    icon: "nc-icon nc-button-play",
    component: <MediaFiles />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.media",
        icon: "nc-icon nc-cloud-upload-94",
        component: <MediafileNew />
      }
    ]
  }
];

export const uploadRoutes = [
  {
    path: "/mediafiles",
    name: "menu.media",
    icon: "nc-icon nc-button-play",
    component: <MediaFiles />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.media",
        icon: "nc-icon nc-cloud-upload-94",
        component: <MediafileNew />
      }
    ]
  },
  {
    path: "/library",
    name: "menu.library",
    icon: "nc-icon nc-paper",
    component: <LibraryItems />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.bookquote",
        icon: "nc-icon nc-simple-add",
        component: <LibraryItemNew />
      }
    ]
  },
  {
    path: "/books",
    name: "menu.books",
    icon: "nc-icon nc-bold",
    component: <Books />,
    layout: "/admin",
    routes: [
      {
        path: "new",
        name: "menu.upload.book",
        icon: "nc-icon nc-cloud-upload-94",
        component: <BookNew />
      }
    ]
  }
];

export default adminRoutes;