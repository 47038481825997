class AccountSearchDto {
  name;
  email;

  pageNo;
  pageSize;
  sortBy;
  sortAsc;

  constructor(data) {
    this.name = data?.name;
    this.email = data?.email;
    this.pageNo = data?.pageNo;
    this.pageSize = data?.pageSize;
    this.sortBy = data?.sortBy;
    this.sortAsc = data?.sortAsc;
  }
}

export default AccountSearchDto;
