import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import Login from "page/login/login.js";

var ps;

function PublicLayout(props) {
  return <div className="login-page"><Login/></div>;
}

export default PublicLayout;
