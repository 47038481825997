import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Switch from "react-switch";

import CategoryStore from "../category/category-store.js";
import LibraryItemSearchDto from "./libraryitem-search-dto.js";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import LibraryItemStore from "./libraryitem-store.js";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

let store = new CategoryStore();

function LibraryItemSearch(props) {
  const { t } = useTranslation();

  const [id, setId] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('');

  function handleContentChange(event) {
    setContent(event.target.value);
  }

  function handleIdChange(event) {
    setId(event.target.value);
  }

  function handleTypeChange(select) {
    setType(select.value);
  }

  function getTypeList() {
    return [
      {
        value: '1',
        label: 'Trích Dẫn'
      },
      {
        value: '2',
        label: 'Câu Đạo Lý'
      },
      {
        value: '3',
        label: 'Nền Tảng Đạo Đức'
      },
      {
        value: '4',
        label: 'Lời Bài Hát'
      },
      {
        value: 5,
        label: 'Đạo Phật Nhập Môn'
      }
    ];
  }

  function handleSearch(event) {
    props.showProgressingFn();
    let searchReq = new LibraryItemSearchDto({
      "ids": id,
      "content": content,
      "type": type
    });
    props.store.search(searchReq).then((data) => props.callback(data, searchReq));
  }

  return (
    <>
      <Row>
        <Col className="pl-3" md="1">
          <FormGroup>
            <label>ID</label>
            <Input placeholder="Id" type="text" onChange={handleIdChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>Nội dung</label>
            <Input placeholder={'Nội dung'} type="text" onChange={handleContentChange}/>
          </FormGroup>
        </Col>
        <Col className="px-1" md="2">
          <FormGroup>
            <label>Loại</label>
            <Select options={getTypeList()} onChange={handleTypeChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary" type="submit">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default LibraryItemSearch;
