import axios from "axios";

import MediaFileDto from "./mediafile-dto.js";
import MediaFileSearchDto from "./mediafile-search-dto.js";
import HttpUtils from "utils/http-utils.js";

let shortLinkable;
let attachableMedias;
let attachableCpMedias;
class MediaFileStore {
  search = async function(searchParams: MediaFileSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/mediaFiles/search", searchParams,
          { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  attachableMedias = async function() {
    if(!attachableMedias) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/mediaFiles/attachableMedias",
          { headers: new HttpUtils().getHeaders()});
      attachableMedias = response.data;
    }
    return attachableMedias;
  }

  attachableCpMedias = async function() {
    if(!attachableCpMedias) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/mediaFiles/attachableCpMedias",
          { headers: new HttpUtils().getHeaders()});
      attachableCpMedias = response.data;
    }
    return attachableCpMedias;
  }

  shortLinkableMedias = async function() {
    if(!shortLinkable) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/mediaFiles/shortLinkableMedias",
          { headers: new HttpUtils().getHeaders()});
      shortLinkable = response.data;
    }
    return shortLinkable;
  }

  get = async function(mediaFileId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId,
          { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  update = async function(dto) {
    console.log(dto);
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles", dto,
          { headers: new HttpUtils().getHeaders()});
  }

  notifyApp = async function(mediaFileId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/notifyApp", {},
          { headers: new HttpUtils().getHeaders()}
        ).then((res) => {
          console.log("Notify App -" + mediaFileId);
        });
  }

  disableNotifyApp = async function(mediaFileId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/disableNotifyApp", {},
          { headers: new HttpUtils().getHeaders()}
        ).then((res) => {
          console.log("Disable Notify App -" + mediaFileId);
        });
  }

  setupNotifyApp = async function(mediaFileId: int, time) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/setupNotifyApp", {
          'notifyTime': time
        },
          { headers: new HttpUtils().getHeaders()}
        ).then((res) => {
          console.log("setupNotifyApp Notify App -" + mediaFileId);
        });
  }

  createHls = async function(mediaFileId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/createHls",
          {"id": mediaFileId},
          { headers: new HttpUtils().getHeaders()}
        ).then((res) => {
          console.log("FixHLS -" + mediaFileId);
        });
  }

  view = async function(mediaFileId: int) {
    return await axios
        .get(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/view",
          { headers: new HttpUtils().getHeaders()});
  }

  viewHls = async function(mediaFileId: int) {
    return await axios
        .get(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/viewHls",
          { headers: new HttpUtils().getHeaders()});
  }

  switchMode = async function(mediaFileId: int) {
    return await axios
        .get(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/switchMode",
          { headers: new HttpUtils().getHeaders()});
  }

  remove = async function(mediaFileId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/remove", {},
          { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("remove -" + mediaFileId);
        });
  }

  disable = async function(mediaFileId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/disable", {},
          { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("disable -" + mediaFileId);
        });
  }

  enable = async function(mediaFileId: int, publishTime) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/mediaFiles/" + mediaFileId + "/enable", {
          'publishTime': publishTime
        },{ headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("enable -" + mediaFileId);
        });
  }
}

export default MediaFileStore;