import axios from "axios";
import LoginDto from "./login-dto.js";
import HttpUtils from "utils/http-utils.js";

class LoginStore {
  login = async function(loginDto: LoginDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/auth", loginDto,
      {
         headers: {
           "accept": 'application/json',
           "Access-Control-Allow-Origin": "*"
         }
      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
        return {'access_token': ''};
      });
    return response.data;
  }

  logout = async function(loginDto: LoginDto) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/auth/logout",
      { headers: new HttpUtils().getHeaders() });
    return response.data;
  }
}

export default LoginStore;