class NotiUtils {
  notify = function(notiRef, type, msg, autoDismiss) {
      var options = {};
      options = {
        place: "tr",
        message: msg,
        type: type,
        icon: "nc-icon nc-bell-55",
        autoDismiss: !autoDismiss ? 7 : autoDismiss,
      };
      notiRef.current.notificationAlert(options);
  };
}

export default NotiUtils;
