import React, { useState } from "react";

import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

function UserPaging(props) {
  const [page, setPage] = useState(0);

  let hasMore = props.searchPage?.totalPages > 15;
  let numberOfPages = props.searchPage?.totalPages > 15 ? 15 : props.searchPage?.totalPages;
  return (
    <>
      <Row>
        <Col md="12">
          <Pagination>
            <PaginationItem><PaginationLink first/></PaginationItem>
            {!props.searchPage?.first &&
              <PaginationItem><PaginationLink previous onClick={() => props.callback(page - 1)}/></PaginationItem>
            }
            {[...Array(numberOfPages)].map((x, i) => {
               if(page === i) {
                 return (
                    <PaginationItem active key={i} onClick={() => {setPage(i); props.callback(i + 1);}}>
                      <PaginationLink key={i}>
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                 );
               } else {
                 return (
                     <PaginationItem key={i} onClick={() => {setPage(i); props.callback(i + 1);}}>
                       <PaginationLink key={i}>
                         {i + 1}
                       </PaginationLink>
                     </PaginationItem>
                  );
               }
            })}
            {hasMore && <PaginationLink key="hasMore">...</PaginationLink>}
            {!props.searchPage?.last &&
              <PaginationItem><PaginationLink next onClick={() => props.callback(page + 1)}/></PaginationItem>
            }
            <PaginationItem><PaginationLink last onClick={() => props.callback(numberOfPages - 1)}/></PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default UserPaging;
