import React, { useState, useEffect } from "react";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

function BookSuggestion(props) {
  const [multiSelections, setMultiSelections] = useState([]);

  function handleSelection(data) {
    setMultiSelections(data);
    props.callback(data);
  }

  useEffect(() => {
    if(props.bookIds) {
      setMultiSelections(props.attachableBooks.filter((x) => props.bookIds.includes(x.id)));
    }
  }, []);

  return <Typeahead
         id="basic-typeahead-multiple"
         labelKey="title"
         multiple
         onChange={handleSelection}
         options={props.attachableBooks}
         placeholder="Chọn sách đã trích..."
         selected={multiSelections}
       />;
}

export default BookSuggestion;
