import React, { useEffect, useState } from "react";
import { useSearchParams, useMatch, Outlet, useNavigate } from "react-router-dom";
import BookSloganStore from "./bookslogan-store.js";
import AlbumStore from "../album/album-store.js";
import BookSloganSearchDto from "./bookslogan-search-dto.js";
import BookSloganView from "./bookslogan-view.js";
import BookSloganSearch from "./bookslogans-search.js";
import BookSloganPaging from "./bookslogans-paging.js";
import NotificationAlert from "react-notification-alert";
import { useTranslation } from 'react-i18next';
import BookStore from "../book/book-store.js";
import CategoryStore from "../category/category-store.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new BookSloganStore();

function BookSlogans() {
  const { t } = useTranslation();
  const notificationAlert = React.useRef();
  const [searchReq, setSearchReq] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPage, setSearchPage] = useState({});
  const [sloganBgImages, setSloganBgImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [attachableBooks, setAttachableBooks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    new BookStore().attachableBooks().then((data) => {
      setAttachableBooks(data);
      search(page);
    });
    new AlbumStore().sloganBgImages().then((data) => {
     setSloganBgImages(data);
    });
  }, [navigate]);

  function search(page) {
    let urlIds = searchParams.get('ids');
    let request;
    if(searchReq) {
      request = new BookSloganSearchDto({
        "ids": urlIds,
        "pageNo": page,
        "title": searchReq.title,
        "bookId": searchReq.bookId
      });
    } else {
      request = new BookSloganSearchDto({
        "ids": urlIds,
        "pageNo": page
      });
    }
    store.search(request).then((data) => {
      setSearchPage(data);
      setLoaded(true);
    });
  }
  
  return (
    <>
      <div className="content">
        <Outlet />
        <NotificationAlert ref={notificationAlert} />
        <span className={Boolean(useMatch("/admin/bookslogans/new")) ? "hide" : undefined}>
          <BookSloganSearch store={store}
            showProgressingFn={()=> setLoaded(false)}
            callback={(data, searchReq) => {setLoaded(true); setSearchPage(data); setSearchReq(searchReq);}}/>
          <span className={loaded ? "hide" : undefined}>
            <Row>
              <Col>
                <Progress animated color="danger" style={{height: "5px"}} striped value={100}/>
              </Col>
            </Row>
          </span>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle  tag="h4">{t('bookslogans.bookslogan_list')}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>ID</th>
                        <th>Ảnh</th>
                        <th>Nội dung</th>
                        <th>Sách</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchPage?.content?.map(item => (
                        <BookSloganView
                          id={item.id}
                          sloganBgImages={sloganBgImages}
                          attachableBooks={attachableBooks}
                          notiRef={notificationAlert}
                          key={item.id} data={item}
                          showProgressingFn={()=> setLoaded(false)}
                          refresh={() => search(page)}/>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <BookSloganPaging searchPage={searchPage} callback={(newPage) => {search(newPage);setPage(newPage);}}/>
        </span>
      </div>
    </>
  );
}

export default BookSlogans;
