import React, { useState } from "react";
import PureModal from 'react-pure-modal';
import AccountDto from "./account-dto.js";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Row
} from "reactstrap";

function AccountItem(props) {
  const { t } = useTranslation();

  return (
    <>
      <tr>
        <td width="10%">{props.data.id}</td>
        <td>{props.data.username}</td>
        <td>{props.data.email}</td>
        <td>{props.data.app}</td>
        <td>{props.data.roles}</td>
        <td>{t('common.view_details')}</td>
      </tr>
    </>
  );
}

export default AccountItem;