import React, { useState } from "react";

import 'react-pure-modal/dist/react-pure-modal.min.css';

import EditButton from "component/edit-button.js";

import UserStore from "./user-store.js";
import UserDto from "./user-dto.js";
import UserEdit from "./user-edit.js";
import { useTranslation } from 'react-i18next';
import SendMsgButton from "./component/send-msg-button.js";

import {
  Row,
} from "reactstrap";

function UserView(props) {
  const [writable, setWritable] = useState(false);
  const [data] = useState(props.data);

  function saveDirtyValues(dto: UserDto) {}

  return (
    <>
      <UserRead hide={writable} data={data}
        onSendMsg={(id, message) => props.onSendMsg(id, message)}
        onEdit={() => setWritable(true)}/>
      <tr className={!writable ? "hide" : undefined}>
        <td colSpan="5">
          <UserEdit show={writable} data={data}
            refresh={props.refresh}
            onUpdate={(dto) => {saveDirtyValues(dto)}}
            onReadonly={() => setWritable(false)}/>
        </td>
      </tr>
    </>
  );
}

function UserRead(props) {
  const { t } = useTranslation();

  return (
    <tr className={props.hide ? "hide" : undefined}>
      <td width="45%">
        <b>USER ID: {props.data.id}</b>
        <br/><b>{props.data.name.toUpperCase()}</b>
        <br/>{props.data.email && ">" && props.data.email }
        <br/> {props.data.phone && ">" && props.data.phone }
        <br/>{props.data.createdAt}
        <hr/>
        {props.data.deviceName}<br/>
        {props.data.deviceNo}
      </td>
      <td width="15%">{props.data.source.toUpperCase()}</td>
      <td>
        <Row>
          <EditButton id={props.data.id}
            className={props.data.queueStatusStr ? "hide" : undefined}
            callback={(id) => props.onEdit()}/>
          <SendMsgButton id={props.data.id} app={props.data.source} name={props.data.name.toUpperCase()}
            callback={(id, message) => props.onSendMsg(id, message)}/>
        </Row>
      </td>
    </tr>
  );
}

export default UserView;
