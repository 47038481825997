import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import { useTranslation } from 'react-i18next';
import { Line, Pie } from "react-chartjs-2";
import {
  statisticsChartOptions,
  perMonthStatisticsChartOptions,
} from "variables/charts.js";

import DashboardStore from "./dashboard-store.js";

let store = new DashboardStore();

function Dashboard() {
  const { t } = useTranslation();
  const [summary, setSummary] = useState([]);
  const [summaryCp, setSummaryCp] = useState([]);
  const [pkgData, setPkgData] = useState([]);
  const [seriesData, setSeriesData] = useState({});

  useEffect(() => {
    store.summary().then((data) => {
       setSummary(data);
    });
    store.summaryCp().then((data) => {
       setSummaryCp(data);
    });
    store.monthlyPkg().then((data) => {
       setPkgData(data);
    });
    store.monthlySeries().then((data) => {
       setSeriesData(data);
    });
  }, []);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-globe text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('dashboard.totaluser')}</p>
                      <CardTitle tag="p">{summary.totalUsers} P</CardTitle>
                      <CardTitle tag="p">{summaryCp.totalUsers} C</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> {t('dashboard.updatenow')}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('dashboard.totalview')}</p>
                      <CardTitle tag="p">{summary.totalViewSuccess} P</CardTitle>
                      <CardTitle tag="p">{summaryCp.totalViewSuccess} C</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-calendar" /> {t('dashboard.currentmonth')}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-bold text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('dashboard.books')}</p>
                      <CardTitle tag="p">{summary.totalBook} P</CardTitle>
                      <CardTitle tag="p">{summaryCp.totalBook} C</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> {t('dashboard.untilnow')}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-button-play text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">{t('dashboard.media_files')}</p>
                      <CardTitle tag="p">{summary.totalMediaFile} P</CardTitle>
                      <CardTitle tag="p">{summaryCp.totalMediaFile} C</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> {t('dashboard.currentmonth')}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <CardBody style={{ height: "70px" }}>
                <center><a target="_blank" href="https://app.axiom.co/ctpq-di3u/dashboards/iy2DkPkgzjIG6Bv9p3">MỞ TRANG GIÁM SÁT</a></center>
                <small><center>(đăng nhập bằng tài khoản gmail: app.phapquang@gmail.com)</center></small>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">{t('dashboard.view_percent_bymonth')}</CardTitle>
                <p className="card-category">{t('dashboard.thisyear')}</p>
              </CardHeader>
              <CardBody style={{ height: "320px" }}>
                <Line
                    data={() => store.viewPerMonthStatisticsChartData(t, seriesData)}
                    options={perMonthStatisticsChartOptions}
                    width={400} height={100}
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">{t('dashboard.cost_percent_bymonth')}</CardTitle>
                <p className="card-category">{t('dashboard.thisyear')}</p>
              </CardHeader>
              <CardBody style={{ height: "320px" }}>
                <Line
                    data={() => store.costPerMonthStatisticsChartData(t, seriesData)}
                    options={perMonthStatisticsChartOptions}
                    width={400} height={100}
                  />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Dashboard;
