import React from "react";
import 'react-pure-modal/dist/react-pure-modal.min.css';

function PermissionItem(props) {
  return (
    <>
      <tr>
        <td>{props.data.id}</td>
        <td>{props.data.code}</td>
        <td>{props.data.name}</td>
      </tr>
    </>
  );
}

export default PermissionItem;