import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Switch from "react-switch";

import CategoryStore from "../category/category-store.js";
import BookQuoteSearchDto from "./bookquote-search-dto.js";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import BookQuoteStore from "./bookquote-store.js";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

let store = new CategoryStore();

function BookQuoteSearch(props) {
  const { t } = useTranslation();

  const [quote, setQuote] = useState('');
  const [id, setId] = useState('');
  const [type, setType] = useState('');

  function handleTypeChange(event) {
    setType(event.target.value);
  }

  function handleTypeChange(select) {
    setType(select.value);
  }

  function handleQuoteChange(event) {
    setQuote(event.target.value);
  }

  function handleIdChange(event) {
    setId(event.target.value);
  }

  function handleSearch(event) {
    props.showProgressingFn();
    let searchReq = new BookQuoteSearchDto({
      "ids": id,
      "quote": quote,
      "type": 2
    });
    props.store.search(searchReq).then((data) => props.callback(data, searchReq));
  }

  return (
    <>
      <Row>
        <Col className="pl-3" md="1">
          <FormGroup>
            <label>ID</label>
            <Input placeholder="Id" type="text" onChange={handleIdChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>{t('bookquotes.quote')}</label>
            <Input placeholder={t('bookquotes.quote')} type="text" onChange={handleQuoteChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary" type="submit">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default BookQuoteSearch;
