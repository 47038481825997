import React from 'react';
import {
  Player, LoadingSpinner, BigPlayButton, ForwardControl, ReplayControl, ControlBar }
from 'video-react';
import HLSSource from './hls-source';

function MediaHlsViewer(props) {
  return (
    <>
      <Player>
        <HLSSource
              isVideoChild
              src={props.viewLink}
            />
        <LoadingSpinner />
        <BigPlayButton position="center" />
        <ControlBar>
          <ReplayControl seconds={10} order={2.2} />
          <ForwardControl seconds={10} order={3.2} />
        </ControlBar>
      </Player>
    </>
  );
}

export default MediaHlsViewer;
