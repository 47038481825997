import React, { useState } from "react";

import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

function BookPaging(props) {
  const [chunk, setChunk] = useState(0);
  const [page, setPage] = useState(props.page || 0);

  let hasMore = props.searchPage?.totalPages > 10;
  let numberOfPages = props.searchPage?.totalPages > 10 ? 10 : props.searchPage?.totalPages;
  return (
    <>
      <Row>
        <Col md="12">
          <Pagination>
            <PaginationItem><PaginationLink first onClick={() => {setPage(0); setChunk(0); props.callback(0)}}/></PaginationItem>
            {chunk > 0 && <PaginationLink onClick={() => {setChunk(chunk - 1);}}>...</PaginationLink>}
            {[...Array(numberOfPages)].map((x, i) => {
               if(page === 10 * chunk + i) {
                 return (
                    <PaginationItem active key={i} onClick={() => {setPage(10 * chunk + i); props.callback(10 * chunk + i + 1);}}>
                      <PaginationLink key={i}>
                        {10 * chunk + i + 1}
                      </PaginationLink>
                    </PaginationItem>
                 );
               } else {
                 return (
                     <PaginationItem key={i} onClick={() => {setPage(10 * chunk + i); props.callback(10 * chunk + i + 1);}}>
                       <PaginationLink key={i}>
                         {10 * chunk + i + 1}
                       </PaginationLink>
                     </PaginationItem>
                  );
               }
            })}
            {hasMore && <PaginationLink key="hasMore" onClick={() => {setChunk(chunk + 1);}}>...</PaginationLink>}
            {!props.searchPage?.last &&
              <PaginationItem><PaginationLink next onClick={() => {setPage(page + 1); props.callback(page + 1)}}/></PaginationItem>
            }
            <PaginationItem><PaginationLink last onClick={() => props.callback(numberOfPages - 1)}/></PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default BookPaging;
