import React, { useState } from "react";

import MediaFileStore from "../mediafile-store.js";
import { useTranslation } from 'react-i18next';

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function NotifyButton(props) {
  const { t } = useTranslation();
  const [notifyModal, setNotifyModal] = useState(false);

  function handleNotifyApp(event) {
    setNotifyModal(false);
    new MediaFileStore().notifyApp(props.id);
  }

  return <span style={{cursor: "pointer"}}>
    <Modal isOpen={notifyModal} fade={false} toggle={()=>setNotifyModal(false)}>
      <ModalHeader toggle={()=>setNotifyModal(false)}>Xác nhận lựa chọn</ModalHeader>
      <ModalBody>
        Bấm <b>'Xác nhận'</b> để đồng ý gửi thông báo cho người dùng biết bài <b>{props.title}</b>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleNotifyApp}>
          Xác nhận
        </Button>{' '}
        <Button color="secondary" onClick={()=>setNotifyModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setNotifyModal(true)} >
      <Button className="btn-round btn-icon" color="success" outline size="sm">
        <i className="fa fa-bell" />
      </Button>{props.btnTitle}
    </span>
  </span>;
}

export default NotifyButton;
