import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import OriginalMediaSuggestion from "./component/original-media-suggestion.js";

import MediaFileStore from "./mediafile-store.js";
import CategoryStore from "../category/category-store.js";
import HttpUtils from "utils/http-utils.js";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Spinner,
  Row,
  Col,
  Label,
  Progress,
  Modal, ModalHeader, ModalBody, ModalFooter,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function MediafileNew() {
  const { t } = useTranslation();
  const [notifyModal, setNotifyModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [genres1, setGenres1] = useState([]);
  const [genres2, setGenres2] = useState([]);
  const [categoryCode, setCategoryCode] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [genre1Code, setGenre1Code] = useState('');
  const [genre2Code, setGenre2Code] = useState('');
  const [genre1Name, setGenre1Name] = useState('');
  const [genre2Name, setGenre2Name] = useState('');
  const [title, setTitle] = useState('');
  const [pqChecked, setPqChecked] = useState('');
  const [cpChecked, setCpChecked] = useState('');
  const [bgColorFrom, setBgColorFrom] = useState('');
  const [bgColorTo, setBgColorTo] = useState('');
  const [description, setDescription] = useState('');
  const [previewImg, setPreviewImg] = useState('');
  const [previewImgCp, setPreviewImgCp] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [imageFileCp, setImageFileCp] = useState('');
  const [mediaFile, setMediaFile] = useState('');
  const [imageProgress, setImageProgress] = useState('');
  const [imageCpProgress, setImageCpProgress] = useState('');
  const [mediaProgress, setMediaProgress] = useState('');
  const [statusStr, setStatusStr] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const [recordAt, setRecordAt] = useState(null);
  const [recordPlace, setRecordPlace] = useState('');
  const [publishNo, setPublishNo] = useState('');

  const [publishTime, setPublishTime] = useState(new Date());
  const [notifyTime, setNotifyTime] = useState(new Date());
  const [requestNotify, setRequestNotify] = useState(false);
  const [selectedRequestNotifyOption, setSelectedRequestNotifyOption] = useState({
    value: false,
    label: 'KHÔNG THÔNG BÁO'
  });
  const [requestShowHide, setRequestShowHide] = useState(true);
  const [selectedRequestShowHideOption, setSelectedRequestShowHideOption] = useState({
    value: true,
    label: 'HIỆN BÀI NGAY hoặc TRONG TƯƠNG LAI'
  });

  const [publishTimeCp, setPublishTimeCp] = useState(new Date());
  const [notifyTimeCp, setNotifyTimeCp] = useState(new Date());
  const [requestNotifyCp, setRequestNotifyCp] = useState(false);
  const [selectedRequestNotifyCpOption, setSelectedRequestNotifyCpOption] = useState({
    value: false,
    label: 'KHÔNG THÔNG BÁO'
  });
  const [requestShowHideCp, setRequestShowHideCp] = useState(true);
  const [selectedRequestShowHideCpOption, setSelectedRequestShowHideCpOption] = useState({
    value: true,
    label: 'HIỆN BÀI NGAY hoặc TRONG TƯƠNG LAI'
  });
  
  const [originalMediaIds, setOriginalMediaIds] = useState('');
  const [shortLinkableMedias, setShortLinkableMedias] = useState([]);

  let genImageFile = '';
  let genImageCpFile = '';
  let genMediaFile = '';

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  useEffect(() => {
    new CategoryStore().list().then((data) => {
      setCategories(data);
    });
    new MediaFileStore().shortLinkableMedias().then((data) => {
      setShortLinkableMedias(data);
    });
  }, []);

  function getCategoryList() {
    return categories.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function getGenre1List() {
    return genres1.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function getGenre2List() {
    return genres2.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function onResetClick(e) {
    setCategoryCode('');
    setGenre1Code('');
    setImageFile('');
    setImageFileCp('');
    setMediaFile('');
    setPreviewImg('');
    setTitle('');
    setStatusStr('');
    setSuccess(false);
    setImageProgress(0);
    setImageCpProgress(0);
    setMediaProgress(0);
    setCategoryName('');
    setGenre1Name('');
    setGenre2Name('');
    setGenre2Code('');
    setPqChecked('');
    setCpChecked('');
    setRequestNotify(true);
    setRequestShowHide(true);
    setOriginalMediaIds('');
  }

  function onCreateClick(e) {
    if(!waiting) {
      return;
    }

    if(pqChecked) {
      if(!publishTime && requestShowHide) {
        setError(true);
        setStatusStr('Vui lòng nhập thời gian hiện bài trên ứng dụng');
        return;
      }
    }

    if(cpChecked) {
      if(!publishTimeCp && requestShowHideCp) {
        setError(true);
        setStatusStr('Vui lòng nhập thời gian hiện bài trên ứng dụng');
        return;
      }
    }

    if(!categoryCode) {
      setError(true);
      setStatusStr(t('error.category.required'));
      return;
    }
    if(!genre1Code) {
      setError(true);
      setStatusStr(t('error.genres1.required'));
      return;
    }
    if(pqChecked && !imageFile) {
      setError(true);
      setStatusStr(t('error.imagefile.required'));
      return;
    }
    if(cpChecked && !imageFileCp) {
      setError(true);
      setStatusStr(t('error.imagefile.required'));
      return;
    }
    if(!mediaFile) {
      setError(true);
      setStatusStr(t('error.mediafile.required'));
      return;
    }
    if(requestNotify) {
      setNotifyModal(true);
      return;
    }

    start(e);
  }

  function start(e) {
    setNotifyModal(false);

    setImageProgress(0);
    setMediaProgress(0);
    if(cpChecked) {
      onImageCpFileUpload(e);
    }
    if(pqChecked) {
      onImageFileUpload(e);
    }
  }

  function onImageFileUpload(e) {
    setStatusStr(t('common.pls_wait'));
    e.preventDefault();
    setWaiting(false);
    const formData = new FormData();
    formData.append(
        "imageFile",
        imageFile,
        imageFile.name
    );
    let apiPath = categoryCode === 'SHORTVIDEO' ?  "/uploadShortImageFile" : "/uploadDefaultImageFile";

    axios.post(process.env.REACT_APP_API_URL + apiPath, formData, {
        onUploadProgress: data => setImageProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    ).then((res) => {
      if(res.data) {
        genImageFile = res.data;
        onMediafileNew(e);
      } else {
        setWaiting(true);
        setStatusStr("Cannot upload image");
      }
    });
  }

  function onImageCpFileUpload(e) {
    setStatusStr(t('common.pls_wait'));
    e.preventDefault();
    setWaiting(false);
    const formData = new FormData();
    formData.append(
        "imageFile",
        imageFileCp,
        imageFileCp.name
    );
    let apiPath = "/uploadCpImageFile";

    axios.post(process.env.REACT_APP_API_URL + apiPath, formData, {
        onUploadProgress: data => setImageCpProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    ).then((res) => {
      if(res.data) {
        genImageCpFile = res.data;
        onMediafileNew(e);
      } else {
        setWaiting(true);
        setStatusStr("Cannot upload chanh phap image");
      }
    });
  }

  function onMediafileNew(e) {
    e.preventDefault()
    const formData = new FormData();
    formData.append(
        "mediaFile",
        mediaFile,
        mediaFile.name
    );
    axios.post(process.env.REACT_APP_API_URL + "/uploadMediaFile", formData, {
        onUploadProgress: data => setMediaProgress(Math.round((100 * data.loaded) / data.total)),
        headers: new HttpUtils().getHeaders()
      }
    ).then((res) => {
      if(res.data) {
        genMediaFile = res.data;
        createFile();
      } else {
        setWaiting(true);
        setStatusStr("Cannot upload media file");
      }
    });
  }

  function createFile() {
    axios.post(process.env.REACT_APP_API_URL + "/finalizeFile", {
      "title": title.trim(),
      'bgColorFrom': bgColorFrom,
      'bgColorTo': bgColorTo,
      "description": description,
      "categoryCode": categoryCode,
      "genre1Code": genre1Code,
      "genre2Code": genre2Code,
      "mediaFile": genMediaFile,
      "imageFile": genImageFile,
      "imageCpFile": genImageCpFile,
      "originalMediaIds": originalMediaIds,
      "publishNo": publishNo,
      "recordAt": recordAt,
      "recordPlace": recordPlace,
      "requestNotify": requestNotify,
      "requestShowHide": requestShowHide,
      "notifyTime": notifyTime,
      "publishTime": publishTime,

      "cpChecked": cpChecked,
      "pqChecked": pqChecked,

      "requestNotifyCp": requestNotifyCp,
      "requestShowHideCp": requestShowHideCp,
      "notifyTimeCp": notifyTimeCp,
      "publishTimeCp": publishTimeCp
    },
    {headers: new HttpUtils().getHeaders()}).then((res) => {
      if(res.data) {
        setImageFile('');
        setMediaFile('');
        setSuccess(true);
        setStatusStr("Create new media - " + mediaFile.name.toUpperCase() + " - successfully.");
      } else {
        setStatusStr("Cannot create new media in database after uploading the files");
      }
      setWaiting(true);
    });
  }

  function onImageSelected(event) {
    setImageFile(event.target.files[0]);
    setPreviewImg(URL.createObjectURL(event.target.files[0]));

    let fileName = event.target.files[0].name;
    setTitle(fileName.replace('.jpg', ''));
  }

  function onImageCpSelected(event) {
    setImageFileCp(event.target.files[0]);
    setPreviewImgCp(URL.createObjectURL(event.target.files[0]));
  }

  function onMediaSelected(event) {
    setMediaFile(event.target.files[0]);
  }

  function handleCategoryChange(category) {
    let selected = category.value;
    let selectedCat = categories.filter(item => item.code === selected)[0];
    setCategoryCode(selected);
    setCategoryName(selectedCat.name);
    setGenres1(selectedCat.genres.filter(item => item.id !== 1 && item.id !== 2));

    setGenre1Code('');
    setGenre1Name('');
    setGenre2Code('');
    setGenre2Name('');
    setGenres2([]);
  }

  function handleGenre1Change(genre1) {
    let selected = genre1.value;
    setGenre1Code(selected);
    setGenre1Name(genres1.filter(item => item.code === selected)[0].name);
    setGenre2Code('');
    setGenre2Name('');

    let selectedGenre1 = genres1.filter(item => item.code === selected)[0];
    if(selectedGenre1.genres) {
      setGenres2(selectedGenre1.genres);
    } else {
      setGenres2([]);
    }
  }

  function handleGenre2Change(genre2) {
    let selected = genre2.value;
    setGenre2Code(selected);
    setGenre2Name(genres2.filter(item => item.code === selected)[0].name);
  }

  function handleTitle(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function handleCpOn(event) {
    setCpChecked(event.target.checked);
  }

  function handlePqOn(event) {
    setPqChecked(event.target.checked);
  }

  function handleRequestNotify(event) {
    let selected = event.value;
    setRequestNotify(selected);
    setSelectedRequestNotifyOption(event);
  }

  function handleRequestShowHide(event) {
    let selected = event.value;
    setRequestShowHide(selected);
    setSelectedRequestShowHideOption(event);
  }

  function handleRequestNotifyCp(event) {
    let selected = event.value;
    setRequestNotifyCp(selected);
    setSelectedRequestNotifyCpOption(event);
  }

  function handleRequestShowHideCp(event) {
    let selected = event.value;
    setRequestShowHideCp(selected);
    setSelectedRequestShowHideCpOption(event);
  }

  function handleBgColorFrom(event) {
    let selected = event.target.value;
    setBgColorFrom(selected);
  }

  function handleBgColorTo(event) {
    let selected = event.target.value;
    setBgColorTo(selected);
  }

  function handlePublishNo(event) {
    let selected = event.target.value;
    setPublishNo(selected);
  }

  function handleRecordAt(event) {
    let selected = event.target.value;
    setRecordAt(selected);
  }

  function handleRecordPlace(event) {
    let selected = event.target.value;
    setRecordPlace(selected);
  }

  function handleDescription(event) {
    let selected = event.target.value;
    setDescription(selected);
  }

  function getRequestNotifyOptions() {
    return [
      { value: true, label: 'CÓ THÔNG BÁO'},
      { value: false, label: 'KHÔNG THÔNG BÁO'}
    ];
  }

  function getRequestShowHideOptions() {
    return [
      { value: true, label: 'HIỆN BÀI NGAY hoặc TRONG TƯƠNG LAI'},
      { value: false, label: 'ẨN BÀI'}
    ];
  }

  function CreateButton(props) {
    return <Button className="btn-round" color="primary" type="button" onClick={onCreateClick}>
      {props.waiting && t('files.create')} {!props.waiting && <Spinner size="sm">t('common.pls_wait')</Spinner>}
    </Button>;
  }

  function formatTime(time) {
    return new Date(time).toLocaleDateString() + " " + new Date(time).toLocaleTimeString();
  }

  return <>
    <Modal isOpen={notifyModal} fade={false} toggle={() => setNotifyModal(false)}>
      <ModalHeader toggle={()=>setNotifyModal(false)}>XEM LẠI VÀ XÁC NHẬN</ModalHeader>
      <ModalBody>
        Bài <b>{title}</b> sẽ được THÔNG BÁO CHO NGƯỜI DÙNG BIẾT lúc <b>{notifyTime ? formatTime(notifyTime) : formatTime(publishTime) }</b> .
        <br/> <br/> Bấm vào <b>'Đồng ý'</b> để tiếp tục
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={start}>
          Đồng ý
        </Button>{' '}
        <Button color="secondary" onClick={() => setNotifyModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>
    <fieldset disabled={!waiting}>
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <div className="image2">
                  {previewImg && <><img alt="..." src={previewImg}/> <center><b>PHÁP QUANG</b></center></>}
                </div>
                <div className="image2">
                  {previewImgCp && <><img alt="..." src={previewImgCp} /> <br/><center><b>CHÁNH PHÁP</b></center></>}
                </div>
              </CardBody>
              <CardFooter>
                <div className="author2">
                  {title}
                </div>
                {categoryName && <p className="text-center">
                  <b>{categoryName} > </b>
                  <b> {genre1Name} </b> <b> > {genre2Name}</b>
                </p>}
              </CardFooter>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">{t('files.upload_new_media')}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row><Col md="12"><div className={"blockquote blockquote-warning"}> Trường thông tin có dấu <span style={{color: "red"}}> * </span> bắt buộc phải nhập</div></Col></Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t('files.browse_mp3_mp4')} <span style={{color: "red"}}> * </span></label>
                        <Input
                          placeholder={t('files.browse_mp3_mp4')}
                          type="file" accept=".mp3,audio/*,.mp4"
                          onChange={onMediaSelected}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t('files.upload_media_progress')}</label>
                        <Progress
                          value={mediaProgress}
                        > {mediaProgress < 100 ? "uploading" : "done"} </Progress>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" onChange={handlePqOn} name="pqOn" />
                          <span className="form-check-sign">
                            <span className="check"></span>
                          </span>
                          <b>ĐĂNG LÊN <span style={{color: "red"}}>PHÁP QUANG</span></b>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  {pqChecked && <>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t('files.browse_image')} <span style={{color: "red"}}>PHÁP QUANG *</span> </label>
                        <Input
                          placeholder="Please select image to upload"
                          type="file" accept="image/jpg"
                          onChange={onImageSelected}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t('files.upload_image_progress')}</label>
                        <Progress
                          value={imageProgress}
                        > {imageProgress < 100 ? "uploading" : "done"} </Progress>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label><b>THÔNG BÁO NGƯỜI DÙNG BIẾT</b></label>
                        <Select options={getRequestNotifyOptions()} value={selectedRequestNotifyOption}
                          onChange={handleRequestNotify}/>
                      </FormGroup>
                    </Col>
                    {requestNotify && <Col className="pr-1" md="6">
                      <FormGroup>
                        <label><b>THỜI GIAN <span style={{color: "red"}}>THÔNG BÁO</span> CHO NGƯỜI DÙNG</b></label><br/>
                        <DatePicker showTimeSelect selected={notifyTime}
                          onChange={(date) => setNotifyTime(date)}
                          dateFormat="dd-MM-yyyy hh:mm aa"
                          className="form-control"/>
                      </FormGroup>
                    </Col>}
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label><b>CHỌN CHẾ ĐỘ HIỆN BÀI</b></label><br/>
                        <Select options={getRequestShowHideOptions()} value={selectedRequestShowHideOption} onChange={handleRequestShowHide}/>
                      </FormGroup>
                    </Col>
                    {requestShowHide && <Col className="pr-1" md="6">
                      <FormGroup>
                        <label><b>THỜI GIAN HIỆN LÊN <span style={{color: "red"}}>PHÁP QUANG</span> </b></label><br/>
                        <DatePicker showTimeSelect selected={publishTime}
                          onChange={(date) => setPublishTime(date)}
                          dateFormat="dd-MM-yyyy hh:mm aa"
                          className="form-control"/>
                      </FormGroup>
                    </Col>}
                  </Row></>}
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" onChange={handleCpOn} name="cpOn" />
                          <span className="form-check-sign">
                            <span className="check"></span>
                          </span>
                           <b>ĐĂNG LÊN <span style={{color: "red"}}>CHÁNH PHÁP</span></b>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  {cpChecked && <>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>{t('files.browse_image')} <span style={{color: "red"}}>CHÁNH PHÁP</span> <span style={{color: "red"}}> * </span></label>
                          <Input
                            placeholder="Please select image to upload"
                            type="file" accept="image/jpg"
                            onChange={onImageCpSelected}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label>{t('files.upload_image_progress')}</label>
                          <Progress
                            value={imageCpProgress}
                          > {imageCpProgress < 100 ? "uploading" : "done"} </Progress>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label><b>THÔNG BÁO NGƯỜI DÙNG BIẾT</b></label>
                          <Select options={getRequestNotifyOptions()} value={selectedRequestNotifyCpOption}
                            onChange={handleRequestNotifyCp}/>
                        </FormGroup>
                      </Col>
                      {requestNotify && <Col className="pr-1" md="6">
                        <FormGroup>
                          <label><b>THỜI GIAN <span style={{color: "red"}}>THÔNG BÁO</span> CHO NGƯỜI DÙNG</b></label><br/>
                          <DatePicker showTimeSelect selected={notifyTimeCp}
                            onChange={(date) => setNotifyTimeCp(date)}
                            dateFormat="dd-MM-yyyy hh:mm aa"
                            className="form-control"/>
                        </FormGroup>
                      </Col>}
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label><b>CHỌN CHẾ ĐỘ HIỆN BÀI</b></label><br/>
                          <Select options={getRequestShowHideOptions()} value={selectedRequestShowHideCpOption} onChange={handleRequestShowHideCp}/>
                        </FormGroup>
                      </Col>
                      {requestShowHide && <Col className="pr-1" md="6">
                        <FormGroup>
                          <label><b>THỜI GIAN HIỆN LÊN <span style={{color: "red"}}>CHÁNH PHÁP</span> </b></label><br/>
                          <DatePicker showTimeSelect selected={publishTimeCp}
                            onChange={(date) => setPublishTimeCp(date)}
                            dateFormat="dd-MM-yyyy hh:mm aa"
                            className="form-control"/>
                        </FormGroup>
                      </Col>}
                    </Row>
                  </>}
                  <hr/>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t('category.category')} <span style={{color: "red"}}> * </span></label>
                        <Select options={getCategoryList()} onChange={handleCategoryChange}/>
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="4">
                      <FormGroup>
                        <label>{t('category.genre_1')} <span style={{color: "red"}}> * </span></label>
                        <Select options={getGenre1List()} onChange={handleGenre1Change}/>
                      </FormGroup>
                    </Col>
                    <Col className="px-3" md="4">
                      <FormGroup>
                        <label>{t('category.genre_2')}</label>
                        <Select options={getGenre2List()} onChange={handleGenre2Change}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>{t('files.title')} <span style={{color: "red"}}> * </span></label>
                        <Input
                          placeholder={t('files.input_title')}
                          type="text" onChange={handleTitle} defaultValue={title}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        {t('files.description')}
                        <Input type="textarea" placeholder={t('files.input_desc')} onChange={handleDescription}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr/>
                  {categoryCode !== 'SHORTVIDEO' &&
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('files.record_at')}</label>
                        <DatePicker selected={recordAt}
                          onChange={(date) => setRecordAt(date)}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          timeClassName={handleColor}/>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('files.record_place')}</label>
                        <Input
                          placeholder={t('files.record_place')}
                            type="text" onChange={handleRecordPlace} defaultValue={recordPlace}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-4" md="4">
                      <FormGroup>
                        <label>{t('files.publish_no')}</label>
                        <Input
                          placeholder={t('files.publish_no')}
                            type="text" onChange={handlePublishNo} defaultValue={publishNo}
                        />
                      </FormGroup>
                    </Col>
                  </Row>}
                  {categoryCode === 'SHORTVIDEO' && <Row>
                    <Col className="pr-1" md="8">
                      <FormGroup>
                        <label>Bài giảng gốc liên kết <span style={{color: "red"}}> * </span></label>
                        <div>
                          <OriginalMediaSuggestion callback={(data) => {setOriginalMediaIds(data);}}
                            shortLinkableMedias={shortLinkableMedias}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>}
                  <hr/>
                  {categoryCode !== 'SHORTVIDEO' && <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Mã màu hex bắt đầu</label>
                        <Input
                            onChange={handleBgColorFrom}
                            placeholder={"Mã màu hex bắt đầu"}
                            type="text" defaultValue={bgColorFrom}
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Mã màu hex kết thúc</label>
                        <Input
                            onChange={handleBgColorTo}
                            placeholder={"Mã màu hex kết thúc"}
                            type="text" defaultValue={bgColorTo}
                          />
                      </FormGroup>
                    </Col>
                  </Row>}
                  <Row>
                    <Col className="pr-1" md="12">
                      {statusStr && <div className={success ? "blockquote blockquote-info" : "blockquote blockquote-danger"}>
                        <p>
                          {error && <b className="text-danger">{t('common.error')}:</b>} <label className={success ? "text-info" : "text-danger"}>{statusStr} </label>
                          {success && <a href="/admin/mediafiles">
                            <Button className="btn-round" type="button" onClick={onResetClick}>
                              {t('files.view_media_files')}
                            </Button>
                          </a>}
                        </p>
                      </div>}
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <CreateButton waiting={waiting}/>
                      <Button className="btn-round" type="button" onClick={onResetClick}>
                        {t('files.clear')}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </fieldset>
  </>;
}

export default MediafileNew;
