import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';

i18n
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "charts.m1": 'Jan',
          "charts.m2": 'Feb',
          "charts.m3": 'Mar',
          "charts.m4": 'Apr',
          "charts.m5": 'May',
          "charts.m6": 'Jun',
          "charts.m7": 'Jul',
          "charts.m8": 'Aug',
          "charts.m9": 'Sep',
          "charts.m10": 'Oct',
          "charts.m11": 'Nov',
          "charts.m12": 'Dec',
          "common.pls_wait": 'Please wait for processing',
          "common.view_details": 'View details',
          "common.information": 'Information',
          "common.status": "Status",
          "common.days": 'days',
          "common.error": 'ERROR',
          "common.image": 'Image',
          "common.title": 'Title',
          "common.name": 'Name',
          "common.genre": 'Genre',
          "common.search": "Search...",
          "common.confirm": "Confirm",
          "common.save": "Save",
          "common.back": "Back",
          "common.page": "Page",
          "common.from_date": 'From Date',
          "common.to_date": 'To Date',
          "menu.dashboard": 'Dashboard',
          "menu.administration": 'Administration',
          "menu.packages": 'Packages',
          "menu.accounts": 'Accounts',
          "menu.permissions": 'Role & Permissions',
          "menu.configuration": 'Configuration',
          "menu.category.genre": 'Category & Genre',
          "menu.auditlogs": 'Audit Logs',
          "menu.bookquotes": 'Trích dẫn sách',
          "menu.library": 'Thư viện',
          "menu.bookslogans": 'Câu đạo lý',
          "menu.upload.bookquote": 'Thêm mới',
          "menu.upload.bookslogan": 'Thêm mới',
          "menu.post": 'Posts',
          "menu.media": 'Media',
          "menu.upload.media": 'Upload Media',
          "menu.apppq.users": 'Users',
          "menu.books": 'Books',
          "menu.upload.book": 'Upload Book',
          "menu.orders": 'Orders',
          "dashboard.totaluser": 'Total Users',
          "dashboard.books": 'Books',
          "dashboard.media_files": 'Media Files',
          "dashboard.updatenow": 'Update Now',
          "dashboard.totalupgrade": 'Total Upgrade',
          "dashboard.totalview": 'Success View',
          "dashboard.currentmonth": 'Current Month',
          "dashboard.untilnow": 'Until now',
          "dashboard.thisyear": '2024',
          "dashboard.packagepercent": 'Package %',
          "dashboard.packagepercent_bymonth": 'Monthly Package %',
          "dashboard.view_percent_bymonth": 'Monthly View %',
          "dashboard.cost_percent_bymonth": 'Monthly Cost %',
          "admin.accounts.list": 'ACCOUNTS LIST',
          "admin.accounts.id": 'id',
          "admin.accounts.username": 'Username',
          "admin.accounts.email": 'Email',
          "admin.accounts.role": 'Role',
          "admin.packages.list": 'PACKAGE LIST',
          "admin.packages.id": 'id',
          "admin.packages.name": 'Name',
          "admin.packages.price": 'Price',
          "admin.packages.postSalePrice": 'Post Sale Price',

          "bookquotes.language": 'Language',
          "bookquotes.quote": 'Trích dẫn',
          "bookquotes.bookquote_list": 'Danh sách',
          "bookquotes.common_info": 'Thông tin chung',
          "bookquotes.create_new_bookquote": 'Tạo mới',
          "bookquotes.input_quote": 'Nhập trích dẫn',
          "bookquotes.from_page": 'Trang bắt đầu',
          "bookquotes.input_from_page": 'Nhập trang bắt đầu',
          "bookquotes.to_page": 'Trang kết thúc',
          "bookquotes.input_to_page": 'Nhập trang kết thúc',
          "bookquotes.book": 'Sách trích dẫn',
          "bookquotes.create_bookquote": 'Tạo trích dẫn',
          "bookquotes.clear": 'Nhập lại',
          "bookquotes.update_bookquote": 'Cập nhật trích dẫn',
          "bookquotes.updated_bookquote": 'Cập nhật trích dẫn thành công',
          "bookquotes.created_bookquote": 'Đã tạo trích dẫn sách thành công',

          "bookslogans.language": 'Language',
          "bookslogans.slogan": 'Câu đạo lý',
          "bookslogans.bookslogan_list": 'Danh sách',
          "bookslogans.common_info": 'Thông tin chung',
          "bookslogans.create_new_bookslogan": 'Tạo mới',
          "bookslogans.input_slogan": 'Nhập Khẩu hiệu',
          "bookslogans.from_page": 'Trang bắt đầu',
          "bookslogans.input_from_page": 'Nhập trang bắt đầu',
          "bookslogans.to_page": 'Trang kết thúc',
          "bookslogans.input_to_page": 'Nhập trang kết thúc',
          "bookslogans.book": 'Sách nguồn',
          "bookslogans.create_bookslogan": 'Tạo khẩu hiệu',
          "bookslogans.clear": 'Nhập lại',
          "bookslogans.update_bookslogan": 'Cập nhật khẩu hiệu',
          "bookslogans.updated_bookslogan": 'Cập nhật khẩu hiệu thành công',
          "bookslogans.created_bookslogan": 'Đã tạo khẩu hiệu sách thành công',

          "error.quote.required": 'Yêu cầu nhập trích dẫn',
          "error.frompage.required": 'Yêu cầu nhập trang bắt đầu',
          "error.topage.required": 'Yêu cầu nhập trang kết thúc',

          "books.language": 'Language',
          "books.notify_app": 'Notify AppPQ',
          "books.share_link": 'Share',
          "books.book_list": 'Book List',
          "books.sale_price": 'Sale price',
          "books.input_sale_price": 'Input sale price',
          "books.print_price": 'Print price',
          "books.input_print_price": 'Input print price',
          "books.attach_music": 'Bài nhạc liên kết',
          "books.media": 'Media',
          "books.auto_play": 'Auto Play',
          "books.clear": 'Clear',
          "books.create_book": 'Create book',
          "books.update_book": 'Update book',
          "books.created_book": 'Created book successfully',
          "books.updated_book": 'Updated book successfully',
          "books.cannot_create_book": 'Cannot create book',
          "books.browse_image": 'Image file',
          "books.browse_vi_pdf": 'VI pdf to upload',
          "books.browse_en_pdf": 'EN pdf to upload',
          "books.select_vi_pdf_to_upload": 'Select VI pdf to upload',
          "books.select_en_pdf_to_upload": 'Select EN pdf to upload',
          "books.upload_image_progress": 'Image upload progress',
          "books.upload_vi_book_progress": 'Vi book upload progress',
          "books.upload_en_book_progress": 'EN book upload progress',
          "books.price_selling": 'Price & Selling',
          "books.common_info": 'Information',
          "books.updated_at": 'Updated At',
          "books.input_title": 'Input title',
          "books.title": 'Title',
          "books.author": 'Author',
          "books.in_stock": 'In Stock',
          "books.input_author": 'Input author',
          "books.book_desc": 'Description',
          "books.input_book_desc": 'Input description',
          "books.create_new_book": 'Create new book',
          "files.create": 'Create Media',
          "files.upload_new_media": 'Upload New Media',
          "files.record_place": 'Record Place',
          "files.record_at": 'Record At',
          "files.publish_no": 'Publish No',
          "files.set_time": 'Set date & time you want to publish to user',
          "files.view_media_files": 'View media files',
          "files.clear": 'Clear',
          "files.title": 'Title',
          "files.input_title": 'Input title',
          "files.description": 'Description',
          "files.input_desc": 'Input description',
          "files.browse_image": 'Browse Image',
          "files.browse_mp3_mp4": 'Browse File mp3/mp4',
          "files.upload_image_progress": '% upload image',
          "files.upload_media_progress": '% upload file mp3/mp4',
          "files.category_genre": 'Category & Genre',
          "files.file_list": 'Media files list',
          "files.view_count": 'View count',
          "files.size": 'Size',
          "files.duration": 'Duration',
          "files.created_at": 'Created at',
          "orders.list": 'Orders List',
          "orders.order_no": 'Order No',
          "orders.date": 'Date',
          "orders.item_list": 'Item list',
          "orders.shipping_info": 'Shipping Info',
          "orders.status": 'Status',
          "orders.buyer": 'BUYER',
          "orders.receiver": 'RECEIVER',
          "orders.status.paid": 'Waiting',
          "orders.status.transferred": 'Transferred',
          "orders.status.finished": 'Finished',
          "orders.status.cancelled": 'Cancelled',
          "users.from_date": 'From Date',
          "users.to_date": 'To Date',
          "users.email": 'Email',
          "users.phone": 'Phone',
          "users.user_list": 'User list',
          "users.name": 'Name',
          "users.update_profile": 'Update profile',
          "users.subscriptions": 'Subscription History',
          "users.account": 'Account',
          "users.subscription": 'Subscription',
          "users.role": 'Role',
          "users.vip": 'VIP',
          "users.device_no": 'Device No',
          "users.registered": 'Registered',
          "users.device_name": 'Device Name',
          "users.upgrade_date": 'Upgrade Date',
          "users.upgrade_package": 'Package',
          "users.full_name": 'Full Name',
          "users.profile": 'Profile',
          "category.category": 'Category',
          "category.category_list": 'Category & Genre List',
          "category.category_id": 'Category ID',
          "category.category_name": 'Category Name',
          "category.genre": ' Genre',
          "category.genre_id": 'Genre ID',
          "category.genre_1": 'Genre 1',
          "category.genre_2": 'Genre 2',
          "error.category.required": 'Category is required',
          "error.genres1.required": 'Genre 1 is required',
          "error.genres.required": 'Genre is required',
          "error.saleprice.required": 'Sale price is required',
          "error.printprice.required": 'Print price is required',
          "error.imagefile.required": 'Image file is required',
          "error.mediafile.required": 'Media file is required',
          "files.disable_notify_app": 'Tắt thông báo',
        },
      },
      vi: {
        translation: {
          "charts.m1": 'Th1',
          "charts.m2": 'Th2',
          "charts.m3": 'Th3',
          "charts.m4": 'Th4',
          "charts.m5": 'Th5',
          "charts.m6": 'Th6',
          "charts.m7": 'Th7',
          "charts.m8": 'Th8',
          "charts.m9": 'Th9',
          "charts.m10": 'Th10',
          "charts.m11": 'Th11',
          "charts.m12": 'Th12',
          "common.pls_wait": 'Vui lòng chờ xử lý',
          "common.days": 'ngày',
          "common.information": 'Thông tin',
          "common.save": "Lưu",
          "common.back": "Quay lại",
          "common.error": 'LỖI',
          "common.from_date": 'Từ ngày',
          "common.to_date": 'Đến ngày',
          "common.image": 'Ảnh',
          "common.title": 'Tiêu đề',
          "common.name": 'Tên',
          "common.page": 'Trang',
          "common.genre": 'Thể loại',
          "common.search": "Tìm kiếm...",
          "common.status": "Trạng thái",
          "common.confirm": "Xác nhận",
          "common.view_details": 'Xem chi tiết',
          "menu.dashboard": 'Trang chủ',
          "menu.administration": 'Quản lý hệ thống',
          "menu.accounts": 'Tài khoản quản lý',
          "menu.packages": 'Danh sách gói',
          "menu.permissions": 'Quyền truy cập',
          "menu.configuration": 'Thông tin cấu hình',
          "menu.category.genre": 'Danh mục sản phẩm',
          "menu.auditlogs": 'Audit Logs',
          "menu.post": 'Bài viết',
          "menu.media": 'Media',
          "menu.upload.media": 'Đăng media',
          "menu.apppq.users": 'Người dùng',
          "menu.books": 'Sách',
          "menu.upload.book": 'Đăng sách',
          "menu.orders": 'Đơn hàng',
          "dashboard.totaluser": 'Số người dùng',
          "dashboard.books": 'Sách',
          "dashboard.media_files": 'Số files',
          "dashboard.updatenow": 'Cập nhật',
          "dashboard.totalupgrade": 'Tổng tiền',
          "dashboard.totalview": 'Lượt view',
          "dashboard.currentmonth": 'Tháng hiện tại',
          "dashboard.thisyear": '2024',
          "dashboard.untilnow": 'Tính tới thời điểm hiện tại',
          "dashboard.packagepercent": 'Tỉ lệ gói',
          "dashboard.packagepercent_bymonth": 'Tỉ lệ gói theo tháng',
          "dashboard.view_percent_bymonth": 'Tỉ lệ view theo tháng',
          "dashboard.cost_percent_bymonth": 'Tỉ lệ chi phí theo tháng',
          "admin.accounts.list": 'DANH SÁCH TÀI KHOẢN',
          "admin.accounts.id": 'id',
          "admin.accounts.username": 'Tên tài khoản',
          "admin.accounts.email": 'Email',
          "admin.accounts.role": 'Quyền',
          "admin.packages.list": 'DANH SÁCH GÓI',
          "admin.packages.id": 'id',
          "admin.packages.name": 'Tên',
          "admin.packages.price": 'Giá gốc',
          "admin.packages.postSalePrice": 'Giá bán',
          "files.file_list": 'Danh sách files',
          "files.record_place": 'Nơi thuyết giảng',
          "files.record_at": 'Ngày thuyết giảng',
          "files.publish_no": 'Giấy phép xuất bản',
          "files.create": 'Bắt đầu tạo file mới',
          "files.upload_new_media": 'Đăng bài media mới',
          "files.set_time": 'Thời gian hiện lên AppPQ',
          "files.view_media_files": 'Xem danh sách file',
          "files.clear": 'Xóa dữ liệu đã nhập',
          "files.title": 'Tiêu đề',
          "files.input_title": 'Nhập tiêu đề',
          "files.description": 'Mô tả',
          "files.input_desc": 'Nhập mô tả',
          "files.browse_image": 'File ảnh',
          "files.browse_mp3_mp4": 'File mp3/mp4',
          "files.upload_image_progress": '% upload ảnh',
          "files.upload_media_progress": '% upload file mp3/mp4',
          "files.category_genre": 'Phân loại',
          "files.notify_app": 'Gửi Thông Báo AppPQ',
          "files.disable_notify_app": 'Tắt thông báo',
          "files.view_count": 'Lượt xem',
          "files.size": 'Dung lượng',
          "files.duration": 'Thời lượng',
          "files.created_at": 'Ngày giờ tạo',
          "books.updated_at": 'Ngày cập nhật',
          "books.media": 'Media',
          "books.language": 'Ngôn ngữ',
          "books.auto_play": 'Phát tự động',
          "books.price_selling": 'Thông tin bán hàng',
          "books.common_info": 'Thông tin chung',
          "books.notify_app": 'Thông báo AppPQ',
          "books.share_link": 'Chia sẻ',
          "books.book_list": 'Danh Sách',
          "books.sale_price": 'Giá bán',
          "books.input_sale_price": 'Nhập giá bán',
          "books.print_price": 'Giá in trên sách',
          "books.input_print_price": 'Nhập giá in trên bìa sách',
          "books.attach_music": 'Liên kết với file nhạc',
          "books.clear": 'Xóa dữ liệu đã nhập',
          "books.create_book": 'Tạo sách',
          "books.in_stock": 'Còn hàng',
          "books.update_book": 'Cập nhật sách',
          "books.created_book": 'Tạo sách thành công',
          "books.updated_book": 'Cập nhật sách thành công',
          "books.cannot_create_book": 'Không thể tạo sách',
          "books.browse_image": 'File ảnh',
          "books.browse_vi_pdf": 'File sách tiếng Việt',
          "books.browse_en_pdf": 'File sách tiếng Anh',
          "books.select_vi_pdf_to_upload": 'Chọn file sách tiếng Việt',
          "books.select_en_pdf_to_upload": 'Chọn file sách tiếng Anh',
          "books.upload_image_progress": '% upload ảnh',
          "books.upload_vi_book_progress": '% upload file tiếng Việt',
          "books.upload_en_book_progress": '% upload file tiếng Anh',
          "books.input_title": 'Nhập tiêu đề',
          "books.title": 'Tiêu đề',
          "books.author": 'Tác giả',
          "books.input_author": 'Nhập tên tác giả',
          "orders.list": 'Danh sách đơn hàng',
          "orders.order_no": 'Mã đơn hàng',
          "orders.date": 'Ngày',
          "orders.item_list": 'Dách sách',
          "orders.shipping_info": 'Thông tin vận chuyển',
          "orders.status": 'Trạng thái',
          "orders.buyer": 'Người mua',
          "orders.receiver": 'Người nhận',
          "orders.status.paid": 'Đang chờ xử lý',
          "orders.status.transferred": 'Đã vận chuyển',
          "orders.status.finished": 'Đã hoàn thành',
          "orders.status.cancelled": 'Đã hủy',
          "users.from_date": 'Từ ngày',
          "users.to_date": 'Đến ngày',
          "users.email": 'Email',
          "users.phone": 'Điện thoại',
          "users.user_list": 'Danh sách',
          "users.name": 'Tên',
          "users.update_profile": 'Cập nhật tài khoản',
          "users.subscriptions": 'Lịch sử nâng cấp',
          "users.account": 'Tài khoản',
          "users.subscription": 'Đăng Ký/Nâng cấp',
          "users.role": 'Vai trò',
          "users.device": 'Thiết bị',
          "users.device_no": 'ID Thiết bị',
          "users.registered": 'Ngày đăng ký',
          "users.device_name": 'Loại Thiết bị',
          "users.upgrade_date": 'Ngày nâng cấp',
          "users.upgrade_package": 'Gói nâng cấp',
          "users.full_name": 'Tên',
          "users.vip": 'VIP',
          "users.profile": 'Thông tin',
          "category.category_list": 'VaiDanh mục',
          "category.category_id": 'ID danh mục lớn',
          "category.category_name": 'Tên danh mục lớn',
          "category.category": 'Danh mục lớn',
          "category.genre": 'Danh mục con',
          "category.genre_id": ' ID Danh mục con',
          "category.genre_1": 'Danh mục con cấp 1',
          "category.genre_2": 'Danh mục con cấp 2',
          "books.book_desc": 'Mô tả sách',
          "books.input_book_desc": 'Nhập mô tả sách',
          "books.create_new_book": 'Tạo sách mới',
          "error.category.required": 'Chưa nhập thông tin Danh Mục Lớn',
          "error.genres1.required": 'Chưa nhập thông tin Danh Mục Con',
          "error.genres.required": 'Chưa nhập thông tin Danh Mục Phân Loại',
          "error.saleprice.required": 'Chưa nhập giá bán',
          "error.printprice.required": 'Chưa nhập giá in trên bìa sách',
          "error.imagefile.required": 'Chưa chọn file ảnh',
          "error.mediafile.required": 'Chưa chọn file mp3/mp4',
        },
      },
    },
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;