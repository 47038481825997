import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Switch from "react-switch";

import CategoryStore from "../category/category-store.js";
import BookSearchDto from "./book-search-dto.js";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import BookGroupStore from "./book-group-store.js";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

let store = new CategoryStore();

function BookSearch(props) {
  const { t } = useTranslation();

  const [genres, setGenres] = useState([]);
  const [stockStatuses, setStockStatuses] = useState([{'id': 1, 'name': 'Còn hàng'}, {'id': 2, 'name': 'Hết hàng'}]);
  const [pdfOptions, setPdfOptions] = useState([{'id': 1, 'name': 'Sách tiếng Việt'}, {'id': 2, 'name': 'Sách tiếng Anh'}, {'id':3, 'name': 'Sách song ngữ'}, {'id': 4, 'name': 'Chưa có'}]);
  const [genreId, setGenreId] = useState('');
  const [genreName, setGenreName] = useState('');
  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const [inStock, setInStock] = useState('');
  const [bookGroup, setBookGroup] = useState('');
  const [pdfOption, setPdfOption] = useState('');
  const [bookGroups, setBookGroups] = useState([]);

  useEffect(() => {
    store.list3().then((data) => {
      var tmp = data.filter(item => item.id === 8)[0].genres;
      setGenres(tmp.filter(item => item.code !== 'MOI'));
    });

    new BookGroupStore().list().then((data) => {
      setBookGroups(data);
    });
  }, []);

  function handleGenreChange(genre) {
    setGenreId(genres.filter(item => item.code === genre.value)[0].id);
    setGenreName(genre.name);
  }

  function handleTitleChange(event) {
    setTitle(event.target.value);
  }

  function handleIdChange(event) {
    setId(event.target.value);
  }

  function handleSearch(event) {
    props.showProgressingFn();
    let searchReq = new BookSearchDto({
      "ids": id,
      "title": title,
      "genre1Id": genreId,
      "inStock": inStock,
      "bookGroup": bookGroup,
      "pdfOption": pdfOption
    });
    props.store.search(searchReq).then((data) => props.callback(data, searchReq));
  }

  function handleInStock(selected) {
    setInStock(selected.target.value);
  }

  function handlePdfOption(selected) {
    setPdfOption(selected.value);
  }

  function getGenreList() {
    return genres.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function getPdfOptionList() {
    return pdfOptions.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function handleBookGroupChange(group) {
    setBookGroup(group.value);
  }

  function getBookGroup() {
    return bookGroups.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  return (
    <>
      <Row>
        <Col className="px-3" md="2">
          <FormGroup>
            <label>Danh mục sách</label>
            <Select options={getGenreList()} onChange={handleGenreChange}/>
          </FormGroup>
        </Col>
        <Col className="pr-1" md="2">
          <FormGroup>
            <label>Bộ sách</label>
            <Select options={getBookGroup()} onChange={handleBookGroupChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="1">
          <FormGroup>
            <label>ID</label>
            <Input placeholder="Id" type="text" onChange={handleIdChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>{t('books.title')}</label>
            <Input placeholder={t('books.title')} type="text" onChange={handleTitleChange}/>
          </FormGroup>
        </Col>
        <Col className="pr-1" md="2">
          <FormGroup>
            <label>Có sách đọc?</label>
            <Select options={getPdfOptionList()} onChange={handlePdfOption}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary" type="submit">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default BookSearch;
