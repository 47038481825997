import React, { useState, useEffect } from "react";
import axios from "axios";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Switch from "react-switch";
import Select from 'react-select';
import BookSuggestion from "./component/book-suggestion.js";
import LibraryItemStore from "./libraryitem-store.js";
import BookStore from "../book/book-store.js";
import AlbumStore from "../album/album-store.js";
import MediaSuggestion from "./component/media-suggestion.js";

import PureModal from 'react-pure-modal';
import UndoButton from "component/undo-button.js";
import SaveButton from "component/save-button.js";

import HttpUtils from "utils/http-utils.js";
import NotiUtils from "utils/noti-utils.js";
import { useTranslation } from 'react-i18next';
import { Gallery } from "react-grid-gallery";
import 'quill/dist/quill.snow.css';
import { useQuill } from 'react-quilljs';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function LibraryItemEdit(props) {
  const { t } = useTranslation();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],

        [{ list: 'ordered'}, { list: 'bullet' }],
        [{ indent: '-1'}, { indent: '+1' }],

        [{ color: [] }, { background: [] }],
      ],
    }
  });

  const [bookId, setBookId] = useState(props.data.bookId);
  const [bookName, setBookName] = useState(props.data.bookName);
  const [content, setContent] = useState(props.data.content);
  const [contentEn, setContentEn] = useState(props.data.contentEn);
  const [contentCn, setContentCn] = useState(props.data.contentCn);
  const [title, setTitle] = useState(props.data.title);
  const [titleEn, setTitleEn] = useState(props.data.titleEn);
  const [titleCn, setTitleCn] = useState(props.data.titleCn);
  const [type, setType] = useState(props.data.type);
  const [bgImage, setBgImage] = useState('');
  const [bgImageSrc, setBgImageSrc] = useState(props.data.bgImageSrc);
  const [previewImg, setPreviewImg] = useState(props.data.bgImageSrc);

  const [imageFile, setImageFile] = useState('');
  const [pdfFile, setPdfFile] = useState('');
  const [imageProgress, setImageProgress] = useState('');
  const [pdfProgress, setPdfProgress] = useState('');
  const [mediaIds, setMediaIds] = useState(props.data.mediaIds);

  const [result, setResult] = useState('');
  const [notifyModal, setNotifyModal] = useState(false);

  let store = new AlbumStore();

  function validate() {
    let changedTitle = props.data.title !== title;
    let changedTitleEn = props.data.titleEn !== titleEn;
    let changedTitleCn = props.data.titleCn !== titleCn;
    let changedContent = props.data.content !== content;
    let changedContentEn = props.data.contentEn !== contentEn;
    let changedContentCn = props.data.contentCn !== contentCn;
    let changedBgImage = imageFile !== '';
    let changedMediaIds = props.data.mediaIds !== mediaIds;
    if(changedTitle || changedContent || changedTitleEn || changedTitleEn
        || changedContentEn || changedContentCn || changedBgImage || changedMediaIds) {
      setNotifyModal(true);
    } else {
      new NotiUtils().notify(props.notiRef, 'info', "Nothing changed to update");
    }
  }

  function onUpdate(e) {
    setNotifyModal(false);

    let updatedImage = imageFile !== '';
    let updatedPdfFile = pdfFile !== '';
    if(updatedImage) {
      updateImageFile(e);
    }
    if(updatedPdfFile) {
      updatePdfFile(e);
    }

    props.onUpdate({
      'id': props.data.id,
      'title': title,
      'titleEn': titleEn,
      'titleCn': titleCn,
      'content': content,
      'contentEn': contentEn,
      'contentCn': contentCn,
      "type": type,
      "bgImageFilename": imageFile !== '' ? imageFile.name : '',
      'bookId': bookId,
      'bookName': bookName,
      'mediaIds': mediaIds
    });
    updateSrc(props.data);
    props.onReadonly();
    new NotiUtils().notify(props.notiRef, 'info', 'Cập nhật thành công');

    setTimeout(() => {
      props.data.bgImageSrc = bgImageSrc + "?" + Date.now();
    }, 2000);
  }

  function updateSrc(src) {
    src.title = title;
    src.titleEn = titleEn;
    src.titleCn = titleCn;
    src.content = content;
    src.contentEn = contentEn;
    src.contentCn = contentCn;
    src.mediaIds = mediaIds;
    src.bookId = bookId;
    src.bookName = bookName;
    src.bgImageSrc = bgImageSrc + "?" + Date.now();
  }

  function handleTitleChange(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function handleTitleEnChange(event) {
    let selected = event.target.value;
    setTitleEn(selected);
  }

  function handleTitleCnChange(event) {
    let selected = event.target.value;
    setTitleCn(selected);
  }

  function handleContentChange(event) {
    let selected = event.target.value;
    setContent(selected);
  }

  function handleContentEnChange(event) {
    let selected = event.target.value;
    setContentEn(selected);
  }

  function handleContentCnChange(event) {
    let selected = event.target.value;
    setContentCn(selected);
  }

  function onImageSelected(event) {
     setImageFile(event.target.files[0]);
     setPreviewImg(URL.createObjectURL(event.target.files[0]));
  }

  function onPdfSelected(event) {
    setPdfFile(event.target.files[0]);
  }

  async function updateImageFile(e)  {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "imageFile",
      imageFile,
      imageFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/library/" + props.data.id + "/updateImageFile", formData, {
        onUploadProgress: data => setImageProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    );
    return res.data === 'true';
  }

  async function updatePdfFile(e) {
    e.preventDefault()
    const formData = new FormData();
    formData.append(
        "pdfFile",
        pdfFile,
        pdfFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/library/" + props.data.id + "/updatePdfFile", formData, {
        onUploadProgress: data => setPdfProgress(Math.round((100 * data.loaded) / data.total)),
        headers: new HttpUtils().getHeaders()
      }
    )
    return res.data === 'true';
  }

  return (
    <div className={!props.show ? "hide" : undefined}>
      <Row>
        <Col md="4">
          <Card className="card-user">
            <CardBody>
              <div className="image2">
                {previewImg && <img alt="..." src={previewImg} />}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">CẬP NHẬT NỘI DUNG</CardTitle>
            </CardHeader>
          <CardBody>
              <Form>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>File ảnh</label>
                      <Input
                        placeholder="Please select image to upload"
                        type="file" accept="image/jpg"
                        onChange={onImageSelected}
                      />
                    </FormGroup>
                  </Col>
                </Row>
               {props.data.type === 4 &&
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>File pdf</label>
                      <Input
                        placeholder="Please select pdf to upload"
                        type="file" accept="*.pdf"
                        onChange={onPdfSelected}
                      />
                    </FormGroup>
                  </Col>
                </Row>}
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Tiêu đề</label>
                      <div>
                        <Input type="textarea" onChange={handleTitleChange} defaultValue={title}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {(props.data.type === 3 || props.data.type === 5) && <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Tiêu đề (English)</label>
                      <div>
                        <Input type="textarea" onChange={handleTitleEnChange} defaultValue={titleEn}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>}
                {(props.data.type === 3 || props.data.type === 5) && <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Tiêu đề (Chinese)</label>
                      <div>
                        <Input type="textarea" onChange={handleTitleCnChange} defaultValue={titleCn}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>}
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Nội dung</label>
                      <div>
                        <Input type="textarea" onChange={handleContentChange} defaultValue={content}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                {(props.data.type === 3 || props.data.type === 5) && <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Nội dung (English)</label>
                      <div>
                        <Input type="textarea" onChange={handleContentEnChange} defaultValue={contentEn}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>}
                {(props.data.type === 3 || props.data.type === 5) && <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Nội dung (Chinese)</label>
                      <div>
                        <Input type="textarea" onChange={handleContentCnChange} defaultValue={contentCn}/>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>}
                {(props.data.type !== 4 && props.data.type !== 5) && <Row>
                  <Col className="pr-2" md="12">
                      <FormGroup>
                        <label>Sách</label>
                        <BookSuggestion
                          attachableBooks={props.attachableBooks}
                          callback={(data) => {setBookId(data[0].id);}}
                          bookIds={[props.data.bookId]}/>
                      </FormGroup>
                  </Col>
                </Row>}
                {(props.data.type === 4 || props.data.type === 5) && <Row>
                  <Col className="pr-1" md="9">
                      <FormGroup>
                        <label>Liên kết với file media</label>
                        <MediaSuggestion
                          attachableMedias={props.attachableMedias}
                          callback={(ids) => {setMediaIds(ids);}}
                          mediaIds={props.data.mediaIds}/>
                      </FormGroup>
                  </Col>
                </Row>}
                <Row>
                  <Col className="pr-3" md="12">
                    <FormGroup>
                      <label className="title text-warning">{result}</label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    <SaveButton callback={validate}/> &nbsp;
                    <UndoButton callback={(id) => {props.onReadonly();}}/>
                    <PureModal width="400px" header="Please confirm"
                      footer={
                       <div>
                         <button onClick={() => setNotifyModal(false)}>Cancel</button>
                         <button onClick={onUpdate}>Yes</button>
                       </div>
                      }
                      isOpen={notifyModal}
                      onClose={()=>setNotifyModal(false)}
                    >
                      <p>Are you sure you want to save new data?</p>
                      {props.data.content !== content ? <p><b>{props.data.content}</b> -> <b>{content}</b></p>: undefined}
                      {props.data.contentEn !== contentEn ? <p><b>{props.data.contentEn}</b> -> <b>{contentEn}</b></p>: undefined}
                      {props.data.title !== title ? <p><b>{props.data.title}</b> -> <b>{title}</b></p>: undefined}
                      {props.data.titleEn !== titleEn ? <p><b>{props.data.titleEn}</b> -> <b>{titleEn}</b></p>: undefined}
                    </PureModal>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default LibraryItemEdit;
