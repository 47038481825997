import React, { useState, useEffect } from "react";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

function OriginalMediaSuggestion(props) {
  const [multiSelections, setMultiSelections] = useState([]);

  function handleSelection(data) {
    setMultiSelections(data);
    let ids = data.map((x, i) => x.id).join(',');
    console.log(ids);
    props.callback(ids);
  }

  useEffect(() => {
    if(props.originalMediaIds) {
      var lst = props.originalMediaIds.split(',');
      setMultiSelections(
        props
          .shortLinkableMedias
          .filter((x) => lst.includes(x.id.toString()))
      );
    }
  }, []);

  return <Typeahead
         id="basic-typeahead-multiple"
         labelKey="title"
         multiple
         onChange={handleSelection}
         options={props.shortLinkableMedias}
         placeholder="Choose some media..."
         selected={multiSelections}
       />;
}

export default OriginalMediaSuggestion;
