import React from "react";

// reactstrap widgets
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

function CategoryPaging(props) {
  let hasMore = props.searchPage?.totalPages > 15;
  let numberOfPages = props.searchPage?.totalPages > 15 ? 15 : props.searchPage?.totalPages;
  return (
    <>
      <Row>
        <Col md="12">
          <Pagination>
            <PaginationItem><PaginationLink first href="#"/></PaginationItem>
            {!props.searchPage?.first &&
              <PaginationItem><PaginationLink previous href="#"/></PaginationItem>
            }
            {[...Array(numberOfPages)].map((x, i) =>
              <PaginationItem key={i}>
                <PaginationLink key={i} href="#">
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            )}
            {hasMore && <PaginationLink key="hasMore" href="#">...</PaginationLink>}
            {!props.searchPage?.last &&
              <PaginationItem><PaginationLink next href="#"/></PaginationItem>
            }
            <PaginationItem><PaginationLink href="#" last /></PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

export default CategoryPaging;
