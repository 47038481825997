import React from "react";

import Roles from "page/administrator/uam/roles.js";
import Permissions from "page/administrator/uam/permissions.js";

function Uam() {
  return (
    <>
      <Roles/>
      <Permissions/>
    </>
  );
}

export default Uam;
