import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import PureModal from 'react-pure-modal';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";

import adminRoutes, { mediaRoutes, bookRoutes, uploadRoutes } from "routes.js";
import LoginStore from "page/login/login-store.js";

function Header(props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [logoutNotify, setLogoutNotify] = React.useState(false);
  const [lang, setLang] = React.useState(i18n.language);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };
  const getBrand = () => {
    let brandName = "Default Brand";
    let role = localStorage.getItem('role');
    let routes = [];
    if(role === 'ADMIN') {
      routes = adminRoutes;
    } else if(role === 'BOOK_ADMIN') {
      routes = bookRoutes;
    } else if(role === 'MEDIA_ADMIN') {
      routes = mediaRoutes;
    } else if(role === 'UPLOAD_ADMIN') {
      routes = uploadRoutes;
    }

    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return t(brandName);
  };
  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };
  let store = new LoginStore();
  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  React.useEffect(() => {
    let role = localStorage.getItem('role');
    if(typeof role === "string" && role.trim() === "null") {
      navigate('/login');
    }
  });
  React.useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  function handleLogout1(event) {
    setLogoutNotify(true);
  }

  function handleLanguage(event) {
    let newLang = 'en' === lang ? 'vi' : 'en';
    setLang(newLang);
    i18n.changeLanguage(newLang);
  }

  function handleLogout2(event) {
    store.logout().then((data) => {
      console.log(data);
      if(data) {
        localStorage.setItem('access_token', null);
        localStorage.setItem('role', null);
        navigate('/login');
      }
    });
  }

  return (
    <>
      <PureModal width="400px" header="Please confirm"
        footer={
         <div>
           <button onClick={() => setLogoutNotify(false)}>Cancel</button>
           <button onClick={handleLogout2}>Yes</button>
         </div>
        }
        isOpen={logoutNotify}
        onClose={()=>setLogoutNotify(false)}
      >
        <p>Are you sure you want to logout?</p>
      </PureModal>
      <Navbar
        color={
          location.pathname.indexOf("full-screen-maps") !== -1 ? "dark" : color
        }
        expand="lg"
        className={
          location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand href="/">{getBrand()}</NavbarBrand>
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <form>
              <InputGroup className="no-border">
                <Input placeholder={t('common.search')}/>
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="nc-icon nc-zoom-split" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form>
            <Nav navbar>
              <NavItem>
                <Link to="#" onClick={handleLanguage} className="nav-link btn-magnify">
                  <img src={'en' === lang ? require("assets/img/us.png") : require("assets/img/vn.png")}/>
                </Link>
              </NavItem>
              <NavItem>
                <Link to="#" onClick={handleLogout1} className="nav-link btn-magnify">
                  <i className="nc-icon nc-button-power" />
                  <p>
                    <span className="d-lg-none d-md-block">Logout</span>
                  </p>
                </Link>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
