import React, { useState } from "react";
import CategoryGenreDto from "./category-dto.js";
import 'react-pure-modal/dist/react-pure-modal.min.css';

import {
  Button,
  Row
} from "reactstrap";

function CategoryItem(props) {
  const [readonly, setReadonly] = useState([true]);

  function handleClick(event) {
    if(event.detail === 2) { // double click
      setReadonly(!readonly);
    }
  }

  function handleUpdate(event) {
    setReadonly(true);
    let updateReq = new CategoryGenreDto({
      "id": props.data.id,
      "category": props.data.title,
      "genre1": props.data.title,
      "genre2": props.data.title
    });
    console.log(updateReq.id + " -> " + updateReq.title);

    props.store.update(updateReq);
  }
//
//  function SaveButton() {
//    if (readonly) {
//      return "";
//    }
//    return <span><Button className="btn-round btn-icon" color="success" outline size="m"><i className="fa fa-check" /> </Button> Save</span>;
//  }
//
//  function UndoButton() {
//    if (readonly) {
//      return "";
//    }
//    return <span id={"undo_cat_genre_" + props.data.genreId} onClick={(event) => setReadonly(true)} style={{cursor: "pointer"}}>
//      <Button className="btn-round btn-icon" color="danger" outline size="m">
//        <i className="fa fa-undo" />
//      </Button>Back
//    </span>;
//  }
//
//  function EditButton() {
//    if (!readonly) {
//      return "";
//    }
//    return <span onClick={() => setReadonly(!readonly)} style={{cursor: "pointer"}}>
//      <Button className="btn-round btn-icon" color="success" outline size="m">
//        <i className="fa fa-edit" />
//      </Button>Edit
//    </span>;
//  }

  return (
    <>
      <tr key={"cat_genre_" + props.data.genreId} onClick={handleClick}>
        <td>{props.data.categoryId}</td>
        <td>{props.data.category}</td>
        <td>{props.data.genreId}</td>
        <td>{props.data.genre1}</td>
        <td>{props.data.genre2}</td>
      </tr>
    </>
  );
}

export default CategoryItem;
