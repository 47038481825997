import axios from "axios";
import HttpUtils from "../../utils/http-utils.js";

let bookGenres;
let listDef;
class CategoryStore {
  search = async function(searchParams: CategorySearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/categories/search", searchParams,
          { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  list = async function() {
    if(!listDef) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/categories",
      { headers: new HttpUtils().getHeaders()});
      listDef = response.data;
    }
    return listDef;
  }

  list2 = async function() {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/categories2",
      { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  list3 = async function() {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/categories3",
      { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  listBookGenres = async function() {
    if(!bookGenres) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/bookGenres",
        { headers: new HttpUtils().getHeaders()});
      bookGenres = response.data;
    }
    return bookGenres;
  }
}

export default CategoryStore;