import React, { useState } from "react";

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function DeleteButton(props) {
  const [modal, setModal] = useState(false);

  return <span style={{cursor: "pointer"}}>
    <Modal isOpen={modal} fade={false} toggle={()=>setModal(false)} centered>
      <ModalHeader toggle={() => setModal(false)}>XÁC NHẬN</ModalHeader>
      <ModalBody>
        <p>Are you sure you want to delete?</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {setModal(false);props.callback(props.id);}}>
          Xác nhận
        </Button>{' '}
        <Button color="secondary" onClick={()=>setModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setModal(true)}>
      <Button className="btn-round" outline size="sm"  color="danger">
        <i className="fa fa-trash" /> Xóa
      </Button>
    </span>
  </span>;
}

export default DeleteButton;
