import React, { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";

import CategoryStore from "../category/category-store.js";
import LibraryItemStore from "./libraryitem-store.js";
import AlbumStore from "../album/album-store.js";
import BookStore from "../book/book-store.js";
import MediaFileStore from "../file/mediafile-store.js";
import BookSuggestion from "./component/book-suggestion.js";
import MediaSuggestion from "./component/media-suggestion.js";
import HttpUtils from "utils/http-utils.js";
import NotiUtils from "utils/noti-utils.js";
import NotificationAlert from "react-notification-alert";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Gallery } from "react-grid-gallery";
import 'quill/dist/quill.snow.css';
import { useQuill } from 'react-quilljs';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Spinner,
  Row,
  Col,
  Label,
  Progress,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function LibraryItemNew() {
  const { t } = useTranslation();

  const [mediaIds, setMediaIds] = useState('');

  const [imageFile, setImageFile] = useState('');
  const [previewImg, setPreviewImg] = useState('');
  const [pdfFile, setPdfFile] = useState('');

  let imageFileNameResult = '';
  let pdfFileNameResult = '';
  const [imageProgress, setImageProgress] = useState('');
  const [pdfProgress, setPdfProgress] = useState('');

  const [bookId, setBookId] = useState('');
  const [bookName, setBookName] = useState('');
  const [content, setContent] = useState('');
  const [contentEn, setContentEn] = useState('');
  const [contentCn, setContentCn] = useState('');
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleCn, setTitleCn] = useState('');

  const [type, setType] = useState(0);
  const [attachableBooks, setAttachableBooks] = useState([]); 
  const [attachableMedias, setAttachableMedias] = useState([]);

  const [statusStr, setStatusStr] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const notificationAlert = React.useRef();

  let store = new AlbumStore();
  useEffect(() => {
    new BookStore().attachableBooks().then((data) => {
      setAttachableBooks(data);
    })
    new MediaFileStore().attachableCpMedias().then((data) => {
      setAttachableMedias(data);
    });
  }, []);

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  function handleContent(event) {
    let selected = event.target.value;
    setContent(selected);
  }

  function handleContentEn(event) {
    let selected = event.target.value;
    setContentEn(selected);
  }

  function handleContentCn(event) {
    let selected = event.target.value;
    setContentCn(selected);
  }

  function handleTitle(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function handleTitleEn(event) {
    let selected = event.target.value;
    setTitleEn(selected);
  }

  function handleTitleCn(event) {
    let selected = event.target.value;
    setTitleCn(selected);
  }

  function onImageSelected(event) {
    setImageFile(event.target.files[0]);
    setPreviewImg(URL.createObjectURL(event.target.files[0]));
  }

  function onPdfSelected(event) {
    setPdfFile(event.target.files[0]);
  }

  function clean() {
    setTitle('');
    setTitleEn('');
    setTitleCn('');
    setContent('');
    setContentEn('');
    setContentCn('');
  }

  function onResetClick(e) {
    clean();
    setStatusStr('');
  }

  function onCreateClick(e) {
    if(waiting) {
      return;
    }
    if(imageFile && !content) {
      setStatusStr(t('Thiếu nội dung'));
      setError(true);
      return;
    }
    start(e);
  }

  function start(e) {
    setImageProgress(0);
    setPdfProgress(0);

    onImageFileUpload(e);
  }

  function onImageFileUpload(e) {
    if(imageFile) {
      setStatusStr(t('common.pls_wait'));
      e.preventDefault();
      setWaiting(true);
      const formData = new FormData();
      formData.append(
          "imageFile",
          imageFile,
          imageFile.name
      );

      let apiPath = "/library/" + type + "/uploadImageFile";
      axios.post(process.env.REACT_APP_API_URL + apiPath, formData, {
          onUploadProgress: data => setImageProgress((100 * data.loaded) / data.total),
          headers: new HttpUtils().getHeaders()
        }
      ).then((res) => {
        if(res.data) {
          imageFileNameResult = res.data;
          if(type === 4) {
            onPdfFileUpload(e);
          } else {
            onNew(e);
          }
        } else {
          setWaiting(false);
          setStatusStr("Cannot upload image");
        }
      });
    } else {
      onNew(e);
    }
  }

  function onPdfFileUpload(e) {
    if(pdfFile) {
      setStatusStr(t('common.pls_wait'));
      e.preventDefault();
      setWaiting(true);
      const formData = new FormData();
      formData.append(
          "pdfFile",
          pdfFile,
          pdfFile.name
      );

      let apiPath = "/library/" + type + "/uploadPdfFile";
      axios.post(process.env.REACT_APP_API_URL + apiPath, formData, {
          onUploadProgress: data => setPdfProgress((100 * data.loaded) / data.total),
          headers: new HttpUtils().getHeaders()
        }
      ).then((res) => {
        if(res.data) {
          pdfFileNameResult = res.data;
          onNew(e);
        } else {
          setWaiting(false);
          setStatusStr("Cannot upload pdf");
        }
      });
    } else {
      onNew(e);
    }
  }

  function onNew(e) {
    if(imageFile) {
      axios.post(process.env.REACT_APP_API_URL + "/library/finalize", {
        'imageFile': imageFileNameResult,
        'pdfFile': pdfFileNameResult,
        "type": type,
        "bookId": bookId,
        "content": content,
        "contentEn": contentEn,
        "contentCn": contentCn,
        "title": title,
        "titleEn": titleEn,
        "titleCn": titleCn,
        "mediaIds": mediaIds
      },
      {headers: new HttpUtils().getHeaders()}).then((res) => {
        if(res.data) {
          setImageFile('');

          setSuccess(true);
          setStatusStr("Tạo thành công");
        } else {
          setStatusStr("Không thể tạo");
        }
        setWaiting(false);
      });
    } else {
      setStatusStr("Cần upload ít nhất 1 file ảnh");
    }
  }

  function handleTypeChange(select) {
    setType(select.value);
  }

  function getTypeList() {
    return [
      {
        value: 1,
        label: 'Trích Dẫn'
      },
      {
        value: 2,
        label: 'Câu Đạo Lý'
      },
      {
        value: 3,
        label: 'Nền Tảng Đạo Đức'
      },
      {
        value: 4,
        label: 'Lời Bài Hát'
      },
      {
        value: 5,
        label: 'Đạo Phật Nhập Môn'
      }
    ];
  }

  function CreateButton(props) {
    return <Button className="btn-round" color="primary" type="button" onClick={onCreateClick}>
      Bắt Đầu Xử Lý {props.waiting && <Spinner size="sm">t("common.pls_wait")</Spinner>}
    </Button>;
  }

  return (
    <fieldset disabled={waiting}>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <div className="sloganimage">
                  {previewImg && <img alt="..." src={previewImg} />}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">TẠO MỚI</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Loại</label>
                        <Select options={getTypeList()} onChange={handleTypeChange}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>File ảnh</label>
                        <Input
                          placeholder="Please select image to upload"
                          type="file" accept="image/jpg"
                          onChange={onImageSelected}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t('files.upload_image_progress')}</label>
                        <Progress value={imageProgress}> {imageProgress < 100 ? "uploading" : "done"} </Progress>
                      </FormGroup>
                    </Col>
                  </Row>
                  {type === 4 && <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>File lời bài hát</label>
                        <Input
                          placeholder="Please select pdf to upload"
                          type="file" accept=".pdf"
                          onChange={onPdfSelected}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>% upload pdf</label>
                        <Progress value={pdfProgress}> {pdfProgress < 100 ? "uploading" : "done"} </Progress>
                      </FormGroup>
                    </Col>
                  </Row>}
                  <Row>
                    <Col className="pr-3" md="12">
                      <FormGroup>
                        <label>Tiêu đề <span style={{color: "red"}}> * </span></label>
                        <div>
                          <Input type="textarea" onChange={handleTitle}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {(type === 3 || type == 5) && <Row>
                    <Col className="pr-3" md="12">
                      <FormGroup>
                        <label>Tiêu đề (English) </label>
                        <div>
                          <Input type="textarea" onChange={handleTitleEn}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>}
                  {(type === 3 || type == 5) && <Row>
                    <Col className="pr-3" md="12">
                      <FormGroup>
                        <label>Tiêu đề (Chinese) </label>
                        <div>
                          <Input type="textarea" onChange={handleTitleCn}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>}
                  <Row>
                    <Col className="pr-3" md="12">
                      <FormGroup>
                        <label>NỘI DUNG</label>
                        <div>
                          <Input type="textarea" onChange={handleContent}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  {(type === 3 || type == 5) && <Row>
                    <Col className="pr-3" md="12">
                      <FormGroup>
                        <label>Nội dung (English)</label>
                        <div>
                          <Input type="textarea" onChange={handleContentEn}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>}
                  {(type === 3 || type == 5) && <Row>
                    <Col className="pr-3" md="12">
                      <FormGroup>
                        <label>Nội dung (Chinese)</label>
                        <div>
                          <Input type="textarea" onChange={handleContentCn}/>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>}
                  {(type === 4 || type === 5) &&
                  <Row>
                    <Col className="pr-1" md="9">
                      <FormGroup>
                        <label>Liên kết với file media</label>
                        <MediaSuggestion callback={(ids) => {setMediaIds(ids);}}
                          attachableMedias={attachableMedias}/>
                      </FormGroup>
                    </Col>
                  </Row>}
                  {(type === 1 || type === 2) && <Row>
                    <Col className="pr-2" md="12">
                      <FormGroup>
                        <label>Sách</label>
                        <BookSuggestion
                          attachableBooks={attachableBooks}
                          callback={(data) => {setBookId(data[0].id);}}/>
                      </FormGroup>
                    </Col>
                  </Row>}
                  <Row>
                    <Col className="pr-1" md="12">
                      {statusStr && <div className={success ? "blockslogan blockslogan-info" : "blockslogan blockslogan-danger"}>
                        <p>
                          {error && <b className="text-danger">{"THÔNG BÁO"}: </b>}
                            <h6 className={success ? "text-info" : "text-danger"}><b>{statusStr}</b></h6>
                          {success && <a href="/admin/library" className="text-danger"><br/><b>Xem danh sách</b></a>}
                        </p>
                      </div>}
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <CreateButton waiting={waiting}/>
                      <Button className="btn-round" type="button" onClick={onResetClick}> Reset </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </fieldset>
  );
}

export default LibraryItemNew;
