import React, { useEffect, useState } from "react";
import { useSearchParams, useMatch, useNavigate, Outlet } from "react-router-dom";

import CategoryStore from "../category/category-store.js";
import MediaFileStore from "./mediafile-store.js";
import MediaFileSearchDto from "./mediafile-search-dto.js";
import MediafileView from "./mediafile-view.js";
import MediaFileSearch from "./mediafiles-search.js";
import MediafilePaging from "./mediafiles-paging.js";
import { useTranslation } from 'react-i18next';
import NotificationAlert from "react-notification-alert";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new MediaFileStore();

function MediaFiles() {
  const { t } = useTranslation();
  const notificationAlert = React.useRef();
  const [searchReq, setSearchReq] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPage, setSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const [shortLinkableMedias, setShortLinkableMedias] = useState([]);

  useEffect(() => {
    search(page);
    new CategoryStore().list();
    new MediaFileStore().shortLinkableMedias().then((data) => {
      setShortLinkableMedias(data);
    });
  }, [navigate]);

  function search(page) {
    let urlIds = searchParams.get('ids');
    let request;
    if(searchReq) {
      request = new MediaFileSearchDto({
        "ids": urlIds,
        "pageNo": page,
        "title": searchReq.title,
        "categoryId": searchReq.categoryId,
        "source": searchReq.source,
        "status": searchReq.status,
        "genre1Id": searchReq.genre1Id
      });
    } else {
      request = new MediaFileSearchDto({
        "ids": urlIds,
        "pageNo": page
      });
    }
    store.search(request).then((data) => {
      setSearchPage(data);
      setLoaded(true);
    });
  }

  return (
    <>
      <div className="content">
        <Outlet />
        <NotificationAlert ref={notificationAlert} />
        <span className={Boolean(useMatch("/admin/mediafiles/new")) ? "hide" : undefined}>
          <MediaFileSearch store={store} page={page}
            showProgressingFn={()=> setLoaded(false)}
            callback={(data, searchReq) => {setLoaded(true); setSearchPage(data); setSearchReq(searchReq);}}/>
          <span className={loaded ? "hide" : undefined}>
            <Row>
              <Col>
                <Progress animated color="danger" style={{height: "5px"}} striped value={100}/>
              </Col>
            </Row>
          </span>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">{t('files.file_list').toUpperCase()}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>{t("common.image")}</th>
                        <th>{t("common.title")}</th>
                        <th>{t("files.category_genre")}</th>
                        <th>{t("common.information")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchPage?.content?.map(item => (
                        <MediafileView id={item.id} key={item.id} data={item}
                          notiRef={notificationAlert}
                          shortLinkableMedias={shortLinkableMedias}
                          showProgressingFn={()=> setLoaded(false)}
                          refresh={() => search(page)}/>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <MediafilePaging searchPage={searchPage}
            callback={(newPage) => {search(newPage);setPage(newPage);}}/>
        </span>
      </div>
    </>
  );
}

export default MediaFiles;
