import React, { useEffect, useState } from "react"; 
import ConfigStore from "./config-store.js";
import ConfigSearchDto from "./config-search-dto.js";
import ConfigItem from "./config-item.js";
import ConfigSearch from "./configs-search.js";
import ConfigPaging from "./configs-paging.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
  FormGroup,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

let store = new ConfigStore();

function ConfigList() {
  const [configSearchPage, setConfigSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let searchReq = new ConfigSearchDto();
    store.search(searchReq).then((data) => {
      setConfigSearchPage(data);
      setLoaded(true);
    });
  }, []);
  
  return (
    <>
      <div className="content">
        <ConfigSearch store={store} callback={(data) => {setConfigSearchPage(data);}}/>
        <span style={{display: loaded ? "none" : "block"}}>
          <Row>
            <Col>
              <Progress animated color="info" striped value={100}/>
            </Col>
          </Row>
        </span>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">CONFIGS LIST</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>ID</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Value</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {configSearchPage?.content?.map(item => (
                        <ConfigItem id={item.id} key={item.id} data={item} store={store}/>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ConfigPaging searchPage={configSearchPage}/>
      </div>
    </>
  );
}

export default ConfigList;
