import React, { useState } from "react";

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function DisableButton(props) {
  const [modal, setModal] = useState(false);

  return <span style={{cursor: "pointer"}} className={props.className}>
    <Modal isOpen={modal} fade={false} toggle={()=>setModal(false)} centered>
      <ModalHeader toggle={() => setModal(false)}>ẨN BÀI</ModalHeader>
      <ModalBody>
        ẨN BÀI <b>{props.name}</b> <br/> <br/> Bạn cần HIỆN BÀI thủ công trên trang admin sau này nếu muốn bài hiện trên ứng dụng?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {setModal(false);props.callback(props.id);}}>
          Xác nhận
        </Button>{' '}
        <Button color="secondary" onClick={()=>setModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setModal(true)}>
      <Button className="btn-round" outline size="sm">
        <i className="fa fa-eye-slash" /> Ẩn bài
      </Button>
    </span>
  </span>;
}

export default DisableButton;
