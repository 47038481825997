class OrderDto {
  id;
  orderNo;
  date;
  itemList;
  paymentSummary;
  status;

  constructor(data) {
    this.id = data?.id;
    this.orderNo = data?.orderNo;
    this.date = data?.date;
    this.itemList = data?.itemList;
    this.paymentSummary = data?.paymentSummary;
    this.status = data?.status;
  }
}

export default OrderDto;
