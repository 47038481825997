class MediaFileSearchDto {
  ids;
  title;
  status;
  source;
  categoryId;
  genre1Id;
  genre2Id;
  pageNo;
  pageSize;
  sortBy;
  sortAsc;

  constructor(data) {
    this.ids = data?.ids;
    this.title = data?.title;
    if(data?.status) {
      this.status = data?.status;
    }
    this.source = data?.source;
    this.categoryId = data?.categoryId;
    this.genre1Id = data?.genre1Id;
    this.genre2Id = data?.genre2Id;
    this.pageNo = data?.pageNo;
    this.pageSize = data?.pageSize;
    this.sortBy = data?.sortBy;
    this.sortAsc = data?.sortAsc;
  }
}

export default MediaFileSearchDto;
