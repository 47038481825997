import React, { useState } from "react";
import CategoryStore from "./category-store.js";
import CategorySearchDto from "./category-search-dto.js";
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap";

function CategorySearch(props) {
  const { t } = useTranslation();

  const [categoryName, setCategoryName] = useState('');
  const [genreName, setGenreName] = useState('');

  function handleCategoryName(event) {
    setCategoryName(event.target.value);
  }

  function handleGenreName(event) {
    setGenreName(event.target.value);
  }

  function handleSearch(event, callback) {
    let searchReq = new CategorySearchDto({
      "categoryName": categoryName,
      "genreName": genreName,
    });
    props.store.search(searchReq).then(props.callback);
  }

  return (
    <>
      <Row>
        <Col className="pl-3" md="3">
          <FormGroup>
            <label>{t('category.category')}</label>
            <Input placeholder={t('category.category')} type="text" onChange={handleCategoryName}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>{t('category.genre')}</label>
            <Input placeholder={t('category.genre')} type="text" onChange={handleGenreName}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default CategorySearch;
