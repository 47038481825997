class LibraryItemSearchDto {
  ids;
  type;
  content;
  pageNo;
  pageSize;
  sortBy;
  sortAsc;

  constructor(data) {
    this.ids = data?.ids;
    this.content = data?.content;
    this.type = data?.type;
    this.pageNo = data?.pageNo;
    this.pageSize = data?.pageSize;
    this.sortBy = data?.sortBy;
    this.sortAsc = data?.sortAsc;
  }
}

export default LibraryItemSearchDto;
