import React, { useState, useEffect } from "react";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';

import {
  Card,
  CardBody,
  Table,
  Button,
  Input,
} from "reactstrap";

import MediaFileStore from "../../file/mediafile-store.js";
import BookMediaDto from "../book-media-dto.js";
import { useTranslation } from 'react-i18next';

function MediaTable(props) {
  const { t } = useTranslation();
  const [bookMediaItems, setBookMediaItems] = useState(props.bookMediaItems || []);
  const [attachableMedias, setAttachableMedias] = useState([]);

  useEffect(() => {
    new MediaFileStore().attachableMedias().then((data) => {
      setAttachableMedias(data);
    });
  }, []);

  function handleSelection(data, item) {
    let id = data.map((x, i) => x.id).join(',');
    let title = data.map((x, i) => x.title).join(',');
    item.mediaId = parseInt(id);
    item.title = title.substring(title.indexOf('-') + 2);

    var copy = [...bookMediaItems];
    setBookMediaItems(copy);
    props.callback(copy);
  }

  function findSelection(item) {
    return attachableMedias.filter((x) => item.mediaId === x.id);
  }

  function onAddClick() {
    var copy = [...bookMediaItems];
    copy.push(new BookMediaDto());
    setBookMediaItems(copy);
    props.callback(copy);
  }

  function onRemoveClick(selectedIndex) {
    console.log(bookMediaItems);
    var copy = [];
    for(var index = 0; index < bookMediaItems.length; index++) {
      if(index !== selectedIndex) {
        copy.push(bookMediaItems[index]);
      }
    }
    setBookMediaItems(copy);
    props.callback(copy);
  }

  function onChangeValue() {
    props.callback(bookMediaItems);
  }

  function getLanguageOptions() {
    return [
      {value: 'en', label: 'English'},
      {value: 'vi', label: 'Tiếng Việt'}
    ];
  }

  return (
    <Card>
      <CardBody>
        <Table>
          <thead className="text-primary">
            <tr>
              <th>{t("books.media")}</th>
              <th>{t("common.page")}</th>
              <th>{t("common.title")}</th>
              <th>{t("books.language")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {bookMediaItems?.map((item, i) => (
              <tr>
                <td width="40%">
                  <Typeahead labelKey="title" id={i}
                     options={attachableMedias}
                     onChange={(selected) => {handleSelection(selected, item)}}
                     selected={findSelection(item)}
                     placeholder="Choose some media..."/>
                </td>
                <td width="5%">
                  <Input onChange={(e) => {item.page = e.target.value; onChangeValue();}} type="text" defaultValue={item.page}/>
                </td>
                <td>
                  <Input onChange={(e) => {item.title = e.target.value; onChangeValue();}} type="text" defaultValue={item.title}/>
                </td>
                <td className="text-center">
                  <Select options={getLanguageOptions()} onChange={(e) => {item.title = e.value; onChangeValue();}}/>
                </td>
                <td><Button outline onClick={() => onRemoveClick(i)}  size="sm" color="danger"> <i className="fa fa-trash" /> </Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button outline size="sm" onClick={onAddClick} color="success"> <i className="fa fa-plus" /> </Button>
      </CardBody>
    </Card>
  );
}

export default MediaTable;
