import axios from "axios";
import BookSearchDto from "./book-search-dto.js";
import HttpUtils from "../../utils/http-utils.js";

let attachableList;

class BookStore {
  search = async function(searchParams: BookSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/books/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(bookId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/books/" + bookId,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  notifyApp = async function(bookId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/books/" + bookId + "/notifyApp", {},
          { headers: new HttpUtils().getHeaders()}
        ).then((res) => {
          console.log("Notify App -" + bookId);
        });
  }

  update = async function(book) {
    await axios
      .post(process.env.REACT_APP_API_URL + "/books", book, { headers: new HttpUtils().getHeaders()});
  }

  remove = async function(bookId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/books/" + bookId + "/remove", {},
          { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("remove -" + bookId);
        });
  }

  attachableBooks = async function() {
    if(!attachableList) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/books/attachableBooks",
          { headers: new HttpUtils().getHeaders()});
      attachableList = response.data;
    }
    return attachableList;
  }
}

export default BookStore;