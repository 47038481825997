import React, { useState } from "react";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';

import MediaViewer from "./media-viewer.js";
import MediaFileStore from "../mediafile-store.js";

// reactstrap widgets
import {
  Button,
  Spinner
} from "reactstrap";

function PlayButton(props) {
  const { id, title } = props;

  const [viewLink, setVewLink] = useState('');
  const [playModal, setPlayModal] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);

  if (viewLoading) {
    return <Spinner color="primary">
      processing...
    </Spinner>;
  }

  function handleViewLink(event) {
    setViewLoading(true);
    new MediaFileStore().view(props.id)
        .then((res) => {
            setPlayModal(true);
            setVewLink(res.data);
            setViewLoading(false);
        });
  }

  return <span style={{cursor: "pointer"}}>
    <PureModal width="1000px" header={props.title}
      footer={
        <div>
          <button onClick={() => setPlayModal(false)}>Close</button>
        </div>
      }
      isOpen={playModal}
      onClose={() => setPlayModal(false)}
    >
      <div className='player-wrapper'>
        <MediaViewer viewLink={viewLink}/>
      </div>
    </PureModal>
    <span onClick={handleViewLink} >
      <Button className="btn-round btn-icon" color="primary" outline size="sm">
        <i className="fa fa-play" />
      </Button>Phát file gốc
    </span>
  </span>;
}

export default PlayButton;
