import React, { useEffect, useState } from "react";
import UserStore from "./user-store.js";
import UserSearchDto from "./user-search-dto.js";
import UserView from "./user-view.js";
import UserSearch from "./users-search.js";
import UserPaging from "./users-paging.js";
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new UserStore();

function UserList() {
  const { t } = useTranslation();

  const [searchPage, setSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    search(0);
  }, []);

  function search(page) {
    let searchReq = new UserSearchDto({
      "pageNo": page
    });
    store.search(searchReq).then((data) => {
      setSearchPage(data);
      setLoaded(true);
    });
  }

  function sendMsg(id, message) {
    store.sendMsg(id, message);
  }

  return (
    <>
      <div className="content">
        <UserSearch store={store} callback={(data) => {setSearchPage(data);}}/>
        <span className={loaded ? "hide" : undefined}>
          <Row>
            <Col>
              <Progress animated color="info" striped value={100}/>
            </Col>
          </Row>
        </span>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{t('users.user_list')}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead className="text-primary">
                    <tr>
                      <th>{t('users.account')}</th>
                      <th>Ứng dụng</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                      {searchPage?.content?.map(item => (
                        <UserView id={item.id} key={item.id} data={item}
                            showProgressingFn={()=> setLoaded(false)}
                            onSendMsg={(id, message) => sendMsg(id, message)}
                            refresh={() => search(searchPage)}/>
                      ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <UserPaging searchPage={searchPage} callback={(newPage) => search(newPage)}/>
      </div>
    </>
  );
}

export default UserList;
