import axios from "axios";
import HttpUtils from "../../utils/http-utils.js";

let bookGroups;

class BookGroupStore {
  list = async function() {
    if(!bookGroups) {
      let response = await axios.get(process.env.REACT_APP_API_URL + "/bookgroups",
            { headers: new HttpUtils().getHeaders()});
      bookGroups = response.data;
    }
    return bookGroups;
  }
}

export default BookGroupStore;