import axios from "axios";
import ConfigSearchDto from "./config-search-dto.js";
import HttpUtils from "utils/http-utils.js";

class ConfigStore {
  search = async function(searchParams: ConfigSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/configs/search", searchParams,
          { headers: new HttpUtils().getHeaders() });
    return response.data;
  }
}

export default ConfigStore;