import axios from "axios";
import PackageDto from "./package-dto.js";
import PackageSearchDto from "./package-search-dto.js";
import HttpUtils from "utils/http-utils.js";

class PackageStore {
  search = async function(searchParams: PackageSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/packages/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(packageId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/packages/" + packageId,
              { headers: new HttpUtils().getHeaders() });
    return response.data;
  }

  update = async function(package1: PackageDto) {
    await axios.post(process.env.REACT_APP_API_URL + "/packages", package1,
          {headers: new HttpUtils().getHeaders() })
        .then((res) => {
          console.log(res);
          console.log(res.data);
        });
  }
}

export default PackageStore;