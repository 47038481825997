import axios from "axios";
import LibraryItemSearchDto from "./libraryitem-search-dto.js";
import HttpUtils from "../../utils/http-utils.js";

class LibraryItemStore {
  search = async function(searchParams: LibraryItemSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/library/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(id: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/library/" + id,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  update = async function(item) {
    await axios
      .post(process.env.REACT_APP_API_URL + "/library", item, { headers: new HttpUtils().getHeaders()});
  }

  remove = async function(itemId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/library/" + itemId + "/remove", {},
          { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("remove -" + itemId);
        });
  }
}

export default LibraryItemStore;