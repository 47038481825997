import React, { useState } from "react";

import 'react-pure-modal/dist/react-pure-modal.min.css';

import EditButton from "component/edit-button.js";
import DeleteButton from "component/delete-button.js";
import DisableButton from "component/disable-button.js";
import EnableButton from "component/enable-button.js";

import Title from "./component/title.js";
import MediaFileStore from "./mediafile-store.js";
import MediaFileDto from "./mediafile-dto.js";
import MediafileEdit from "./mediafile-edit.js";
import { useTranslation } from 'react-i18next';

import PlayButton from "./component/play-button.js";
import PlayHlsButton from "./component/playhls-button.js";
import CreateHlsButton from "./component/create-hls-button.js";
import SwitchModeButton from "./component/switch-mode-button.js";
import CopyLinkButton from "./component/copy-link-button.js";

import {
  Row, Badge
} from "reactstrap";

function MediafileView(props) {
  const [writable, setWritable] = useState(false);
  const [message, setMessage] = useState("processing... it takes approximate 5 minutes or faster");

  const [data] = useState(props.data);

  function saveDirtyValues(dto: MediaFileDto) {
    new MediaFileStore().update(
      dto,
      (res) => {
        if(res.data) {
          setMessage(res.data);
        } else {
          setMessage("Cannot update new values ....");
        }
      }
    );
  }

  async function remove(mediaId: int) {
    props.showProgressingFn();
    await new MediaFileStore().remove(
      mediaId,
      (res) => {}
    );
    props.refresh();
  }

  function disable(mediaId: int) {
    new MediaFileStore().disable(
      mediaId,
      (res) => {}
    );
  }

  function enable(mediaId: int, publishTime) {
    new MediaFileStore().enable(
      mediaId,
      publishTime,
      (res) => {}
    );
  }

  return (
    <>
      <MediafileViewRead hide={writable} data={data}
        onEdit={() => setWritable(true)}
        onDelete={(id) => remove(id)}
        onDisable={(id) => disable(id)}
        onEnable={(id, publishTime) => enable(id, publishTime)}
      />
      <tr className={!writable ? "hide" : undefined}>
        <td colSpan="5">
          <MediafileEdit show={writable} data={data}
            refresh={props.refresh}
            notiRef={props.notiRef}
            shortLinkableMedias={props.shortLinkableMedias}
            onUpdate={(dto) => {saveDirtyValues(dto)}}
            onReadonly={() => setWritable(false)}/>
        </td>
      </tr>
    </>
  );
}

function MediafileViewRead(props) {
  const { t } = useTranslation();
  const [status, setStatus] = useState(props.data.status);
  const [publishTime, setPublishTime] = useState(props.data.publishTime);

  return (
    <tr className={props.hide ? "hide" : undefined}>
      <td width="15%">
        <img alt="" src={props.data.imageUrl}/>
      </td>
      <td width="30%">
        <Title data={props.data} callback={(title) => props.data.title = title}/>
      </td>
      <td>
          {props.data.category} <br/>
          {props.data.genre1} {props.data.genre2 && ">" } <br/>
          {props.data.genre2 && props.data.genre2} <br/>
          {props.data.queueStatus && (props.data.queueStatus === 'ACTIVE' || props.data.queueStatus === 'MISSED') && <><PlayButton id={props.data.id} title={props.data.title}/> <br/></>}
          {props.data.queueStatus && props.data.queueStatus === 'ACTIVE' && <><PlayHlsButton id={props.data.id} title={props.data.title}/> <br/></>}
          <CreateHlsButton id={props.data.id} /> <br/> {props.data.queueStatus !== 'MISSED' && <SwitchModeButton id={props.id} />}
      </td>
      <td>
          <b>ID: {props.data.id} </b><br/>

          <span style={{color: "red"}}><b>{status === 'DISABLED' && <>ĐANG ẨN<br/></>}</b></span>
          <span style={{color: "red"}}>
            <b>{status === 'INACTIVE' && <>ĐANG CHỜ HIỆN
              {publishTime && ": "
                + new Date(publishTime).toLocaleDateString()
                + " "
                + new Date(publishTime).toLocaleTimeString()}<br/></>}</b>
          </span>
          <span style={{color: "red"}}><b>{status === 'ACTIVE' && <>ĐANG HIỆN<br/></>}</b></span>

          {t('files.view_count')}: {props.data.viewCount} <br/>
          {t('files.size')}: {props.data.size} <br/>
          {t('files.duration')}: <span style={{color: "red"}}> <b>{props.data.duration}</b> </span><br/>
          {t('files.created_at')}: {props.data.createdAt}<br/>
          <CopyLinkButton shareLink={props.data.shareLink} shareTittle="Link Chia sẻ"/>
          <hr/>
          {props.data.hasHls && <Badge color="primary">HLS</Badge>} &nbsp; {props.data.hasHlsMaster && <Badge color="primary">HLS Master</Badge>}
          <br/>
          {(props.data.categoryCode !== 'SHORTVIDEO' && props.data.recordAt) && <> {t('files.record_at')}: {props.data.recordAt} <br/></>}
          {(props.data.categoryCode !== 'SHORTVIDEO' && props.data.recordPlace !== 'Chưa nhập dữ liệu') && <> {t('files.record_place')}: {props.data.recordPlace} <br/> </>}
          {(props.data.categoryCode !== 'SHORTVIDEO' && props.data.publishNo) && <> {t('files.publish_no')}: {props.data.publishNo}</>}
          {props.data.categoryCode === 'SHORTVIDEO' && <> Bài giảng gốc: {props.data.originalMediaIds && <b>: {props.data.originalMediaIds}</b>}</>}
      </td>
      <td>
        <EditButton id={props.data.id}
          className={props.data.queueStatusStr ? "hide" : undefined}
          callback={(id) => props.onEdit()}/><br/>
        <DeleteButton id={props.data.id} callback={(id) => {props.onDelete(id);}}/> <br/>
        <DisableButton className={(status !== 'ACTIVE' && status !== 'INACTIVE') ? "hide" : undefined}
          id={props.data.id} name={props.data.title} callback={(id) => {setStatus('DISABLED'); props.onDisable(id);}}/>
        <EnableButton className={status === 'DISABLED' ? undefined : "hide"}
          id={props.data.id} name={props.data.title}
          callback={(id, time) => {setStatus('INACTIVE'); setPublishTime(time); props.onEnable(id, time);}}/>
      </td>
    </tr>
  );
}

export default MediafileView;
