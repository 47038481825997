import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import UndoButton from "component/undo-button.js";
import DeleteButton from "component/delete-button.js";
import SaveButton from "component/save-button.js";
import Switch from "react-switch";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Label,
  Col,
} from "reactstrap";

function Subscription(props) {
  return (
    <li>
      <Row>
        <Col md="8">
          <b>ID: {props.data.id}</b> <br />
          <b>{props.data.channel}</b> <br />
          {props.data.fromDate} - <b>{props.data.toDate}</b>
        </Col>
        <Col>
          {props.data.desc === 'ADMIN MANUAL' && <DeleteButton/>}
        </Col>
      </Row>
      <hr/>
    </li>
  );
}

function UserEdit(props) {
  const { t } = useTranslation();
  const [vip, setVip] = useState(props.data.vip);

  function validate() {
  }

  function handleVip(selected) {
    setVip(selected);
  }

  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">{t('users.profile')}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('users.device_no')} </label>
                        <Input
                          defaultValue={props.data.deviceNo}
                          disabled
                          placeholder="device"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('users.device_name')} </label>
                        <Input
                          defaultValue={props.data.deviceName}
                          disabled
                          placeholder="device"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label>{t("users.vip")}</Label>
                        <div><Switch onChange={handleVip} checked={vip} /></div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('users.phone')}</label>
                        <Input
                          defaultValue={props.data.phone}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          {t('users.email')}
                        </label>
                        <Input placeholder={props.data.email} type="email" />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>{t('users.full_name')}</label>
                        <Input
                          defaultValue={props.data.name}
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <SaveButton callback={validate}/> &nbsp;
                      <UndoButton callback={(id) => {props.onReadonly(); }}/>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{t('users.subscriptions')}</CardTitle>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled team-members">
                  {props.data.subscriptions?.map(item => (
                    <Subscription data={item}/>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default UserEdit;
