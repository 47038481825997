import React, { useEffect, useState } from "react";
import axios from "axios";

import CategoryStore from "../category/category-store.js";
import BookGroupStore from "./book-group-store.js";
import MediaFileStore from "../file/mediafile-store.js";
import MediaSuggestion from "./component/media-suggestion.js";
import HttpUtils from "utils/http-utils.js";
import NotiUtils from "utils/noti-utils.js";
import NotificationAlert from "react-notification-alert";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Spinner,
  Row,
  Col,
  Label,
  Progress,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function BookNew() {
  const { t } = useTranslation();

  const [mediaIds, setMediaIds] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [printPrice, setPrintPrice] = useState('');
  const [genres1, setGenres1] = useState([]);
  const [bookGroups, setBookGroups] = useState([]);
  const [bookGroup, setBookGroup] = useState('');
  const [genre1Code, setGenre1Code] = useState('');
  const [genre1Name, setGenre1Name] = useState('');
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [colorCodeFrom, setColorCodeFrom] = useState('');
  const [colorCodeTo, setColorCodeTo] = useState('');
  const [publisher, setPublisher] = useState('');
  const [previewImg, setPreviewImg] = useState('');
  const [imageFile, setImageFile] = useState('');
  const [imageProgress, setImageProgress] = useState('');
  const [attachableMedias, setAttachableMedias] = useState([]);

  const [enBookFile, setEnBookFile] = useState('');
  const [enBookProgress, setEnBookProgress] = useState('');
  const [viBookFile, setViBookFile] = useState('');
  const [viBookProgress, setViBookProgress] = useState('');

  const [statusStr, setStatusStr] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [waiting, setWaiting] = useState([true]);
  const notificationAlert = React.useRef();

  useEffect(() => {
    new CategoryStore().listBookGenres().then((data) => {
      setGenres1(data);
    });
    new BookGroupStore().list().then((data) => {
      setBookGroups(data);
    });
    new MediaFileStore().attachableMedias().then((data) => {
      setAttachableMedias(data);
    });
  }, []);

  function onCreateClick(e) {
    if(!waiting) {
      return;
    }
    if(!genre1Code) {
      setStatusStr(t('error.genres.required'));
      setError(true);
      return;
    }
    if(!imageFile) {
      setStatusStr(t('error.imagefile.required'));
      setError(true);
      return;
    }

    if(isSale()) {
      if(!salePrice) {
        setStatusStr(t('error.saleprice.required'));
        setError(true);
        return;
      }

      if(!printPrice) {
        setStatusStr(t('error.printprice.required'));
        setError(true);
        return;
      }
    }

    onImageFileUpload(e);
  }

  function isSale() {
    return genre1Code !== 'LOIBAIHAT' && genre1Code !== 'NGHILE';
  }

  async function onImageFileUpload(e) {
    setStatusStr(t('common.pls_wait'));
    e.preventDefault();
    setWaiting(false);
    const formData = new FormData();
    formData.append(
        "imageFile",
        imageFile,
        imageFile.name
    );
    await axios.post(process.env.REACT_APP_API_URL + "/uploadBookImageFile", formData, {
        onUploadProgress: data => setImageProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    );

    if(enBookFile) {
      await onEnBookFileUpload(e);
    }
    if(viBookFile) {
      await onViBookFileUpload(e);
    }
    createBook(e);
  }

  async function onEnBookFileUpload(e) {
    setStatusStr(t('common.pls_wait'));
    e.preventDefault();
    setWaiting(false);
    const formData = new FormData();
    formData.append(
        "bookFile",
        enBookFile,
        enBookFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/uploadEnBookFile", formData, {
        onUploadProgress: data => setEnBookProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    );
    return res.data === 'true';
  }

  async function onViBookFileUpload(e) {
    setStatusStr(t('common.pls_wait'));
    e.preventDefault();
    setWaiting(false);
    const formData = new FormData();
    formData.append(
        "bookFile",
        viBookFile,
        viBookFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/uploadViBookFile", formData, {
        onUploadProgress: data => setViBookProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    );
    return res.data === 'true';
  }

  function clean() {
    setGenre1Code('');
    setImageFile('');
    setPreviewImg('');
    setTitle('');
    setAuthor('');
    setSalePrice(0);
    setPrintPrice(0);
    setError(false);
    setImageProgress(0);
    setViBookProgress(0);
    setEnBookProgress(0);
  }

  function onResetClick(e) {
    clean();
    setStatusStr('');
  }

  function createBook() {
    axios.post(process.env.REACT_APP_API_URL + "/createBook",
      {
        "title": title,
        "description": description,
        "author": author,
        "genre1Code": genre1Code,
        "salePrice": salePrice,
        "printPrice": printPrice,
        "publisher": publisher,
        "colorCodeFrom": colorCodeFrom,
        "colorCodeTo": colorCodeTo,
        "bookGroup": bookGroup,
        "hasEnBook": enBookFile !== '',
        "hasViBook": viBookFile !== '',
        "mediaIds": mediaIds
      },
      {headers: new HttpUtils().getHeaders()}).then((res) => {
      if(res.data) {
        clean();
        setSuccess(true);
        setStatusStr(t('books.created_book'));
        new NotiUtils().notify(notificationAlert, 'info', t('books.created_book'), 15);
      } else {
        setStatusStr(t('books.cannot_create_book'));
      }
      setWaiting(true);
    });
  }

  function onEnBookSelected(event) {
    setEnBookFile(event.target.files[0]);
  }

  function onViBookSelected(event) {
    setViBookFile(event.target.files[0]);
  }

  function onImageSelected(event) {
    setImageFile(event.target.files[0]);
    setPreviewImg(URL.createObjectURL(event.target.files[0]));

    let fileName = event.target.files[0].name;
    setTitle(fileName.replace('.jpg', ''));
  }

  function handlePublisher(event) {
    let selected = event.target.value;
    setPublisher(selected);
  }

  function handleColorCodeFrom(event) {
    let selected = event.target.value;
    setColorCodeFrom(selected);
  }

  function handleColorCodeTo(event) {
    let selected = event.target.value;
    setColorCodeTo(selected);
  }

  function handlePrintPrice(event) {
    let selected = event.target.value;
    setPrintPrice(parseInt(selected));
  }

  function handleAuthor(event) {
    let selected = event.target.value;
    setAuthor(selected);
  }

  function handleSalePrice(event) {
    let selected = event.target.value;
    setSalePrice(parseInt(selected));
  }

  function handleGenre1Change(genre1) {
    setGenre1Code(genre1.value);
    setGenre1Name(genre1.label);
  }

  function handleBookGroupChange(group) {
    setBookGroup(group.value);
  }

  function handleDescription(event) {
    let selected = event.target.value;
    setDescription(selected);
  }

  function handleTitle(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function CreateButton(props) {
    return <Button className="btn-round" color="primary" type="button" onClick={onCreateClick}>
      {props.waiting && t("books.create_book")} {!props.waiting && <Spinner size="sm">t("common.pls_wait")</Spinner>}
    </Button>;
  }

  function getGenreList() {
    return genres1.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function getBookGroup() {
    return bookGroups.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  return (
    <fieldset disabled={!waiting}>
      <NotificationAlert ref={notificationAlert} />
      <div className="content">
        <Row>
          <Col md="4">
            <Card className="card-user">
              <CardBody>
                <div className="image2">
                  {previewImg && <img alt="..." src={previewImg} />}
                </div>
              </CardBody>
              <CardFooter>
                <div className="author2">
                  {title}
                </div>
                {genre1Name && <p className="text-center">
                  <b>Sách > </b>
                  <b>{genre1Name}</b>
                </p>}
              </CardFooter>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">{t("books.create_new_book")}</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row><Col md="12"><div className={"blockquote blockquote-warning"}> Trường thông tin có dấu <span style={{color: "red"}}> * </span> bắt buộc phải nhập</div></Col></Row>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>{t("books.browse_image")} <span style={{color: "red"}}> * </span></label>
                        <Input
                          placeholder={t('books.select_image_to_upload')}
                          type="file" accept="image/jpg"
                          onChange={onImageSelected}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t("books.browse_vi_pdf")}</label>
                        <Input
                          placeholder={t('books.select_vi_pdf_to_upload')}
                          type="file" accept=".pdf"
                          onChange={onViBookSelected}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <span style={{display: genre1Code === 'LOIBAIHAT' || genre1Code === 'NGHILE' ? "none" : "block"}}>
                        <FormGroup>
                          <label>{t("books.browse_en_pdf")}</label>
                          <Input
                            placeholder={t('books.select_en_pdf_to_upload')}
                            type="file" accept=".pdf"
                            onChange={onEnBookSelected}
                          />
                        </FormGroup>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>{t("books.upload_image_progress")}</label>
                        <Progress value={imageProgress} text="0%">{imageProgress < 100 ? "uploading" : "done"}</Progress>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>{t("books.upload_vi_book_progress")}</label>
                        <Progress
                          value={viBookProgress}
                        > {viBookProgress < 100 ? "uploading" : "done"} </Progress>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>{t("books.upload_en_book_progress")}</label>
                        <Progress
                          value={enBookProgress}
                        > {enBookProgress < 100 ? "uploading" : "done"} </Progress>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-3" md="4">
                      <FormGroup>
                        <label>Danh mục sách <span style={{color: "red"}}> * </span></label>
                        <Select options={getGenreList()} onChange={handleGenre1Change}/>
                        <label>Chọn <b>Tổng Hợp</b> nếu không biết mục nào</label>
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Chọn bộ sách</label>
                        <Select options={getBookGroup()} onChange={handleBookGroupChange}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>{t("books.title")} <span style={{color: "red"}}> * </span></label>
                        <Input
                          placeholder={t('books.input_title')}
                          onChange={handleTitle}
                          type="text" defaultValue={title}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>{t("books.book_desc")}</label>
                        <Input type="textarea" placeholder={t('books.input_book_desc')} onChange={handleDescription}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>{t("books.author")}</label>
                        <Input
                          placeholder={t('books.input_author')}
                          onChange={handleAuthor}
                          type="text" defaultValue={author}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <span style={{display: isSale() ? "block" : "none"}}>
                        <FormGroup>
                          <label>{t("books.print_price")} <span style={{color: "red"}}> * </span></label>
                          <Input
                            onChange={handlePrintPrice}
                            placeholder={t('books.input_print_price')}
                            type="text" defaultValue={printPrice}
                          />
                        </FormGroup>
                      </span>
                    </Col>
                    <Col className="pr-1" md="4">
                      <span style={{display: isSale() ? "block" : "none"}}>
                        <FormGroup>
                          <label>{t("books.sale_price")} <span style={{color: "red"}}> * </span></label>
                          <Input
                            onChange={handleSalePrice}
                            placeholder={t('books.input_sale_price')}
                            type="text" defaultValue={salePrice}
                          />
                        </FormGroup>
                      </span>
                    </Col>
                    {isSale() &&
                    <Col md="4">
                      <FormGroup>
                        <label>Nhà xuất bản</label>
                        <Input
                          onChange={handlePublisher}
                          placeholder={"Nhập nhà xuất bản nếu có"}
                          type="text" defaultValue={publisher}
                        />
                      </FormGroup>
                    </Col>}
                  </Row>
                  {!isSale() &&
                  <Row>
                    <Col className="pr-1" md="9">
                      <FormGroup>
                        <label>{t("books.attach_music")}</label>
                        <MediaSuggestion callback={(ids) => {setMediaIds(ids);}}
                          attachableMedias={attachableMedias}/>
                      </FormGroup>
                    </Col>
                  </Row>}
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Mã màu hex bắt đầu</label>
                        <Input
                            onChange={handleColorCodeFrom}
                            placeholder={"Mã màu hex bắt đầu"}
                            type="text" defaultValue={colorCodeFrom}
                          />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="4">
                      <FormGroup>
                        <label>Mã màu hex kết thúc</label>
                        <Input
                            onChange={handleColorCodeTo}
                            placeholder={"Mã màu hex kết thúc"}
                            type="text" defaultValue={colorCodeTo}
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      {statusStr && <div className={success ? "blockquote blockquote-info" : "blockquote blockquote-danger"}>
                        <p>
                          {error && <b className="text-danger">{t('common.error')}:</b>} <label className={success ? "text-info" : "text-danger"}><b>{statusStr}</b></label>
                          {success && <a href="/admin/books" className="text-danger"><br/><b>Xem danh sách sách</b></a>}
                        </p>
                      </div>}
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <CreateButton waiting={waiting}/>
                      <Button className="btn-round" type="button" onClick={onResetClick}>
                        {t("books.clear")}
                      </Button>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </fieldset>
  );
}

export default BookNew;
