import React from "react";

import {
  Button
} from "reactstrap";

function PdfViButton(props) {
  function handleAction(event) {
    window.open(props.url, '_blank', 'noreferrer');
  }

  return <span style={{cursor: "pointer"}}>
    <span onClick={handleAction} >
      <Button className="btn-round btn-icon" color="info" outline size="sm">
        <i className="fa fa-file-pdf" />
      </Button>{props.title}
    </span>
  </span>;
}

export default PdfViButton;
