import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Switch from "react-switch";

import CategoryStore from "../category/category-store.js";
import BookSloganSearchDto from "./bookslogan-search-dto.js";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import BookSloganStore from "./bookslogan-store.js";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

let store = new CategoryStore();

function BookSloganSearch(props) {
  const { t } = useTranslation();

  const [slogan, setSlogan] = useState('');
  const [id, setId] = useState('');
  const [type, setType] = useState('');

  function handleSloganChange(event) {
    setSlogan(event.target.value);
  }

  function handleIdChange(event) {
    setId(event.target.value);
  }

  function handleSearch(event) {
    props.showProgressingFn();
    let searchReq = new BookSloganSearchDto({
      "ids": id,
      "slogan": slogan,
      "type": 2
    });
    props.store.search(searchReq).then((data) => props.callback(data, searchReq));
  }

  return (
    <>
      <Row>
        <Col className="pl-3" md="1">
          <FormGroup>
            <label>ID</label>
            <Input placeholder="Id" type="text" onChange={handleIdChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>{t('bookslogans.slogan')}</label>
            <Input placeholder={t('bookslogans.slogan')} type="text" onChange={handleSloganChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary" type="submit">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default BookSloganSearch;
