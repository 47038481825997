import React, { useEffect, useState } from "react";
import { useSearchParams, useMatch, Outlet, useNavigate } from "react-router-dom";
import BookStore from "./book-store.js";
import BookSearchDto from "./book-search-dto.js";
import BookView from "./book-view.js";
import BookSearch from "./books-search.js";
import BookPaging from "./books-paging.js";
import NotificationAlert from "react-notification-alert";
import { useTranslation } from 'react-i18next';
import MediaFileStore from "../file/mediafile-store.js";
import CategoryStore from "../category/category-store.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new BookStore();

function Books() {
  const { t } = useTranslation();
  const notificationAlert = React.useRef();
  const [searchReq, setSearchReq] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPage, setSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [attachableMedias, setAttachableMedias] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    search(page);
    new MediaFileStore().attachableMedias().then((data) => {
      setAttachableMedias(data);
    });
    new CategoryStore().listBookGenres();
  }, [navigate]);

  function search(page) {
    let urlIds = searchParams.get('ids');
    let request;
    if(searchReq) {
      request = new BookSearchDto({
        "ids": urlIds,
        "pageNo": page,
        "title": searchReq.title,
        "genre1Id": searchReq.genre1Id,
        "inStock": searchReq.inStock,
        "bookGroup": searchReq.bookGroup,
        "pdfOption": searchReq.pdfOption
      });
    } else {
      request = new BookSearchDto({
        "ids": urlIds,
        "pageNo": page
      });
    }
    store.search(request).then((data) => {
      setSearchPage(data);
      setLoaded(true);
    });
  }
  
  return (
    <>
      <div className="content">
        <Outlet />
        <NotificationAlert ref={notificationAlert} />
        <span className={Boolean(useMatch("/admin/books/new")) ? "hide" : undefined}>
          <BookSearch store={store} showProgressingFn={()=> setLoaded(false)} callback={(data, searchReq) => {setLoaded(true); setSearchPage(data); setSearchReq(searchReq);}}/>
          <span className={loaded ? "hide" : undefined}>
            <Row>
              <Col>
                <Progress animated color="danger" style={{height: "5px"}} striped value={100}/>
              </Col>
            </Row>
          </span>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle  tag="h4">{t('books.book_list')}</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>{t("common.image")}</th>
                        <th>{t("books.common_info")}</th>
                        <th>Phân loại</th>
                        <th>{t("books.price_selling")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchPage?.content?.map(item => (
                        <BookView
                          id={item.id}
                          attachableMedias={attachableMedias}
                          notiRef={notificationAlert}
                          key={item.id} data={item}
                          showProgressingFn={()=> setLoaded(false)}
                          refresh={() => search(page)}/>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <BookPaging searchPage={searchPage} callback={(newPage) => {search(newPage);setPage(newPage);}}/>
        </span>
      </div>
    </>
  );
}

export default Books;
