import axios from "axios";
import BookQuoteSearchDto from "./bookquote-search-dto.js";
import HttpUtils from "../../utils/http-utils.js";

class BookQuoteStore {
  search = async function(searchParams: BookQuoteSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/bookquotes/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(bookQuoteId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/bookquotes/" + bookQuoteId,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  view = async function(quoteId: int, voice) {
    return await axios
        .get(process.env.REACT_APP_API_URL + "/bookquotes/" + quoteId + "/view/" + voice,
          { headers: new HttpUtils().getHeaders()});
  }

  update = async function(quote) {
    await axios
      .post(process.env.REACT_APP_API_URL + "/bookquotes", quote, { headers: new HttpUtils().getHeaders()});
  }

  remove = async function(bookQuoteId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/bookquotes/" + bookQuoteId + "/remove", {},
          { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("remove -" + bookQuoteId);
        });
  }
}

export default BookQuoteStore;