import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";

import HomeNavbar from "widget/home-navbar.js";
import Footer from "widget/footer.js";
import Sidebar from "widget/sidebar.js";
import '../i18n.js';

import adminRoutes, { mediaRoutes, bookRoutes, uploadRoutes } from "routes.js";

var ps;

function AdminLayout(props) {
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const mainPanel = React.useRef();
  const location = useLocation();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    mainPanel.current.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [location]);
  let role = localStorage.getItem('role');
  let routes = [];
  if(role === 'ADMIN') {
    routes = adminRoutes;
  } else if(role === 'BOOK_ADMIN') {
    routes = bookRoutes;
  } else if(role === 'MEDIA_ADMIN') {
    routes = mediaRoutes;
  } else if(role === 'UPLOAD_ADMIN') {
    routes = uploadRoutes;
  }

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routes}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel" ref={mainPanel}>
        <HomeNavbar {...props} />
        <Routes>
          {routes.map((prop, key) => {
            return (
              <Route path={prop.path} element={prop.component} key={key}>
                {prop.routes && prop.routes.map((prop2, key2) => {
                  return (
                    <Route path={prop2.path} key={key2} element={prop2.component} replace/>
                  );
                })}
              </Route>
            );
          })}
        </Routes>
        <Footer fluid />
      </div>
    </div>
  );
}

export default AdminLayout;
