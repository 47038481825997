import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoginStore from "./login-store.js";
import LoginDto from "./login-dto.js";

import NotificationAlert from "react-notification-alert";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Spinner,
  NavLink,
  Col,
} from "reactstrap";

let store = new LoginStore();

function Login() {
  const notificationAlert = React.useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  function handleClick(event) {
    let loginReq = new LoginDto({
      "email": email,
      "password": password
    });
    setProcessing(true);

    store.login(loginReq).then((data) => {
      if(data && data.access_token) {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('role', data.role);
        if(data.role === 'ADMIN') {
          navigate('/admin/dashboard');
        } else if(data.role === 'BOOK_ADMIN') {
          navigate('/admin/books');
        } else if(data.role === 'MEDIA_ADMIN') {
          navigate('/admin/media');
        }  else if(data.role === 'UPLOAD_ADMIN') {
          navigate('/admin/media');
        }
      } else {
        setProcessing(false);
        notify("warning", "Wrong username or password");
      }
    });
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handlePwdChange(event) {
    setPassword(event.target.value);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPassword(event.target.value);
      handleClick(event);
    }
  };

  const notify = (type, msg) => {
    var type = type;
    var options = {};
    options = {
      place: "tr",
      message: msg,
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <Row>
          <Col md="4">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">LOGIN</CardTitle>
              </CardHeader>
              <CardBody>
                <Form action="perform_login" method="POST">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Username</label>
                        <Input type="text" name="username" onChange={handleEmailChange}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Password</label>
                        <Input type="password" name="password" onChange={handlePwdChange} onKeyDown={handleKeyDown}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup check>
                        <Label check>
                          <Input type="checkbox" name="remember-me" />{' '}
                          Remember me
                          <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md="6" className="text-right">
                      <NavLink href="">Forget your password ?</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <span style={{display: !processing ? "block" : "none"}}>
                        <Button onClick={handleClick} className="btn-round show" color="primary" type="button">
                          Login
                        </Button>
                      </span>
                    </div>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <span style={{display: processing ? "block" : "none"}}><Spinner color="primary"></Spinner></span>
                    </div>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col md="4"/>
        </Row>
      </div>
    </>
  );
}

export default Login;
