import axios from "axios";
import HttpUtils from "utils/http-utils.js";

class UamStore {
  searchRole = async function(searchParams: RoleSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/roles/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  searchPermission = async function(searchParams: PermissionSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/permissions/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }
}

export default UamStore;