class CategorySearchDto {
  categoryName;
  genreName;

  constructor(data) {
    this.categoryName = data?.categoryName;
    this.genreName = data?.genreName;
  }
}

export default CategorySearchDto;
