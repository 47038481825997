import React, { useEffect, useState } from "react";
import AccountSearchDto from "./account-search-dto.js";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap";

function AccountSearch(props) {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handleUsernameChange(event) {
    setUsername(event.target.value);
  }

  function handleSearch(event, callback) {
    let searchReq = new AccountSearchDto({
      "username": username,
      "email": email
    });
    props.store.search(searchReq).then(props.callback);
  }

  return (
    <>
      <Row className="col-md-12">
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>{t('admin.accounts.username')}</label>
            <Input placeholder={t('admin.accounts.username')} type="text" onChange={handleUsernameChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>{t('admin.accounts.email')}</label>
            <Input placeholder={t('admin.accounts.email')} type="text" onChange={handleEmailChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
        <Col md="3"></Col>
      </Row>
    </>
  );
}

export default AccountSearch;
