import React, { useEffect, useState } from "react";
import OrderStore from "./order-store.js";
import OrderSearchDto from "./order-search-dto.js";
import OrderItem from "./order-item.js";
import OrderListSearch from "./orders-search.js";
import OrderPaging from "./orders-paging.js";
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new OrderStore();

function OrderList() {
  const { t } = useTranslation();
  const [searchPage, setSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    search(0);
  }, []);

  function search(page) {
    let searchReq = new OrderSearchDto({
      "pageNo": page
    });
    store.search(searchReq).then((data) => {
      setSearchPage(data);
      setLoaded(true);
    });
  }

  return (
    <>
      <div className="content">
        <OrderListSearch store={store} callback={(data) => {setSearchPage(data);}}/>
        <span className={loaded  ? "hide" : undefined}>
          <Row>
            <Col>
              <Progress animated color="info" striped value={100}/>
            </Col>
          </Row>
        </span>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{t('orders.list')}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive hover>
                  <thead className="text-primary">
                    <tr>
                      <th>{t('orders.order_no')}</th>
                      <th>{t('orders.date')}</th>
                      <th>{t('orders.item_list')}</th>
                      <th>{t('orders.shipping_info')}</th>
                      <th>{t('orders.status')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchPage?.content?.map(item => (
                        <OrderItem key={item.id} data={item} store={store}/>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <OrderPaging searchPage={searchPage} callback={(newPage) => search(newPage)}/>
      </div>
    </>
  );
}

export default OrderList;
