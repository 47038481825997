import React from 'react';
import {
  Player, LoadingSpinner, BigPlayButton, ForwardControl, ReplayControl, ControlBar }
from 'video-react';

function MediaViewer(props) {
  return (
    <>
      <Player src={props.viewLink}>
        <LoadingSpinner />
        <BigPlayButton position="center" />
        <ControlBar>
          <ReplayControl seconds={10} order={2.2} />
          <ForwardControl seconds={10} order={3.2} />
        </ControlBar>
      </Player>
    </>
  );
}

export default MediaViewer;
