import React, { useState } from "react";
import PureModal from 'react-pure-modal';
import OrderDto from "./order-dto.js";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Row
} from "reactstrap";

function OrderItem(props) {
  const { t } = useTranslation();

  const [completeModal, setCompleteModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  function handleFinishedStatus(event) {
    let updateReq = new OrderDto({
      "id": props.data.id,
      "status": 'FINISHED'
    });
    console.log(updateReq.id + " -> " + updateReq.title);

    props.store.update(updateReq);
  }

  function handleCanceledStatus(event) {
    let updateReq = new OrderDto({
      "id": props.data.id,
      "status": 'CANCELED'
    });
    console.log(updateReq.id + " -> " + updateReq.title);

    props.store.update(updateReq);
  }

  function CompleteStatusButton(props) {
    if (props.finished) {
      return "";
    }
    return <div onClick={() => setCompleteModal(true)} style={{cursor: "pointer"}}>
      <Button className="btn-round btn-icon" color="success" outline size="m"><i className="fa fa-check" /></Button> HOÀN THÀNH
    </div>;
  }

  function CancelStatusButton(props) {
    if (props.finished) {
      return "";
    }
    return <div onClick={() => setCancelModal(true)} style={{cursor: "pointer"}}>
      <Button className="btn-round btn-icon" color="danger" outline size="m"><i className="fa fa-ban" /></Button> HUỶ ĐƠN
    </div>;
  }

  return (
    <>
      <tr>
        <td width="15%">{props.data.orderNo}</td>
        <td width="10%">{props.data.date}</td>
        <td width="30%">
          {props.data.itemList.map((item, index) => {
            return (
              <div key={index}>
                <span style={{color: "green"}}>{item.title.toUpperCase()}</span>. Đơn giá: {item.price.toLocaleString()} VND. Số lượng: {item.quantity}
              </div>
            );
          })}
          <hr/>
          TOTAL: <b>{props.data.totalPrice.toLocaleString()}</b> VND
        </td>
        <td width="35%">
          <b>{t('orders.buyer').toUpperCase()}</b><br/>
          {props.data.orderAddress.buyerName.toUpperCase()}<br/>
          <span style={{color: "red"}}>{props.data.orderAddress.buyerPhone}</span><br/><br/>
          <b>{t('orders.receiver').toUpperCase()}</b><br/>
          {props.data.orderAddress.receiverName.toUpperCase()}<br/>
          {props.data.orderAddress.receiverAddress}<br/>
          <span style={{color: "red"}}>{props.data.orderAddress.receiverPhone}</span><br/>
          {props.data.paymentType === 'CASH' ? 'Thanh toán khi nhận hàng' : <span style={{color: "red"}}>ĐÃ THANH TOÁN QUA VNPAY</span>}
        </td>
        <td width="10%">{props.data.status === 'PAID' ? 'Waiting' : props.data.status}</td>
        <td width="15%">
          <PureModal header="Please confirm"
            footer={
              <div>
                <button onClick={() => setCompleteModal(false)}>Cancel</button>
                <button onClick={handleFinishedStatus}>Yes</button>
              </div>
            }
            isOpen={completeModal}
            onClose={() => setCompleteModal(false)}
          >
            <p>Are you sure you want to COMPLETE?</p>
          </PureModal>
          <PureModal header="Please confirm"
            footer={
              <div>
                <button onClick={() => setCancelModal(false)}>Cancel</button>
                <button onClick={handleCanceledStatus}>Yes</button>
              </div>
            }
            isOpen={cancelModal}
            onClose={() => setCancelModal(false)}
          >
            <p>Are you sure you want to CANCEL?</p>
          </PureModal>
          <Row>
            <CompleteStatusButton finished={props.data.status === 'FINISHED' || props.data.status === 'CANCELED'}/>
            <CancelStatusButton finished={props.data.status === 'FINISHED' || props.data.status === 'CANCELED'}/>
          </Row>
        </td>
      </tr>
    </>
  );
}

export default OrderItem;