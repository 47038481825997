import axios from "axios";
import BookSloganSearchDto from "./bookslogan-search-dto.js";
import HttpUtils from "../../utils/http-utils.js";

class BookSloganStore {
  search = async function(searchParams: BookSloganSearchDto) {
    let response = await axios.post(process.env.REACT_APP_API_URL + "/bookslogans/search", searchParams,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  get = async function(sloganId: int) {
    let response = await axios.get(process.env.REACT_APP_API_URL + "/bookslogans/" + sloganId,
            { headers: new HttpUtils().getHeaders()});
    return response.data;
  }

  view = async function(sloganId: int, voice) {
    return await axios
        .get(process.env.REACT_APP_API_URL + "/bookslogans/" + sloganId + "/view/" + voice,
          { headers: new HttpUtils().getHeaders()});
  }

  update = async function(slogan) {
    await axios
      .post(process.env.REACT_APP_API_URL + "/bookslogans", slogan, { headers: new HttpUtils().getHeaders()});
  }

  remove = async function(sloganId: int) {
    await axios
        .post(process.env.REACT_APP_API_URL + "/bookslogans/" + sloganId + "/remove", {},
          { headers: new HttpUtils().getHeaders()})
        .then((res) => {
          console.log("remove -" + sloganId);
        });
  }
}

export default BookSloganStore;