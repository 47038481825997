import React, { useEffect, useState } from "react";

import NotifyButton from "./notify-button.js";
import SetupNotifyButton from "./setup-notify-button.js";
import DisableNotifyButton from "./disable-notify-button.js";
import SwitchModeButton from "./switch-mode-button.js";
import CopyLinkButton from "./copy-link-button.js";

import {
  Progress, Badge
} from "reactstrap";

function Title(props) {
  const [listening, setListening] = useState(false);
  const [data, setData] = useState({
    progress: props.data.progress,
    queueStatusStr: props.data.queueStatusStr
  });

  return <div>
    <b>{props.data.title}</b><br/>
    {props.data.queueStatus && (props.data.queueStatus !== 'ACTIVE' && props.data.queueStatus !== 'MISSED') && <Progress animated size="sm" color="warning"
        style={{
          height: '15px'
        }}
        value={data.progress}>
    </Progress>}
    {props.data.appSource == 'phapquang' && <Badge color="primary">{props.data.appSource.toUpperCase()}</Badge>}
    {props.data.appSource == 'chanhphap' && <Badge color="warning">{props.data.appSource.toUpperCase()}</Badge>}<br/>
    {props.data.requestNotify && <b>Có thông báo cho người dùng biết <br/></b>}
    {props.data.processingTime && <> Đã xử lý: <b>{props.data.processingTime}</b><br/></> }
    {props.data.processingStatus && <>Chi tiết: {props.data.processingStatus}. {props.data.resolutionDurations}<br/></> }
    {props.data.queueStatus && props.data.queueStatus !== 'ACTIVE' && <p className="text-center">{data.queueStatusStr}</p>}
    <hr/>
    {props.data.status === 'ACTIVE' && <><NotifyButton id={props.data.id} title={props.data.title} btnTitle="Gửi Thông Báo"/> <br/></>}
    {props.data.status === 'ACTIVE' && <><SetupNotifyButton id={props.data.id} btnTitle="Cài đặt ngày giờ thông báo" /> <br/></>}
    {props.data.requestNotify && <><DisableNotifyButton id={props.data.id} title={props.data.title}
      callback={() => {props.data.requestNotify = '';}}
      notifyTime={props.data.notifyTime}
      publishTime={props.data.publishTime}/><br/></>}
  </div>;
}
export default Title;
