class RoleSearchDto {
  name;
  code;

  constructor(data) {
    this.name = data?.name;
    this.code = data?.code;
  }
}

export default RoleSearchDto;
