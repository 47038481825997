import React, { useEffect, useState } from "react"; 
import UamStore from "./uam-store.js";
import PermissionSearchDto from "./permission-search-dto.js";
import PermissionItem from "./permission-item.js";
import PermissionSearch from "./permission-search.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new UamStore();

function PermissionList() {
  const [permissionSearchPage, setPermissionSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    let searchReq = new PermissionSearchDto();
    store.searchPermission(searchReq).then((data) => {
      setPermissionSearchPage(data);
      setLoaded(true);
    });
  }, []);
  
  return (
    <>
      <PermissionSearch store={store} callback={(data) => {setPermissionSearchPage(data);}}/>
      <span style={{display: loaded ? "none" : "block"}}>
        <Row>
          <Col>
            <Progress animated color="info" striped value={100}/>
          </Col>
        </Row>
      </span>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">PERMISSIONS LIST</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>ID</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {permissionSearchPage?.content?.map(item => (
                    <PermissionItem id={item.id} key={item.id} data={item} store={store}/>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default PermissionList;
