import React, { useState, useEffect } from "react";
import axios from "axios";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Switch from "react-switch";
import Select from 'react-select';
import BookSuggestion from "./component/book-suggestion.js";
import BookQuoteStore from "./bookquote-store.js";
import BookStore from "../book/book-store.js";
import AlbumStore from "../album/album-store.js";

import PureModal from 'react-pure-modal';
import UndoButton from "component/undo-button.js";
import SaveButton from "component/save-button.js";

import HttpUtils from "utils/http-utils.js";
import NotiUtils from "utils/noti-utils.js";
import { useTranslation } from 'react-i18next';
import { Gallery } from "react-grid-gallery";
import 'quill/dist/quill.snow.css';
import { useQuill } from 'react-quilljs';
import BookQuoteDetails from "./component/bookquote-details.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function BookQuoteEdit(props) {
  const { t } = useTranslation();
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],

        [{ list: 'ordered'}, { list: 'bullet' }],
        [{ indent: '-1'}, { indent: '+1' }],

        [{ color: [] }, { background: [] }],
      ],
    }
  });

  const [quote, setQuote] = useState(props.data.quote);
  const [quoteDetails, setQuoteDetails] = useState(props.data.quoteDetails);
  const [fromPage, setFromPage] = useState(props.data.fromPage);
  const [toPage, setToPage] = useState(props.data.toPage);
  const [language, setLanguage] = useState(props.data.language);
  const [bookId, setBookId] = useState(props.data.bookId);
  const [bookName, setBookName] = useState(props.data.bookName);
  const [source, setSource] = useState(props.data.source);
  const [title, setTitle] = useState(props.data.title);
  const [bgImage, setBgImage] = useState('');

  const [bgImageSrc, setBgImageSrc] = useState(props.data.bgImageSrc);
  const [bgImageId, setBgImageId] = useState(props.data.bgImageId);

  const [result, setResult] = useState('');
  const [notifyModal, setNotifyModal] = useState(false);

  let store = new AlbumStore();

  function handleFromPage(event) {
    let selected = event.target.value;
    setFromPage(selected);
  }

  function handleToPage(event) {
    let selected = event.target.value;
    setToPage(selected);
  }

  function handleLanguage(event) {
    let selected = event.target.value;
    setLanguage(selected);
  }

  function handleQuote(event) {
    let selected = event.target.value;
    setQuote(selected);
  }

  function validate() {
    let changedTitle = props.data.title !== title;
    let changedSource = props.data.source !== source;
    let changedBgImage = props.data.bgImageId !== bgImageId;
    let changedQuote = props.data.quote !== quote;
    let changedQuoteDetails = props.data.quoteDetails !== quoteDetails;
    let changedFromPage = props.data.fromPage !== fromPage;
    let changedToPage = props.data.toPage !== toPage;
    let changedLanguage = props.data.language !== language;
    if(changedQuote || changedQuoteDetails || changedFromPage || changedToPage || changedLanguage
      || changedTitle || changedSource || changedBgImage) {
      setNotifyModal(true);
    } else {
      new NotiUtils().notify(props.notiRef, 'info', "Nothing changed to update");
    }
  }

  function onUpdate(e) {
    setNotifyModal(false);
    props.onUpdate({
      'id': props.data.id,
      'quote': quote,
      'quoteDetails': quoteDetails,
      'language': language,
      'fromPage': fromPage,
      'toPage': toPage,
      "title": title,
      "source": source,
      'bookId': bookId,
      'bookName': bookName,
      'bgImageId': bgImage.id,
      'bgImageSrc': bgImage.src
    });
    updateSrc(props.data);
    props.onReadonly();
    new NotiUtils().notify(props.notiRef, 'info', t('bookquotes.updated_bookquote'));
  }

  function updateSrc(src) {
    src.quote = quote;
    src.fromPage = fromPage;
    src.toPage = toPage;
    src.language = language;
    src.bookId = bookId;
    src.bookName = bookName;
    src.source = source;
    src.title = title;
    src.bgImageSrc = bgImageSrc;
  }

  function handleTitleChange(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function handleSourceChange(event) {
    let selected = event.target.value;
    setSource(selected);
  }

  function handleBgImageClick(index) {
    setBgImage(props.quoteBgImages[index]);
    setBgImageSrc(bgImage.src);
    setBgImageId(bgImage.id);
  }

  return (
    <div className={!props.show ? "hide" : undefined}>
      <Row>
        <Col md="4">
          <Card className="card-user">
            <CardBody>
              <div className="image2">
                {bgImageSrc && <img alt="..." src={bgImageSrc} />}
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="8">
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">{t('bookquotes.update_bookquote')}</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-2" md="8">
                    <FormGroup>
                      <label>Tiêu đề <span style={{color: "red"}}> * </span></label>
                      <Input
                          onChange={handleTitleChange}
                          type="text" defaultValue={title}
                        />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-3" md="12">
                    <FormGroup>
                      <label>{'Nội dung đầy đủ'}</label>
                      <BookQuoteDetails quoteDetails={props.data.quoteDetails} setQuoteDetails={(value) => setQuoteDetails(value)}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-2" md="12">
                      <FormGroup>
                        <label>{t("bookquotes.book")}</label>
                        <BookSuggestion
                          attachableBooks={props.attachableBooks}
                          callback={(data) => {setBookId(data[0].id);setBookName(data[0].title);}}
                          bookIds={[props.data.bookId]}/>
                      </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-2" md="4">
                    <FormGroup>
                      <label>Bài</label>
                      <Input
                        onChange={handleSourceChange}
                        type="text" defaultValue={source}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-2" md="4">
                    <FormGroup>
                      <label>{t("bookquotes.from_page")}</label>
                      <Input
                        placeholder={t('bookquotes.input_from_page')}
                        onChange={handleFromPage}
                        type="text" defaultValue={fromPage}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-3" md="4">
                    <FormGroup>
                      <label>{t("bookquotes.to_page")}</label>
                      <Input type="text" placeholder={t('bookquotes.input_to_page')}
                        defaultValue={toPage} onChange={handleToPage}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label>Chọn ảnh nền</label>
                      <Gallery rowHeight={70} enableImageSelection={false}
                        images={props.quoteBgImages}
                        onClick={handleBgImageClick} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-3" md="12">
                    <FormGroup>
                      <label className="title text-warning">{result}</label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="update ml-auto mr-auto">
                    <SaveButton callback={validate}/> &nbsp;
                    <UndoButton callback={(id) => {props.onReadonly();}}/>
                    <PureModal width="400px" header="Please confirm"
                      footer={
                       <div>
                         <button onClick={() => setNotifyModal(false)}>Cancel</button>
                         <button onClick={onUpdate}>Yes</button>
                       </div>
                      }
                      isOpen={notifyModal}
                      onClose={()=>setNotifyModal(false)}
                    >
                      <p>Are you sure you want to save new data?</p>
                      {props.data.quote !== quote ? <><p>Quote cũ: <b>{props.data.quote}</b><br/>Quote mới: <b>{quote}</b></p><hr/></>: undefined}
                    </PureModal>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BookQuoteEdit;
