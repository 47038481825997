import React, {useState} from "react";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { useTranslation } from 'react-i18next';

import {
  Button
} from "reactstrap";

function CopyLinkButton(props) {
  const { t } = useTranslation();
  const [notifyModal, setNotifyModal] = useState(false);

  return <span style={{cursor: "pointer"}}>
      <PureModal header="Link chia sẻ"
        footer={
         <div>
           <button onClick={() => setNotifyModal(false)}>Close</button>
         </div>
        }
        isOpen={notifyModal}
        onClose={()=>setNotifyModal(false)}
      >
        <p>{props.shareLink}</p>
      </PureModal>
    <span onClick={() => setNotifyModal(true)} >
      <Button className="btn-round btn-icon" color="warning" outline size="sm">
        <i className="fa fa-share" />
      </Button>{props.shareTittle}
    </span>
  </span>;
}

export default CopyLinkButton;
