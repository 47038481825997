import React, { useEffect, useState } from "react";
import { useSearchParams, useMatch, Outlet, useNavigate } from "react-router-dom";
import LibraryItemStore from "./libraryitem-store.js";
import AlbumStore from "../album/album-store.js";
import LibraryItemSearchDto from "./libraryitem-search-dto.js";
import LibraryItemView from "./libraryitem-view.js";
import LibraryItemSearch from "./libraryitems-search.js";
import LibraryItemPaging from "./libraryitems-paging.js";
import NotificationAlert from "react-notification-alert";
import { useTranslation } from 'react-i18next';
import BookStore from "../book/book-store.js";
import MediaFileStore from "../file/mediafile-store.js";
import CategoryStore from "../category/category-store.js";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new LibraryItemStore();

function LibraryItems() {
  const { t } = useTranslation();
  const notificationAlert = React.useRef();
  const [searchReq, setSearchReq] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPage, setSearchPage] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [attachableBooks, setAttachableBooks] = useState([]);
  const [attachableMedias, setAttachableMedias] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    new BookStore().attachableBooks().then((data) => {
      setAttachableBooks(data);
      search(page);
    });
    new MediaFileStore().attachableCpMedias().then((data) => {
      setAttachableMedias(data);
    });
  }, [navigate]);

  function search(page) {
    let urlIds = searchParams.get('ids');
    let request;
    if(searchReq) {
      request = new LibraryItemSearchDto({
        "ids": urlIds,
        "pageNo": page,
        "type": searchReq.type,
        "title": searchReq.title,
        "bookId": searchReq.bookId
      });
    } else {
      request = new LibraryItemSearchDto({
        "ids": urlIds,
        "pageNo": page
      });
    }
    store.search(request).then((data) => {
      setSearchPage(data);
      setLoaded(true);
    });
  }
  
  return (
    <>
      <div className="content">
        <Outlet />
        <NotificationAlert ref={notificationAlert} />
        <span className={Boolean(useMatch("/admin/library/new")) ? "hide" : undefined}>
          <LibraryItemSearch store={store}
            showProgressingFn={()=> setLoaded(false)}
            callback={(data, searchReq) => {setLoaded(true); setSearchPage(data); setSearchReq(searchReq);}}/>
          <span className={loaded ? "hide" : undefined}>
            <Row>
              <Col>
                <Progress animated color="danger" style={{height: "5px"}} striped value={100}/>
              </Col>
            </Row>
          </span>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">DANH SÁCH</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th>ID</th>
                        <th>Loại</th>
                        <th>Ảnh</th>
                        <th></th>
                        <th>Sách / Media</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchPage?.content?.map(item => (
                        <LibraryItemView
                          id={item.id}
                          attachableBooks={attachableBooks}
                          attachableMedias={attachableMedias}
                          notiRef={notificationAlert}
                          key={item.id} data={item}
                          showProgressingFn={()=> setLoaded(false)}
                          refresh={() => search(page)}/>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <LibraryItemPaging searchPage={searchPage} callback={(newPage) => {search(newPage);setPage(newPage);}}/>
        </span>
      </div>
    </>
  );
}

export default LibraryItems;
