import React, { useState } from "react";
import UserSearchDto from "./user-search-dto.js";
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input
} from "reactstrap";

function UserSearch(props) {
  const { t } = useTranslation();

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [source, setSource] = useState('');
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  function handlePhone(event) {
    setPhone(event.target.value);
  }

  function handleEmail(event) {
    setEmail(event.target.value);
  }

  function handleName(event) {
    setName(event.target.value);
  }

  function getSourceList() {
    return [
      {
        value: 'phapquang',
        label: 'PHÁP QUANG'
      },
      {
        value: 'chanhphap',
        label: 'CHÁNH PHÁP'
      }
    ];
  }

  function handleSourceChange(select) {
    setSource(select.value);
  }

  function handleSearch(event, callback) {
    let searchReq = new UserSearchDto({
      "phone": phone,
      "email": email,
      "name": name,
      "source": source,
      "fromDate": fromDate,
      "toDate": toDate
    });
    props.store.search(searchReq).then(props.callback);
  }

  let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
  };

  return (
    <>
      <Row>
        <Col className="pr-3" md="2">
          <FormGroup>
            <label>{t('users.from_date')}</label>
            <DatePicker selected={fromDate} className="form-control"
                      onChange={(date) => setFromDate(date)}
                      dateFormat="dd-MM-yyyy"
                      timeClassName={handleColor}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="2">
          <FormGroup>
            <label>{t('users.to_date')}</label>
            <DatePicker selected={toDate} className="form-control"
                      onChange={(date) => setToDate(date)}
                      dateFormat="dd-MM-yyyy"
                      timeClassName={handleColor}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="2">
          <FormGroup>
            <label>{t('users.phone')}</label>
            <Input placeholder={t('users.phone')} type="text" onChange={handlePhone}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="2">
          <FormGroup>
            <label>{t('users.email')}</label>
            <Input placeholder={t('users.email')} type="text" onChange={handleEmail}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="2">
          <FormGroup>
            <label>Ứng dụng</label>
            <Select options={getSourceList()} onChange={handleSourceChange}/>
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary">
              {t('common.search')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default UserSearch;
