import {
  Button,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

function SaveButton(props) {
  const { t } = useTranslation();

  return <span>
    <Button onClick={() => props.callback()} className="btn-round"
      outline size="sm" color="success"> <i className="fa fa-check" /> {t('common.save')}
    </Button>
  </span>;
}

export default SaveButton;
