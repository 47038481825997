import React, { useEffect, useState } from "react";
import ConfigSearchDto from "./config-search-dto.js";
import { useNavigate } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
 
function ConfigSearch(props) {
  const [code, setCode] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  function handleCodeChange(event) {
    setCode(event.target.value);
  }

  function handleNameChange(event) {
    setName(event.target.value);
  }

  function handleSearch(event, callback) {
    let searchReq = new ConfigSearchDto({
      "code": code,
      "name": name
    });
    props.store.search(searchReq).then(props.callback);
  }

  function handleNew(event) {
     navigate('/admin/Config-details');
  }

  return (
    <>
      <Row className="col-md-12">
        <Col className="pl-1" md="3">
          <FormGroup>
            <label>Code</label>
            <Input placeholder="Code" type="text" onChange={handleCodeChange}/>
          </FormGroup>
        </Col>
        <Col className="pl-1" md="2">
          <FormGroup>
            <label></label>
            <Button onClick={handleSearch} className="btn-round form-control" color="primary">
              Search
            </Button>
          </FormGroup>
        </Col>
        <Col className="pl-1 pull-right" md="2">
          <FormGroup>
            <label></label>
            <UncontrolledDropdown group>
                <DropdownToggle caret>
                    Review Changes
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>Approve</DropdownItem>
                    <DropdownItem>Reject</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default ConfigSearch;
