import React, { useState } from "react";

import MediaFileStore from "../mediafile-store.js";

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function SwitchModeButton(props) {
  const [notifyModal, setNotifyModal] = useState(false);

  function handleCleanCache(event) {
    new MediaFileStore().switchMode(props.id);
  }

  return <span style={{cursor: "pointer"}}>
    <Modal isOpen={notifyModal} fade={false} toggle={()=>setNotifyModal(false)}>
      <ModalHeader toggle={()=>setNotifyModal(false)}>Chuyển mode phát</ModalHeader>
      <ModalBody>
        Đây là chức năng dành cho <b>ADMIN</b> để chuyển mode phát trên AppPQ. Bấm <b>Đồng Ý</b> nếu bạn biết rõ công việc này.
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleCleanCache}>
          Đồng ý
        </Button>{' '}
        <Button color="secondary" onClick={()=>setNotifyModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setNotifyModal(true)}>
      <Button className="btn-round btn-icon" color="warning" outline size="sm">
        <i className="fa fa-star" />
      </Button>Chuyển chế độ
    </span>
  </span>;
}

export default SwitchModeButton;
