import React from "react";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import {
  Button,
} from "reactstrap";
import EditButton from "component/edit-button.js";

function PermissionItem(props) {
  return (
    <>
      <tr>
        <td>{props.data.id}</td>
        <td>{props.data.code}</td>
        <td>{props.data.name}</td>
        <td>{props.data.value}</td>
        <td>
          <Button color="success" onClick={() => {props.callback(props.id);}} size="sm">
            <i className="fa fa-edit" />
          </Button>
        </td>
      </tr>
    </>
  );
}

export default PermissionItem;