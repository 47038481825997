import React, { useState } from "react";

import 'react-pure-modal/dist/react-pure-modal.min.css';

import EditButton from "component/edit-button.js";
import DeleteButton from "component/delete-button.js";
import BookInfo from "./component/book_info.js";

import BookStore from "./book-store.js";
import BookDto from "./book-dto.js";
import BookEdit from "./book-edit.js";
import { useTranslation } from 'react-i18next';

import {
  Row,
} from "reactstrap";

function BookView(props) {
  const [writable, setWritable] = useState(false);
  const [sell, setSell] = useState(true);
  const [message, setMessage] = useState("processing... it takes approximate 5 minutes or faster");

  const [data] = useState(props.data);

  function saveDirtyValues(dto: BookDto) {
    new BookStore().update(
      dto,
      (res) => {
        if(res.data) {
          setMessage(res.data);
        } else {
          setMessage("Cannot update new values ....");
        }
      }
    );
  }

  async function remove(mediaId: int) {
    props.showProgressingFn();
    await new BookStore().remove(
      mediaId,
      (res) => {
      }
    );
    props.refresh();
  }

  return (
    <>
      <BookViewRead hide={writable} data={data} sell={sell}
        onEdit={() => setWritable(true)} onDelete={(id) => {remove(id);}}/>
      <tr className={!writable ? "hide" : undefined}>
        <td colSpan="5">
          <BookEdit show={writable} data={data}
            refresh={props.refresh}
            notiRef={props.notiRef}
            attachableMedias={props.attachableMedias}
            onUpdate={(dto) => {saveDirtyValues(dto)}}
            onReadonly={() => setWritable(false)}/>
        </td>
      </tr>
    </>
  );
}

function BookViewRead(props) {
  const { t } = useTranslation();

  function isSale() {
    let genre1Code = props.data.genre1Code;
    return genre1Code !== 'LOIBAIHAT' && genre1Code !== 'NGHILE'
      && genre1Code !== 'KINHTUNG' && genre1Code !== 'KINHNHAC'
      && genre1Code !== 'SAMVAN' && genre1Code !== 'CAMNIEM';
  }

  return (
    <tr className={props.hide ? "hide" : undefined}>
      <td width="15%">
        <img alt="" src={props.data.imageUrl}/>
      </td>
      <td width="30%">
        <BookInfo
          id={props.data.id}
          title={props.data.title}
          updatedAt={props.data.updatedAt}
          viBookUrl={props.data.viBookUrl}
          enBookUrl={props.data.enBookUrl}
          author={props.data.author}
          mediaIds={props.data.mediaIds}
          shareLink={props.data.shareLink}
          hasViBook={props.data.hasViBook}
          hasEnBook={props.data.hasEnBook}
          callback={(title) => props.data.title = title}/>
      </td>
      <td width="20%">
          {props.data.genre1 === 'Tất Cả' ? 'Tổng Hợp'.toUpperCase() : props.data.genre1.toUpperCase()}<br/>
          {props.data.bookGroup && <><b>Bộ sách</b>: {props.data.bookGroupName}<br/></>}
      </td>
      {isSale() && <td>
          {t('books.print_price')}: {props.data.printPrice} <br/>
          {t('books.sale_price')}: {props.data.salePrice} <br/>
          <b>{props.data.inStock && "Còn hàng"}</b>
          <b>{!props.data.inStock && "HẾT HÀNG"}</b>
      </td>}
      {!isSale() && <td>
          <b>Sách không bán</b>
      </td>}
      <td>
        <Row>
          <EditButton id={props.data.id}
            className={props.data.statusStr ? "hide" : undefined}
            callback={(id) => props.onEdit()}/>&nbsp;
          <DeleteButton id={props.data.id} callback={(id) => {props.onDelete(id);}}/>
        </Row>
      </td>
    </tr>
  );
}

export default BookView;