import React from "react";

import NotifyButton from "./notify-button.js";
import CopyLinkButton from "./copy-link-button.js";
import DownloadButton from "./download-button.js";
import PdfViButton from "./pdf-vi-button.js";
import PdfEnButton from "./pdf-en-button.js";
import { useTranslation } from 'react-i18next';

function BookInfo(props) {
  const { t } = useTranslation();

  let url = "mediafiles?ids=" + props.mediaIds;
  return <div>
    <b>ID: {props.id}</b><br/>
    <b>{props.title.toUpperCase()}</b><br/>
    {props.author}<br/>
    {t('books.updated_at')}: {props.updatedAt}<br/>
    {props.hasViBook && <hr/>}
    <NotifyButton id={props.id} />
    <CopyLinkButton shareLink={props.shareLink}/> <br/>
    {props.hasViBook && <PdfViButton url={props.viBookUrl} title="Sách tiếng Việt"/>} &nbsp;
    {props.hasEnBook && <PdfEnButton url={props.enBookUrl} title="Sách tiếng Anh"/>}
    {props.mediaIds && <><hr/><b>Audio IDs</b>: <a href={url}>{props.mediaIds}</a><br/></>}
  </div>;
}

export default BookInfo;
