import React, { useEffect, useState } from "react"; 
import PackageStore from "./package-store.js";
import PackageSearchDto from "./package-search-dto.js";
import PackageItem from "./package-item.js";
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  Progress,
} from "reactstrap";

let store = new PackageStore();

function PackageList() {
  const { t } = useTranslation();

  const [loaded, setLoaded] = useState(false);
  const [packageSearchPage, setPackageSearchPage] = useState([]);

  useEffect(() => {
    let searchReq = new PackageSearchDto();
    store.search(searchReq).then((data) => {
      setLoaded(true);
      setPackageSearchPage(data);
    });
  }, []);
  
  return (
    <>
      <div className="content">
        <span style={{display: loaded ? "none" : "block"}}>
          <Row>
            <Col>
              <Progress animated color="info" striped value={100}/>
            </Col>
          </Row>
        </span>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{t('admin.packages.list')}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>{t('admin.packages.id')}</th>
                      <th>{t('admin.packages.name')}</th>
                      <th>{t('admin.packages.price')}</th>
                      <th>{t('admin.packages.postSalePrice')}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {packageSearchPage?.map(item => (
                      <PackageItem id={item.id} key={item.id} data={item} store={store}/>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default PackageList;
