import React, { useState } from "react";

import MediaFileStore from "../mediafile-store.js";
import { useTranslation } from 'react-i18next';

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function DisableNotifyButton(props) {
  const { t } = useTranslation();
  const [notifyModal, setNotifyModal] = useState(false);

  function disableNotifyApp(event) {
    setNotifyModal(false);
    new MediaFileStore().disableNotifyApp(props.id);
    props.callback();
  }

  function formatTime(time) {
    return new Date(time).toLocaleDateString() + " " + new Date(time).toLocaleTimeString();
  }

  return <span style={{cursor: "pointer"}}>
    <Modal isOpen={notifyModal} fade={false} toggle={()=>setNotifyModal(false)}>
      <ModalHeader toggle={()=>setNotifyModal(false)}>Tắt thông báo đã thiết lập</ModalHeader>
      <ModalBody>
        Bài <b>{props.title}</b> sẽ được thông báo trên AppPQ lúc <b>{props.notifyTime ? formatTime(props.notifyTime) : formatTime(props.publishTime) }</b> cho người dùng biết. <br/> <br/> Bấm vào <b>'Hủy Cài Đặt'</b> để bỏ cài đặt này
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={disableNotifyApp}>
          Hủy Cài Đặt
        </Button>{' '}
        <Button color="secondary" onClick={() => setNotifyModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>
    <span onClick={() => setNotifyModal(true)} >
      <Button className="btn-round btn-icon" color="danger" outline size="sm">
        <i className="fa fa-bell-slash" />
      </Button>{t('files.disable_notify_app')}
    </span>
  </span>;
}

export default DisableNotifyButton;
