import {
  Button,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

function UndoButton(props) {
  const { t } = useTranslation();

  return <span style={{cursor: "pointer"}}>
    <Button className="btn-round" color="danger" outline size="sm"
      onClick={() => {props.callback(props.id);}}>
      <i className="fa fa-undo" /> {t('common.back')}
    </Button>
  </span>;
}

export default UndoButton;
