import React, { useState } from "react";
import DatePicker from "react-datepicker";
import Select from 'react-select';

import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Form, Input, Label, Row, Col
} from "reactstrap";

function SendMsgButton(props) {
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [mediaFileId, setMediaFileId] = useState('');
  const [version, setVersion] = useState('');

  const [eventType, setEventType] = useState('');
  const [selectedEventTypeOption, setSelectedEventTypeOption] = useState('');

  const [dataType, setDataType] = useState('');
  const [selectedDataTypeOption, setSelectedDataTypeOption] = useState('');

  function handleTitle(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function handleContent(event) {
    let selected = event.target.value;
    setContent(selected);
  }

  function handleVersion(event) {
    let selected = event.target.value;
    setVersion(selected);
  }

  function handleMediaFileId(event) {
    let selected = event.target.value;
    setMediaFileId(selected);
  }

  function handleDataType(event) {
    let selected = event.value;
    setDataType(selected);
    setSelectedDataTypeOption(event);
  }

  function getEventTypeOptions() {
    return [
      { value: 'publish', label: 'MEDIA MỚI'},
      { value: 'versionChanged', label: 'PHIÊN BẢN MỚI'},
      { value: 'clean_cache', label: 'XÓA CACHE'},
      { value: 'info_msg', label: 'GỬI THÔNG BÁO'},
      { value: 'sync_token', label: 'ĐỒNG BỘ MSG TOKEN'}
    ];
  }

  function getDataTypeOptions() {
    return [
      { value: 'banners', label: 'BANNERS'},
      { value: 'media', label: 'MEDIA'},
      { value: 'image', label: 'IMAGES'}
    ];
  }

  function handleEventType(event) {
    let selected = event.value;
    setEventType(selected);
    setSelectedEventTypeOption(event);
  }

  return <span style={{cursor: "pointer"}} className={props.className}>
    <Modal isOpen={modal} fade={false} toggle={()=>setModal(false)} centered>
      <ModalHeader toggle={() => setModal(false)}>GỬI TIN NHẮN</ModalHeader>
      <ModalBody>
        Gửi tin nhắn tới <b>{props.name}</b> - Vui lòng xác nhận một lần nữa?
        <br/>
        <br/>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup>
                <label>Loại sự kiện</label><br/>
                <Select options={getEventTypeOptions()} value={selectedEventTypeOption}
                  onChange={handleEventType}/>
              </FormGroup>
            </Col>
          </Row>
          {eventType == 'info_msg' && <><Row>
            <Col md="12">
              <FormGroup>
                <label>Tiêu đề</label><br/>
                <Input type="textarea" onChange={handleTitle}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
               <label>Nội dung</label><br/>
               <Input type="textarea" onChange={handleContent}/>
              </FormGroup>
            </Col>
          </Row></>}
          {eventType == 'clean_cache' &&<Row>
            <Col md="12">
              <FormGroup>
                <label>Loại dữ liệu</label><br/>
                <Select options={getDataTypeOptions()} value={selectedDataTypeOption}
                  onChange={handleDataType}/>
              </FormGroup>
            </Col>
          </Row>}
          <Row>
            {eventType == 'publish' && <Col md="12">
              <FormGroup>
                <label>Media File ID</label><br/>
                <Input type="text" onChange={handleMediaFileId}/>
              </FormGroup>
            </Col>}
            {eventType == 'versionChanged' && <Col md="12">
              <FormGroup>
                <label>Version</label><br/>
                <Input type="text" onChange={handleVersion}/>
              </FormGroup>
            </Col>}
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {setModal(false); props.callback(props.id, {
          'userId': props.id,
          'title': title,
          'content': content,
          'mediaFileId': mediaFileId,
          'version': version,
          'app': props.app,
          'eventType': eventType,
          'dataType': dataType
        });}}>
          SEND
        </Button>{' '}
        <Button color="secondary" onClick={()=>setModal(false)}>
          Quay lại
        </Button>
      </ModalFooter>
    </Modal>

    <span onClick={() => setModal(true)}>
      <Button className="btn-round" outline size="sm" color="primary">
        <i className="fa fa-eye" /> Gửi Message
      </Button>
    </span>
  </span>;
}

export default SendMsgButton;
