import React, { useState, useEffect } from "react";
import axios from "axios";
import 'react-pure-modal/dist/react-pure-modal.min.css';
import Switch from "react-switch";
import Select from 'react-select';
import MediaSuggestion from "./component/media-suggestion.js";
import MediaTable from "./component/media-table.js";
import BookGroupStore from "./book-group-store.js";

import PureModal from 'react-pure-modal';
import UndoButton from "component/undo-button.js";
import SaveButton from "component/save-button.js";

import CategoryStore from "../category/category-store.js";
import PdfViButton from "./component/pdf-vi-button.js";
import PdfEnButton from "./component/pdf-en-button.js";

import HttpUtils from "utils/http-utils.js";
import NotiUtils from "utils/noti-utils.js";
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Progress,
  DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";

function BookEdit(props) {
  const { t } = useTranslation();

  const [genres1, setGenres1] = useState([]);
  const [genre1Code, setGenre1Code] = useState(props.data.genre1Code);
  const [genre1Name, setGenre1Name] = useState(props.data.genre1);
  const [selectedGenreOption, setSelectedGenreOption] = useState({
    value: props.data.genre1Code,
    label: props.data.genre1Code === 'ALL' ? 'Tổng Hợp': props.data.genre1
  });

  const [bookGroups, setBookGroups] = useState([]);
  const [bookGroup, setBookGroup] = useState(props.data.bookGroup);
  const [bookGroupName, setBookGroupName] = useState(props.data.bookGroupName);
  const [selectedBookGroupOption, setSelectedBookGroupOption] = useState({
    value: props.data.bookGroup,
    label: props.data.bookGroupName
  });

  const [title, setTitle] = useState(props.data.title);
  const [description, setDescription] = useState(props.data.description);
  const [inStock, setInStock] = useState(props.data.inStock);
  const [salePrice, setSalePrice] = useState(props.data.salePrice);
  const [printPrice, setPrintPrice] = useState(props.data.printPrice);
  const [author, setAuthor] = useState(props.data.author);
  const [colorCodeFrom, setColorCodeFrom] = useState(props.data.colorCodeFrom);
  const [colorCodeTo, setColorCodeTo] = useState(props.data.colorCodeTo);
  const [publisher, setPublisher] = useState(props.data.publisher);

  const [previewImg, setPreviewImg] = useState(props.data.imageUrl);
  const [imageFile, setImageFile] = useState('');
  const [enBookFile, setEnBookFile] = useState('');
  const [viBookFile, setViBookFile] = useState('');
  const [imageProgress, setImageProgress] = useState('');
  const [enBookFileProgress, setEnBookFileProgress] = useState('');
  const [viBookFileProgress, setViBookFileProgress] = useState('');
  const [mediaIds, setMediaIds] = useState(props.data.mediaIds);
  const [bookMediaItems, setBookMediaItems] = useState(props.data.bookMediaItems);

  const [result, setResult] = useState('');
  const [notifyModal, setNotifyModal] = useState(false);

  useEffect(() => {
    new CategoryStore().listBookGenres().then((data) => {
      setGenres1(data);
      var sel = data.filter(item => item.code === genre1Code);
      if(sel && sel[0]) setGenre1Name(sel[0].name);
    });
    new BookGroupStore().list().then((data) => {
      setBookGroups(data);
    });
  }, []);

  function onEnBookFileSelected(event) {
    setEnBookFile(event.target.files[0]);
  }

  function onViBookFileSelected(event) {
    setViBookFile(event.target.files[0]);
  }

  function onImageSelected(event) {
    setImageFile(event.target.files[0]);
    setPreviewImg(URL.createObjectURL(event.target.files[0]));
  }

  function handleGenre1Change(genre) {
    setGenre1Code(genre.value);
    setGenre1Name(genre.label);
  }

  function handleTitle(event) {
    let selected = event.target.value;
    setTitle(selected);
  }

  function handleAuthor(event) {
    let selected = event.target.value;
    setAuthor(selected);
  }

  function handlePrintPrice(event) {
    let selected = event.target.value;
    setPrintPrice(parseInt(selected));
  }

  function handleSalePrice(event) {
    let selected = event.target.value;
    setSalePrice(parseInt(selected));
  }

  function handleInStock(selected) {
    setInStock(selected);
  }

  function getGenreList() {
    return genres1.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  function validate() {
    let movedGenre1 = props.data.genre1Code !== genre1Code;
    let moved = movedGenre1;
    let changedTitle = props.data.title !== title;
    let changedGroup = props.data.bookGroup !== bookGroup;
    let changedAuthor = props.data.author !== author;
    let changedDesc = props.data.description !== description;
    let updatedImage = imageFile !== '';
    let updatedEnBookFile = enBookFile !== '';
    let updatedViBookFile = viBookFile !== '';
    let changedInStock = props.data.inStock !== inStock;
    let updatePrice = props.data.printPrice !== printPrice
        || props.data.salePrice !== salePrice;
    let changedAssets = updatedImage || updatedEnBookFile || updatedViBookFile;
    let changedMediaIds = props.data.mediaIds !== mediaIds;
    let changedBookMedia = props.data.bookMediaItems !== bookMediaItems;
    if(changedAssets && moved) {
      new NotiUtils().notify(props.notiRef, 'warning', "We don't support to move and change assets at same time");
      return;
    }
    if(moved || changedTitle || changedAuthor || changedInStock || changedMediaIds || changedDesc
        || updatedImage || updatedEnBookFile || updatedViBookFile || updatePrice
        || changedBookMedia || changedGroup) {
      setNotifyModal(true);
    } else {
      new NotiUtils().notify(props.notiRef, 'info', "Nothing changed to update");
    }
  }

  function onUpdate(e) {
    setNotifyModal(false);
    let updatedImage = imageFile !== '';
    let updatedEnBookFile = enBookFile !== '';
    let updatedViBookFile = viBookFile !== '';
    if(updatedImage) {
      updateImageFile(e);
    }
    if(updatedEnBookFile) {
      updateEnFile(e);
    }
    if(updatedViBookFile) {
      updateViFile(e);
    }
    props.onUpdate({
        'id': props.data.id,
        'title': title,
        'author': author,
        'description': description,
        'genre1Code': genre1Code,
        'author': author,
        'salePrice': salePrice,
        'printPrice': printPrice,
        'updateImage': updatedImage,
        'updateEnBook': updatedEnBookFile,
        'updateViBook': updatedViBookFile,
        'inStock': inStock,
        'mediaIds': mediaIds,
        "publisher": publisher,
        "colorCodeFrom": colorCodeFrom,
        "colorCodeTo": colorCodeTo,
        "bookGroup": bookGroup,
        'bookMediaItems': bookMediaItems
      });
    updateSrc(props.data);
    props.onReadonly();
    new NotiUtils().notify(props.notiRef, 'info', t('books.updated_book'));
  }

  function updateSrc(src) {
    src.genre1Code = genre1Code;
    src.genre1 = genre1Name;
    src.title = title;
    src.author = author;
    src.description = description;
    src.inStock = inStock;
    src.salePrice = salePrice;
    src.printPrice = printPrice;
    src.bookGroup = bookGroup;
    src.bookGroupName = bookGroupName;
  }

  async function updateImageFile(e)  {
    e.preventDefault();
    const formData = new FormData();
    formData.append(
      "imageFile",
      imageFile,
      imageFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/books/" + props.data.id + "/updateImageFile", formData, {
        onUploadProgress: data => setImageProgress((100 * data.loaded) / data.total),
        headers: new HttpUtils().getHeaders()
      }
    );
    return res.data === 'true';
  }

  async function updateEnFile(e) {
    e.preventDefault()
    const formData = new FormData();
    formData.append(
        "bookFile",
        enBookFile,
        enBookFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/books/" + props.data.id + "/updateEnBookFile", formData, {
        onUploadProgress: data => setEnBookFileProgress(Math.round((100 * data.loaded) / data.total)),
        headers: new HttpUtils().getHeaders()
      }
    )
    return res.data === 'true';
  }

  function isSale() {
    return genre1Code !== 'LOIBAIHAT' && genre1Code !== 'NGHILE';
  }

  function isAttachable() {
    return genre1Code === 'LOIBAIHAT' || genre1Code === 'NGHILE';
  }

  function handleDescription(event) {
    let selected = event.target.value;
    setDescription(selected);
  }

  function handlePublisher(event) {
    let selected = event.target.value;
    setPublisher(selected);
  }

  function handleColorCodeFrom(event) {
    let selected = event.target.value;
    setColorCodeFrom(selected);
  }

  function handleColorCodeTo(event) {
    let selected = event.target.value;
    setColorCodeTo(selected);
  }

  function getBookGroup() {
    return bookGroups.map((item) => ({
      value: item.code,
      label: item.name
    }));
  }

  async function updateViFile(e) {
    e.preventDefault()
    const formData = new FormData();
    formData.append(
        "bookFile",
        viBookFile,
        viBookFile.name
    );
    var res = await axios.post(process.env.REACT_APP_API_URL + "/books/" + props.data.id + "/updateViBookFile", formData, {
        onUploadProgress: data => setViBookFileProgress(Math.round((100 * data.loaded) / data.total)),
        headers: new HttpUtils().getHeaders()
      }
    )
    return res.data === 'true';
  }

  function onMediaTableChange(value) {
    setBookMediaItems(value);
  }

  function handleBookGroupChange(group) {
    setBookGroup(group.value);
    setBookGroupName(group.label);
    setSelectedBookGroupOption({
       value: group.value,
       label: group.label
   });
  }

  return (
    <div className={!props.show ? "hide" : undefined}>
      <Row>
        <Col md="4">
          <Card className="card-user">
            <CardBody>
              <div className="image2">
                {previewImg && <img alt="..." src={previewImg} />}
              </div>
            </CardBody>
            <CardFooter>
              <div className="author2">
                {title}
              </div>
              {genre1Name && <p className="text-center">
                <b> {genre1Name === 'Tất Cả' ? 'Tổng Hợp' : genre1Name} </b>
              </p>}
            </CardFooter>
          </Card>
        </Col>
        <Col md="8">
          <Card className="card-user">
            <CardHeader>
              <CardTitle tag="h5">{t('books.update_book')}</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>{t("books.browse_image")}</label>
                      <Input
                        placeholder={t('books.select_image_to_upload')}
                        type="file" accept="image/jpg"
                        onChange={onImageSelected}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>{t("books.browse_vi_pdf")}</label>
                      <Input
                        placeholder={t('books.select_vi_pdf_to_upload')}
                        type="file" accept=".pdf"
                        onChange={onViBookFileSelected}
                      />
                      {props.data.hasViBook && <PdfViButton url={props.data.viBookUrl} title="VIE.pdf"/>} &nbsp;
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>{t("books.browse_en_pdf")}</label>
                      <Input
                        placeholder={t('books.select_en_pdf_to_upload')}
                        type="file" accept=".pdf"
                        onChange={onEnBookFileSelected}
                      />
                      {props.data.hasEnBook && <PdfEnButton url={props.data.enBookUrl} title="EN.pdf"/>} &nbsp;
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>{t("books.upload_image_progress")}</label>
                      <Progress
                        value={imageProgress}
                      > {imageProgress < 100 ? "uploading" : "done"} </Progress>
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>{t("books.upload_vi_book_progress")}</label>
                      <Progress
                        value={viBookFileProgress}
                      > {viBookFileProgress < 100 ? "uploading" : "done"} </Progress>
                    </FormGroup>
                  </Col>
                  <Col className="pr-2" md="4">
                    <FormGroup>
                      <label>{t("books.upload_en_book_progress")}</label>
                      <Progress
                        value={enBookFileProgress}
                      > {enBookFileProgress < 100 ? "uploading" : "done"} </Progress>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pl-3" md="4">
                    <FormGroup>
                      <label>Danh mục sách <span style={{color: "red"}}> * </span></label>
                      <Select value={selectedGenreOption} options={getGenreList()} onChange={handleGenre1Change}/>
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Chọn bộ sách</label>
                      <Select value={selectedBookGroupOption} options={getBookGroup()} onChange={handleBookGroupChange}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>{t("books.title")}</label>
                      <Input
                        placeholder={t('books.input_title')}
                        onChange={handleTitle}
                        type="text" defaultValue={title}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>{t("books.author")}</label>
                      <Input
                        placeholder={t('books.input_author')}
                        onChange={handleAuthor}
                        type="text" defaultValue={author}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>{t("books.book_desc")}</label>
                      <Input type="textarea" placeholder={t('books.input_book_desc')} defaultValue={description} onChange={handleDescription}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="4">
                    <span style={{display: isSale() ? "block" : "none"}}>
                      <FormGroup>
                        <Label>{t("books.in_stock")}</Label>
                        <div><Switch onChange={handleInStock} checked={inStock} /></div>
                      </FormGroup>
                    </span>
                  </Col>
                  <Col className="pr-1" md="4">
                    <span style={{display: isSale() ? "block" : "none"}}>
                      <FormGroup>
                        <label>{t("books.print_price")}</label>
                        <Input
                          onChange={handlePrintPrice}
                          placeholder={t('books.input_print_price')}
                          type="text" defaultValue={printPrice}
                        />
                      </FormGroup>
                    </span>
                  </Col>
                  <Col className="pr-2" md="4">
                    <span style={{display: isSale() ? "block" : "none"}}>
                      <FormGroup>
                        <label>{t("books.sale_price")}</label>
                        <Input
                          onChange={handleSalePrice}
                          placeholder={t('books.input_sale_price')}
                          type="text" defaultValue={salePrice}
                        />
                      </FormGroup>
                    </span>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Mã màu hex bắt đầu</label>
                      <Input
                          onChange={handleColorCodeFrom}
                          placeholder={"Mã màu hex bắt đầu"}
                          type="text" defaultValue={colorCodeFrom}
                        />
                    </FormGroup>
                  </Col>
                  <Col className="pr-1" md="4">
                    <FormGroup>
                      <label>Mã màu hex kết thúc</label>
                      <Input
                          onChange={handleColorCodeTo}
                          placeholder={"Mã màu hex kết thúc"}
                          type="text" defaultValue={colorCodeTo}
                        />
                    </FormGroup>
                  </Col>
                </Row>

                {isAttachable() &&
                <Row>
                  <Col className="pr-1" md="9">
                      <FormGroup>
                        <label>{t("books.attach_music")}</label>
                        <MediaSuggestion
                          attachableMedias={props.attachableMedias}
                          callback={(ids) => {setMediaIds(ids);}}
                          mediaIds={props.data.mediaIds}/>
                      </FormGroup>
                  </Col>
                </Row>}
                <Row>
                  <Col className="pr-1" md="12">
                    <FormGroup>
                      <label className="title text-warning">{result}</label>
                    </FormGroup>
                  </Col>
                </Row>
                {genre1Code !== 'KINHNHAC' && <Row>
                  <div className="update ml-auto mr-auto">
                    <SaveButton callback={validate}/> &nbsp;
                    <UndoButton callback={(id) => {props.onReadonly();}}/>
                    <PureModal width="400px" header="Please confirm"
                      footer={
                       <div>
                         <button onClick={() => setNotifyModal(false)}>Cancel</button>
                         <button onClick={onUpdate}>Yes</button>
                       </div>
                      }
                      isOpen={notifyModal}
                      onClose={()=>setNotifyModal(false)}
                    >
                      <p>Are you sure you want to save new data?</p>
                      {props.data.title !== title ? <><p>Tên sách cũ: <b>{props.data.title}</b><br/>Tên sách mới: <b>{title}</b></p><hr/></>: undefined}
                      {props.data.genre1Code !== genre1Code ? <><p>Danh mục sách cũ: <b>{props.data.genre1Code}</b><br/>Danh mục sách mới: <b>{genre1Code}</b><hr/></p></>: undefined}
                      {props.data.bookGroup !== bookGroup ? <><p>Bộ sách cũ: <b>{props.data.bookGroup}</b><br/>Bộ sách mới: <b>{bookGroupName}</b><hr/></p></>: undefined}
                      {imageFile !== '' ? <><p><b>Upload new image</b></p><br/></>: undefined}
                      {enBookFile !== '' ? <><p><b>Upload sách tiếng Anh</b></p><br/></>: undefined}
                      {viBookFile !== '' ? <><p><b>Upload sách tiếng Việt hoặc song ngữ</b></p><br/></>: undefined}
                    </PureModal>
                  </div>
                </Row>}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default BookEdit;
